import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderStyle from "./Header.module.css";
import FooterStyle from "./Footer.module.css";
import ButtonStyle from "./Button.module.css";
import SavalaLogo from "../../Assets/Images/savala-logo.svg";
import MobileMenuIconBlack from "../../Assets/Images/MobileMenuIconBlack.svg";
import CloseOrange from "../../Assets/Images/CloseOrange.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearLocalStorage } from "../../utils/common";
import { setUserLogout } from "../../redux/actions/user-action";
const Header = () => {
  const [overLayOpen, setOverLayOpen] = useState<boolean>(false);
  const { isLoggedIn } = useSelector((state: any) => state.userReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNav = (nav: string) => {
    navigate(nav);
  };

  const handleLogout = () => {
    clearLocalStorage();
    dispatch(setUserLogout());
    navigate("/login");
  };
  const toggleMobileMenu = () => {
    setOverLayOpen(!overLayOpen);
  };
  const closeOverlay = () => {
    setOverLayOpen(!overLayOpen);
  };
  return (
    <>
      <div
        className={HeaderStyle.MobileMenuOverlay}
        style={{ width: `${(overLayOpen && "500px") || "0px"}` }}
        // style={{ width: `${(overLayOpen && '100%') || '0%'}` }}
      >
        <div className={HeaderStyle.OverlayContent}>
          <div className={HeaderStyle.TopMobileCotent}>
            <Link
              to="#"
              className={HeaderStyle.CloseBtn}
              onClick={closeOverlay}>
              <img src={CloseOrange} alt="Close" onClick={toggleMobileMenu} />
            </Link>
            <Link to="/" className={HeaderStyle.MobileMenu}>
              Home
            </Link>
            {isLoggedIn && (
              <Link to="/my-profile" className={HeaderStyle.MobileMenu}>
                My Profile
              </Link>
            )}
            <Link to="/why-graphite" className={HeaderStyle.MobileMenu}>
              Why Graphite?
            </Link>
            <Link to="/about" className={HeaderStyle.MobileMenu}>
              About
            </Link>
            <Link to="/contact-us" className={HeaderStyle.MobileMenu}>
              Contact
            </Link>

            {!isLoggedIn ? (
              <div className={HeaderStyle.MobileBtnContainer}>
                <button
                  className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrange} ${HeaderStyle.MobileBtns}`}
                  onClick={() => handleNav("/pre-register")}>
                  Pre-Register
                </button>
                <button
                  className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrange} ${HeaderStyle.MobileBtns} `}
                  onClick={() => handleNav("/login")}>
                  Log in
                </button>
                <button
                  onClick={() => handleNav("/signup")}
                  className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${HeaderStyle.MobileBtns}`}>
                  Sign up
                </button>
              </div>
            ) : (
              <button
                className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrange} ${HeaderStyle.MobileBtns}`}
                onClick={() => handleLogout()}>
                Logout
              </button>
            )}
          </div>
          <div
            className={`${FooterStyle.BottomMobileContent} ${HeaderStyle.mobileSideNavFooter}`}>
            <Link to="/faq" className={FooterStyle.MobileFooterMenu}>
              FAQs
            </Link>
            <Link to="/privacy-policy" className={FooterStyle.MobileFooterMenu}>
              Privacy
            </Link>
          </div>
        </div>
      </div>
      <header className={HeaderStyle.HeaderContainer}>
        <div className={HeaderStyle.MobileMenuIcon}>
          <img
            src={MobileMenuIconBlack}
            alt="Logo"
            onClick={toggleMobileMenu}
          />
        </div>
        <div className={HeaderStyle.Logo}>
          <Link to="/" className={HeaderStyle.FooterLink}>
            <img src={SavalaLogo} alt="Logo" />
          </Link>
        </div>
        <Link
          to="/login"
          className={HeaderStyle.MobileLoginMenu}
          style={{ visibility: "hidden" }}>
          {/* style={!isLoggedIn ? {} : { visibility: "hidden" }}> */}
          Log in
        </Link>
        <div className={HeaderStyle.DesktopMenuSection}>
          <Link to="/" className={HeaderStyle.DesktopMenu}>
            Home
          </Link>
          {isLoggedIn && (
            <Link to="/my-profile" className={HeaderStyle.DesktopMenu}>
              My Profile
            </Link>
          )}
          <Link to="/why-graphite" className={HeaderStyle.DesktopMenu}>
            Why Graphite?
          </Link>
          <Link to="/about" className={HeaderStyle.DesktopMenu}>
            About
          </Link>
          <Link to="/contact-us" className={HeaderStyle.DesktopMenu}>
            Contact
          </Link>
          <Link to="/news" className={HeaderStyle.DesktopMenu}>
            News
          </Link>

          {!isLoggedIn ? (
            <>
              {/* <button
                className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground}`}
                onClick={() => handleNav("/pre-register")}>
                Pre-Register
              </button> */}
              <button
                className={ButtonStyle.PlainBtn}
                onClick={() => handleNav("/login")}>
                Log in
              </button>
              <button
                onClick={() => handleNav("/signup")}
                className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeBlackBackground} `}>
                Sign up
              </button>
            </>
          ) : (
            <button
              className={ButtonStyle.PlainBtn}
              onClick={() => handleLogout()}>
              Logout
            </button>
          )}
        </div>
      </header>
    </>
  );
};
export default Header;
