import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./HomeHowTo.module.css";
import ImageLeftContentRight from "../../Common/ImageLeftContentRight";
import DashboardScreen from "../../../Assets/Images/dashboardBoth.png";
import { useSelector } from "react-redux";

const HomeHowTo = () => {
  const { screenSize } = useSelector((state: any) => state.layoutReducer);
  const rightContent = [
    {
      header: "Register your Savala account",
      body: "Accounts require KYC authentication for proof of identity.",
    },
    {
      header: "Conduct due diligence",
      body: "Account holders receive comprehensive documentation to assist purchase decision making.",
    },
    {
      header: "Acquire your purchase",
      body: "Buyers can use regular or digital currency. Savala accounts are connected to existing digital currency wallets.",
    },
  ];
  return (
    <div style={{ marginBottom: "60px" }}>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1}>
          <div className={Styles.header1}>
            <div>How to use </div>
            <div className={Styles.diffColor}>Savala?</div>
          </div>
          <Row className={Styles.header2}>
            Savala is a unique marketplace for forward-thinkers. It is a global
            leader in innovative, technology-enabled trades for simplicity and
            convenience. Join now.
          </Row>
        </Col>
      </Row>
      <ImageLeftContentRight
        data={rightContent}
        image={DashboardScreen}
        number={true}
        width="100%"
        imageWidth={screenSize === 425 ? "85vw" : "40vw"}
        padding={true}
      />
    </div>
  );
};

export default HomeHowTo;
