import React from 'react';
import Layout from '../Layouts/Layout';

const RedemptionRequest = () => {
  return (
    <Layout>
      <div>
        <p>This is Redemption Request</p>{' '}
      </div>
    </Layout>
  );
};

export default RedemptionRequest;
