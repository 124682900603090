import React from "react";
import CurrencyFormat from "react-currency-format";
import Styles from "./money.module.css";
import { useSelector } from "react-redux";

const MoneyComponent = () => {
  const { total_money_invested } = useSelector(
    (state: any) => state.dashboardReducer
  );

  return (
    <div className={Styles.tokenContainer}>
      <div className={Styles.investmentHeadingContainer + " col-12"}>
        <div className={Styles.orderEllipse}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none">
            <circle opacity="0.6" cx="8.5" cy="9" r="8.5" fill="white" />
          </svg>
        </div>
        <div className={Styles.investmentHeading}>
          Commodity Financial Summary
        </div>
      </div>
      <div className={Styles.tokenBody + " row"}>
        <div className="col-12 ">
          <CurrencyFormat
            value={total_money_invested || 0}
            displayType={"text"}
            decimalScale={2}
            className={Styles.moneyText}
            thousandSeparator={true}
            prefix={"$"}
          />
        </div>
      </div>
    </div>
  );
};
export default MoneyComponent;
