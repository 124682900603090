import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./AboutHeader.module.css";
import AboutHeaderImg from "../../../Assets/Images/about-header.png";
const AboutHeader = () => {
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1} md={6}>
          <Row className={Styles.header1}>
            Savala. Founded in trust and sustainability.
          </Row>
          <Row className={Styles.header2 + " pb-3"}>
            Savala is a cutting edge online marketplace where forward-thinking
            buyers can purchase and trade raw materials used in critical
            renewable energy assets.
          </Row>
        </Col>
        <Col md={6}>
          <img
            className={Styles.bannerImage}
            src={AboutHeaderImg}
            alt="banner_image"
          />
        </Col>
      </Row>
    </div>
  );
};

export default AboutHeader;
