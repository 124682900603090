const URLS = {
  BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_BASE_IMG_URL: process.env.REACT_APP_BASE_IMG_URL,
  LOGIN_URL: "auth/login",
  SIGNUP_URL: "sso/signup",
  FORGOT_PASSWORD_URL: "sso/forget-password",
  VERIFY_OTP_URL: "sso/confirm-password",
  RESEND_OTP_URL: "sso/resend-code",
  CONFIRM_FORGOT_PASSWORD: "sso/confirm-forget-password",
  STRIP_PAYMENT: "create-checkout-session",
  CONTACT_US: "contact-us",
  GET_MINE_ASSET: "asset_request/master",
  BUY_MINE_TOKEN: "buy_mine_token",
  UPLOAD_ORDER_POLICY: "upload/order_policy",
  USER_DETAIL_URL: "get/user/detail",
  USER_UPDATE_URL: "user/detail/update",
  RESET_PASSWORD: "set_password",
  GET_KYC_STATUS: "gmt/kyc/status",
  UPDATE_TRANSACTION: "order/transaction_verification",
  ORDER_LIST: "order/list",
  BLOGS_LIST:"cms/list",
  BLOGS_PAGES:"cms/blogs",
  ADMIN_DETAILS: "admin/details",
  GET_DOCUMENT: "get/mine/document/list/download",
  DASH_API: "cms/pages/dashboard",
  FAQ_API: "homepage/faq",
  SUPPORT_FAQ_API: "support-faq",
  SUPPORT_DOCS_API: "support_docs",
  GET_MY_COLLECTION: "get/user/mycollections",
  GET_MINE_RESELL_COLLECTION: "get/listed_token",
  GET_RESELL_MINE_URL: "list_mine_token",
  GET_WITHDRAW_MINE_URL: "delist/secondary_token",
  REDEEM_MINE: "redemption_request",
  RECOVER_TOKEN: "recovertoken",
  ORDER_BOOK_DETAILS: "get/relisttoken",
  SECONDARY_TRADE_HISTORY: "trade/history",
  PURCHASE_SECONDARY_TOKEN: "buy_secondary_token",
  ALL_COUNTRY: "all/countries",
  REGISTER_API: "pre/register",
};

export default URLS;
