// @ts-nocheck

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAllCountry, registerAPI } from "../../service/api";
import InputField from "../Common/InputField";
import Styles from "./preregister.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";
import Form from "react-bootstrap/Form";
import notify from "../../utils/notify";
import { useDispatch } from "react-redux";
import { setLoader } from "../../redux/actions/layout-action";
const PreRegisterComponent = () => {
  // for screen navigation purpose
  const navigate = useNavigate();

  // for updating and the redux store values

  // initializing the state variables
  const [user, setUserDetails] = useState({
    name: "",
    email: "",
    number: "",
    country: "",
  });

  // state variable for form erros
  const [formErrors, setFormErrors] = useState({});

  const [isSubmit, setIsSubmit] = useState(false);

  const [apiErrorMsg, setApiErrorMsg] = useState("");

  const [countryData, setCountryData] = useState();

  const dispatch = useDispatch();

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setApiErrorMsg("");
    const regexNumber = /^\+?\d*$/;
    if (name === "number") {
      if (value === "" || regexNumber.test(value)) {
        setUserDetails({
          ...user,
          [name]: value,
        });
      }
    } else {
      setUserDetails({
        ...user,
        [name]: value,
      });
    }
  };

  // for valiadting the form inputs
  const validateForm = (values: {
    name: string;
    email: string;
    country: string;
    number: string;
  }) => {
    const errors = {};

    const regexName = /^[^*|\":<>[\]{}`\\()'!#%^_+,./~?;@&$]+$/;

    if (!values.name.trim()) {
      errors.name = "Name is required.";
    } else if (!regexName.test(values.name.trim())) {
      errors.name = "Invalid characters in the name.";
    }

    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/;
    if (!values.email.trim()) {
      errors.email = "Email is required.";
    } else if (!regexEmail.test(values.email.trim())) {
      errors.email = "Invalid email format.";
    }

    if (!values.country.trim()) {
      errors.country = "Country is required.";
    }

    // const regexNumber = /^\+?[0-9]{8,14}$/
    // if (!values.number) {
    //   errors.number = "Phone number is required.";
    // } else if (!regexNumber.test(values.number)) {
    //   errors.number = "Phone number is not valid.";
    // }

    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validateForm(user));
    setIsSubmit(true);
  };

  const signupHandler = async () => {
    const data = {
      email: user.email.trim(),
      mobile_number: user.number,
      name: user.name.trim(),
      country_id: user.country,
    };

    try {
      dispatch(setLoader(true));

      const response = await registerAPI(data);

      if (response.status) {
        // reset the form values after the api action
        setUserDetails({
          name: "",
          email: "",
          number: "",
          country: "",
        });
        dispatch(setLoader(false));
        notify.success(
          "Thank you for pre-registering on Savala. Please check your inbox for your welcome email."
        );
        navigate("/");
      } else {
        // reset the form values after the api action
        setUserDetails({
          name: "",
          email: "",
          number: "",
          country: "",
        });

        setApiErrorMsg(response.message);
        dispatch(setLoader(false));
        return;
      }
    } catch (error) {
      console.error("An error occurred during signup:", error);
      return;
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      signupHandler();
    }
  }, [formErrors]);

  const fetchCountry = async () => {
    const response = await getAllCountry();
    if (response.status) {
      setCountryData(response.countries_details);
    }
  };
  useEffect(() => {
    navigate("/login");
    // fetchCountry();
  }, []);

  return (
    <div className={Styles.MainDiv}>
      <h2 className={Styles.Heading}>Welcome to Pre-Registration</h2>
      <p className={Styles.Paragraph}>
        Pre-Register to receive an in-depth PDF on Savala
      </p>
      <form onSubmit={handleSubmit}>
        <InputField
          label="Name"
          value={user.name}
          type="text"
          placeholder="Name"
          onChange={changeHandler}
          height="50px"
          name="name"
          maxLength={40}
          important={true}
        />

        {formErrors.name && (
          <p className="text-danger">
            <small>{formErrors.name}</small>
          </p>
        )}
        <div className={Styles.InputField}>
          <InputField
            label="Email"
            value={user.email}
            type="email"
            placeholder="Email"
            onChange={changeHandler}
            height="50px"
            name="email"
            maxLength={50}
            important={true}
          />
        </div>
        {formErrors.email && (
          <p className="text-danger">
            <small>{formErrors.email}</small>
          </p>
        )}

        {/* <div className={Styles.InputField}>
          <InputField
            label="Phone Number"
            value={user.number}
            type="text"
            placeholder="Phone Number"
            onChange={changeHandler}
            height="50px"
            name="number"
            maxLength={15}
            important={true}
          />
        </div> */}

        {formErrors.number && (
          <p className="text-danger">
            <small>{formErrors.number}</small>
          </p>
        )}

        <div className={Styles.InputField}>
          <div className={Styles.label}>
            Select Country <b style={{ color: "#FF0003" }}>*</b>
          </div>

          <Form.Select
            aria-label="Default select example"
            onChange={changeHandler}
            name="country"
            value={user.country === "" ? "" : user.country}>
            <option
              value={0}
              style={{
                backgroundImage: "url('../../Assets/Images/HomeCar.svg')",
              }}>
              Select one country
            </option>
            {countryData &&
              countryData.map((country) => (
                <option value={country.country_id}>
                  {country.country_name}
                </option>
              ))}
          </Form.Select>
        </div>
        {formErrors.country && (
          <p className="text-danger">
            <small>{formErrors.country}</small>
          </p>
        )}

        <button
          type="submit"
          style={{ marginTop: 50 }}
          className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}>
          <span className={Styles.RegisterButtonText}>Pre-Register</span>
        </button>
        {apiErrorMsg && (
          <p className="text-danger">
            <small>{apiErrorMsg}</small>
          </p>
        )}
      </form>
    </div>
  );
};

export default PreRegisterComponent;
