import React, { useEffect, useState } from "react";
import SupportHeader from "./SupportHeader";
import FaqComponent from "../FAQComponent";
import SupportPublicDoc from "./SupportPublicDoc";
import SupportContactUs from "./ContactUs";
import commonStyles from "../Common/Button.module.css";
import Styles from "./support.module.css";
import { Link } from "react-router-dom";
import { getSupportFAQData } from "../../service/api";
import { useSelector } from "react-redux";

const SupportComponent = () => {
  const { screenSize } = useSelector((state: any) => state.layoutReducer);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const res: any = await getSupportFAQData();
    if (res.status) {
      setData(res.faqs);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="px-4 py-4">
      <div className="row">
        <div className="col-lg-8  col-md-12 px-3">
          <div className="col-12 ">
            <SupportHeader />
          </div>
          <div className="col-12 mt-4">
            <div className={Styles.faqDiv}>
              <FaqComponent data={data} />
            </div>
          </div>
        </div>
        <div
          className={`col-lg-4 col-md-12 px-4 ${
            screenSize < 769 ? "mt-5" : ""
          }`}>
          <div className="col-12">
            <SupportPublicDoc />
          </div>
          <div className="col-12 mt-4">
            <SupportContactUs />
          </div>
          <div className="col-12 mt-4 ">
            <div>
              <Link to={"/about"}>
                <button
                  className={`${Styles.buymoreBtn} ${commonStyles.PlainBtn} ${commonStyles.ThemeOrangeBackground} `}>
                  <span className={Styles.btnText}>
                    Learn more about Savala
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SupportComponent;
