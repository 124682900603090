import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./GraphiteMiddleText.module.css";
import ImageLeftContentRight from "../../Common/ImageLeftContentRight";
import WhyGraphite from "../../../Assets/Images/whyGraphiteRock-3.svg";
import { useSelector } from "react-redux";
const GraphiteMiddleText = () => {
  const rightContent = [
    {
      header: "Premium grade graphite",
      body: "High-performing batteries require at least 80% grade graphite. Savala buyers purchase up to 99.9% graphite, the world’s finest quality.",
    },
    {
      header: "Natural vs. Synthetic",
      body: "Synthetic graphite can be substituted for natural graphite in batteries, however it is significantly more expensive, incurs more emissions and requires more energy to produce.",
    },
    {
      header: "Sri Lankan sourced",
      body: "Sri Lanka was once the world’s largest graphite producing region and is the only country to mine ultra-pure highly crystalline vein graphite. Vein graphite is highly conductive and recommended for Nano Technology, Batteries, Lubricants, Graphene and a range of other purposes.",
    },
  ];
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );

  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1}>
          <div className={Styles.header1}>
            <div>What is </div>
            <div className={Styles.diffColor}>graphite?</div>
          </div>
          <Row className={Styles.header2}>
            Graphite is a crystalline form of carbon that has traditionally been
            used in pencils and lubricants. In recent years, graphite has become
            a critical component in lithium-ion batteries used in renewable
            energy production.
          </Row>
        </Col>
      </Row>
      <ImageLeftContentRight
        data={rightContent}
        image={WhyGraphite}
        width={screenSize > 768 ? "70%" : "90%"}
      />
    </div>
  );
};

export default GraphiteMiddleText;
