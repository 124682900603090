import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import Styles from "./CommonIcon.module.css";
import { useNavigate } from "react-router-dom";
const CommonIcon = ({ path, hoverIcon, icon, name }: any) => {
  const [pathName, setPathName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    setPathName(window.location.pathname);
  }, [window.location.pathname]);
  const handleNav = () => {
    navigate(path);
  };
  return (
    <>
      {pathName == path ? (
        <Col className={Styles.hover}>
          <img src={hoverIcon} alt="bottom-icon" />
          {name}
        </Col>
      ) : (
        <Col className={Styles.icon} onClick={handleNav}>
          <img src={icon} alt="bottom-icon" />
          {name}
        </Col>
      )}
    </>
  );
};

export default CommonIcon;
