import {
  setUserDetails,
  setUserLoginStatus,
  setUserName,
} from "../redux/actions";

// @ts-ignore
const loginSubmit = (data, dispatch) => {
  if (data.otp_verified === false) {
    dispatch(setUserName(data.user_email));
    const nav = "/verify-otp";
    return nav;
  } else {
    dispatch(setUserLoginStatus(true));
    if (data.aws_session !== "") {
      localStorage.setItem("user_email", data.user_email);
      // localStorage.setItem("aws_access_token", data.multi.aws_access_token);
      localStorage.setItem("user_name", data.user_name);
      localStorage.setItem("otp_check", "true");
      localStorage.setItem("user_id", data.user_id);
      localStorage.setItem("token_expire", data.token_expire);
      localStorage.setItem("loggedIn", "true");
      const nav = "/my-profile";
      return nav;
    } else {
      localStorage.setItem("user_email", data.user_email);
      localStorage.setItem("user_name", data.user_name);
      localStorage.setItem("wallet_address", data.wallet_address);
      localStorage.setItem("otp_check", "false");
      localStorage.setItem("access_token", data.access_token);
      localStorage.setItem("user_id", data.user_id);
      localStorage.setItem("token_expire", data.token_expire);
      localStorage.setItem("loggedIn", "true");

      dispatch(setUserDetails(data));
      const nav = "/my-profile";
      return nav;
    }
  }
};
export default loginSubmit;
