import URLS from "../../utils/api-end-points";
import apiServices from "../api_service";

const loginAPI = async (val: any) => {
  const url = URLS.LOGIN_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const resetPasswordAPI = async (val: any) => {
  const url = URLS.RESET_PASSWORD;
  const result = await apiServices.post(url, val);
  return result;
};

const userDetailAPI = async () => {
  const url = URLS.USER_DETAIL_URL;
  const result = await apiServices.post(url);
  return result;
};
const userGetKYCAPI = async () => {
  const url = URLS.GET_KYC_STATUS;
  const result = await apiServices.post(url);
  return result;
};
const makePayment = async (body: any) => {
  const url = URLS.STRIP_PAYMENT;
  const result = await apiServices.post(url, body);
  return result;
};

const confirmForgotPasswordAPI = async (val: any) => {
  const url = URLS.CONFIRM_FORGOT_PASSWORD;
  const result = await apiServices.post(url, val);
  return result;
};

const forgotPasswordAPI = async (val: any) => {
  const url = URLS.FORGOT_PASSWORD_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const resendOtpAPI = async (val: any) => {
  const url = URLS.RESEND_OTP_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const signUpAPI = async (val: any) => {
  const url = URLS.SIGNUP_URL;
  const result = await apiServices.post(url, val);
  return result;
};
const registerAPI = async (val: any) => {
  const url = URLS.REGISTER_API;
  const result = await apiServices.post(url, val);
  return result;
};

const verifyOtpAPI = async (val: any) => {
  const url = URLS.VERIFY_OTP_URL;
  const result = await apiServices.post(url, val);

  return result;
};

const contactUsAPI = async (val: any) => {
  const url = URLS.CONTACT_US;
  const result = await apiServices.post(url, val);

  return result;
};
const userUpdateAPI = async (val: any) => {
  const url = URLS.USER_UPDATE_URL;
  const result = await apiServices.post(url, val);
  return result;
};

const adminDetailsAPI = async () => {
  const url = URLS.ADMIN_DETAILS;
  const result = await apiServices.post(url);
  return result;
};

const getMineAsset = async (): Promise<any> => {
  const url = URLS.GET_MINE_ASSET;
  const result = await apiServices.post(url);

  return result;
};

const buyMineToken = async (val: any) => {
  const url = URLS.BUY_MINE_TOKEN;
  const result = await apiServices.post(url, val);

  return result;
};
const uploadOrderPolicy = async (val: any) => {
  const url = URLS.UPLOAD_ORDER_POLICY;
  const result = await apiServices.post(url, val);
  return result;
};
const updateTransactionHash = async (val: any) => {
  const url = URLS.UPDATE_TRANSACTION;
  const result = await apiServices.post(url, val);
  return result;
};

const getOrderList = async (pageNo: number) => {
  const url = URLS.ORDER_LIST;
  const result = await apiServices.post(url, { page_no: pageNo });
  return result;
};
const getBlogsList = async () => {
  const url = URLS.BLOGS_LIST;
  const result = await apiServices.post(url);
  return result;
};
const getBlog = async (blogId:any) => {
  console.log('get blogs blocg id ==',blogId)
  const url = `${URLS.BLOGS_PAGES}`;
  const result = await apiServices.post(url,blogId);
  return result;
};
const getDashboardData = async () => {
  const url = URLS.DASH_API;
  const result = await apiServices.post(url);
  return result;
};
const getDocumentData = async (val: any) => {
  const url = URLS.GET_DOCUMENT;
  const result = await apiServices.post(url, val);
  return result;
};
const getFAQData = async () => {
  const url = URLS.FAQ_API;
  const result = await apiServices.get(url);
  return result;
};
const getSupportFAQData = async () => {
  const url = URLS.SUPPORT_FAQ_API;
  const result = await apiServices.get(url);
  return result;
};
const getSupportDocsData = async () => {
  const url = URLS.SUPPORT_DOCS_API;
  const result = await apiServices.post(url);
  return result;
};
const getMyCollectionData = async () => {
  const url = URLS.GET_MY_COLLECTION;
  const result = await apiServices.post(url);
  return result;
};
const getMyResellMineData = async () => {
  const url = URLS.GET_MINE_RESELL_COLLECTION;
  const result = await apiServices.post(url);
  return result;
};
const resellMine = async (val: any) => {
  const url = URLS.GET_RESELL_MINE_URL;
  const result = await apiServices.post(url, val);
  return result;
};
const withdrawMine = async (val: any) => {
  const url = URLS.GET_WITHDRAW_MINE_URL;
  const result = await apiServices.post(url, val);
  return result;
};
const redeemMine = async (val: any) => {
  const url = URLS.REDEEM_MINE;
  const result = await apiServices.post(url, val);
  return result;
};
const recoverMineToken = async (val: any) => {
  const url = URLS.RECOVER_TOKEN;
  const result = await apiServices.post(url, val);
  return result;
};
const getOrderBookDetails = async () => {
  const url = URLS.ORDER_BOOK_DETAILS;
  const result = await apiServices.post(url);
  return result;
};
const getSecondaryTradeHistory = async () => {
  const url = URLS.SECONDARY_TRADE_HISTORY;
  const result = await apiServices.post(url);
  return result;
};
const buySecondaryToken = async (val: any) => {
  const url = URLS.PURCHASE_SECONDARY_TOKEN;
  const result = await apiServices.post(url, val);
  return result;
};
const getAllCountry = async () => {
  const url = URLS.ALL_COUNTRY;
  const result = await apiServices.get(url);
  return result;
};
export {
  resendOtpAPI,
  loginAPI,
  makePayment,
  confirmForgotPasswordAPI,
  forgotPasswordAPI,
  signUpAPI,
  verifyOtpAPI,
  contactUsAPI,
  getMineAsset,
  buyMineToken,
  userDetailAPI,
  userUpdateAPI,
  resetPasswordAPI,
  userGetKYCAPI,
  updateTransactionHash,
  getOrderList,
  adminDetailsAPI,
  getDocumentData,
  getDashboardData,
  getFAQData,
  getMyCollectionData,
  getMyResellMineData,
  resellMine,
  withdrawMine,
  redeemMine,
  recoverMineToken,
  buySecondaryToken,
  getOrderBookDetails,
  getSecondaryTradeHistory,
  getAllCountry,
  registerAPI,
  getSupportFAQData,
  getSupportDocsData,
  uploadOrderPolicy,
  getBlogsList,
  getBlog,
};
