import React, { useState } from "react";
import Layout from "../Layouts/Layout";
import HomeComponent from "../Components/HomeComponent";
const Home = () => {
  return (
    <Layout title="Home Page">
      <HomeComponent />
    </Layout>
  );
};

export default Home;
