import React from "react";
import { useSelector } from "react-redux";
import Styles from "./carousel.module.css";

interface props {
  imageCounter: number;
  setImageCounter: (x: number) => void;
}
const CarouselComponent = ({ imageCounter, setImageCounter }: props) => {
  const { banner_data } = useSelector((state: any) => state.dashboardReducer);

  const imageCount = banner_data?.length || 0;
  const handleSlider = (count: number) => {
    setImageCounter(imageCounter + count);
  };
  return (
    <div className={Styles.carouselContainer}>
      <div className="p-2">
        <div className="d-flex px-3 my-3">
          <div className={Styles.sliderBtn}>
            <button
              className={Styles.btnBorder}
              disabled={imageCounter === 0}
              onClick={() => {
                handleSlider(-1);
              }}>
              <span className={imageCounter === 0 ? Styles.btnDisabled : ""}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none">
                  <circle cx="16" cy="16" r="15.5" stroke="#212121" />
                  <path
                    d="M10.6464 16.3536C10.4512 16.1583 10.4512 15.8417 10.6464 15.6464L13.8284 12.4645C14.0237 12.2692 14.3403 12.2692 14.5355 12.4645C14.7308 12.6597 14.7308 12.9763 14.5355 13.1716L11.7071 16L14.5355 18.8284C14.7308 19.0237 14.7308 19.3403 14.5355 19.5355C14.3403 19.7308 14.0237 19.7308 13.8284 19.5355L10.6464 16.3536ZM21 16.5H11V15.5H21V16.5Z"
                    fill="#212121"
                  />
                </svg>
              </span>
            </button>
          </div>
          <div className={Styles.sliderBtn}>
            <button
              className={Styles.btnBorder}
              disabled={imageCounter === imageCount - 1}
              onClick={() => {
                handleSlider(1);
              }}>
              <span
                className={
                  imageCounter === imageCount - 1 ? Styles.btnDisabled : ""
                }>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none">
                  <circle cx="16" cy="16" r="15.5" stroke="#212121" />
                  <path
                    d="M21.3536 16.3536C21.5488 16.1583 21.5488 15.8417 21.3536 15.6464L18.1716 12.4645C17.9763 12.2692 17.6597 12.2692 17.4645 12.4645C17.2692 12.6597 17.2692 12.9763 17.4645 13.1716L20.2929 16L17.4645 18.8284C17.2692 19.0237 17.2692 19.3403 17.4645 19.5355C17.6597 19.7308 17.9763 19.7308 18.1716 19.5355L21.3536 16.3536ZM11 16.5H21V15.5H11V16.5Z"
                    fill="#212121"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
        <div className="px-2 mt-4">
          <span className={Styles.text1}>
            Your current investment in graphite is enough to:
          </span>
        </div>
        <div className="px-2 mt-1 py-1 mb-3">
          <span className={Styles.text2}>
            {banner_data ? banner_data[imageCounter]?.text : "loading"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CarouselComponent;
