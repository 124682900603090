/* eslint-disable react-hooks/exhaustive-deps */
import Styles from "./myCollection.module.css";
import mine1 from "../../Assets/Images/min1.jpeg";
import { useEffect, useState } from "react";
import { Modal } from "../Common/Modal";
import CurrencyFormat from "react-currency-format";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  getMyCollectionData,
  recoverMineToken,
  redeemMine,
  resellMine,
} from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { setMineList } from "../../redux/actions";
import { MineType } from "../../redux/reducers/mine-reducer";
import notify from "../../utils/notify";

const MyCollectionBody = () => {
  const [dataLoading, setDataLoading] = useState(true);
  const [showResellModal, setShowResellModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [sellQty, setSellQty] = useState(0);
  const [sellPrice, setSellPrice] = useState(0);
  const [redeemIn, setRedeemIn] = useState(false);
  const [flag, setFlag] = useState(false);
  const [errorPrice, setErrorPrice] = useState(false);
  const [errorQty, setErrorQty] = useState(false);
  const [errorMsgPrice, setErrorMsgPrice] = useState("");
  const [errorMsgQty, setErrorMsgQty] = useState("");
  const [hideConfirmBtn, setHideConfirmBtn] = useState(false);

  const [selectedMine, setSelectedMine] = useState({} as MineType);
  const [mineTypeWallet, setMineTypeWallet] = useState(true);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { mineList } = useSelector((state: any) => state.mineReducer);
  const { userDetails } = useSelector((state: any) => state.userReducer);

  const getMyCollection = async () => {
    try {
      const res: any = await getMyCollectionData();
      if (res.status) {
        dispatch(setMineList(res.token_collections));
        setDataLoading(false);
      }
    } catch (error) {
      console.log("eerr", error);
      setDataLoading(false);
    }
  };
  useEffect(() => {
    getMyCollection();
  }, []);

  const Panel = ({ data }: any) => {
    if (
      (mineTypeWallet && data.own_qty == 0) ||
      (!mineTypeWallet && data.treasury_qty == 0)
    ) {
      return;
    }

    return (
      <>
        <div className={Styles.panelContainer}>
          <div className="row">
            <div className="col-lg-2 col-md-2">
              <div className={Styles.imgDiv}>
                <img
                  src={data?.mine_images?.[0]?.mine_image_url || mine1}
                  className={Styles.img}
                  alt="panel-img"></img>
              </div>
            </div>
            <div className="col-lg-10 col-md-10">
              <div className={Styles.collectionDiv}>
                <div className="row">
                  <div className="col-lg-10 col-md-10">
                    <div className={Styles.panelHeader}>{data.mine_name}</div>
                    <hr />
                    <div className="row">
                      <div className="col-lg-3 col-md-3">
                        <div className={Styles.panelHeading}>Quantity</div>
                        <div className={Styles.panelValue}>
                          {mineTypeWallet ? data?.own_qty : data?.treasury_qty}
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className={Styles.panelHeading}>
                          Expected Mine End At
                        </div>
                        <div className={Styles.panelValue}>
                          {data?.mine_expected_end_date
                            ? moment(data?.mine_expected_end_date).format("lll")
                            : "-"}
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3">
                        <div className={Styles.panelHeading}>
                          Expected Mine Budget
                        </div>
                        <div className={Styles.panelValue}>
                          <CurrencyFormat
                            value={data?.mine_expected_budget || 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <button
                      className={Styles.btn1}
                      onClick={() => {
                        setSelectedMine(data);
                        setHideConfirmBtn(false);
                        setShowResellModal(true);
                      }}>
                      Resell
                    </button>

                    {!mineTypeWallet && (
                      <button
                        className={Styles.btn2}
                        onClick={() => {
                          setSelectedMine(data);
                          setShowRedeemModal(true);
                        }}>
                        Recover
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const handleResell = async () => {
    handleSellPrice(sellQty);
    handleSellQuantiy(sellPrice);
    setHideConfirmBtn(true);
    if (errorQty || errorPrice) {
      return;
    }
    notify.success("These might take sometime.");
    try {
      const data = {
        mine_id: selectedMine.mine_id,
        owner_type: mineTypeWallet ? "own" : "custody",
        qty: sellQty,
        token_value: sellPrice,
      };
      const res: any = await resellMine(data);
      if (res.status) {
        notify.success(res.message);
        setShowResellModal(false);
        navigate("/resell-request");
      }
    } catch (error) {
      console.log("eerr", error);
      setShowResellModal(false);
      notify.error("Error reselling mine");
    }
  };

  const handleSellPrice = (val: number) => {
    if (val < 0) {
      setErrorPrice(true);
      setErrorMsgPrice("Token value can't be less than 0.");
    } else if (val.toString().length > 10) {
      setErrorPrice(true);
      setErrorMsgPrice("Token value can't be greater than 10 digit.");
    } else {
      setSellPrice(val);
      setErrorPrice(false);
      setErrorMsgPrice("");
    }
  };

  const handleSellQuantiy = (val: number) => {
    if (val <= 0) {
      if (val === 0) {
        setErrorQty(true);
        setErrorMsgQty("Quantity can't be 0.");
      }
      if (val < 0) {
        setErrorQty(true);
        setErrorMsgQty("Quantity can't be less than 0.");
        return;
      }
    }
    if (
      val >
      (mineTypeWallet ? selectedMine?.own_qty : selectedMine?.treasury_qty)
    ) {
      setErrorQty(true);
      setErrorMsgQty("Quantity can't be more than available.");
      return;
    } else if (val.toString().length > 10) {
      setErrorQty(true);
      setErrorMsgQty("Quantity can't be greater than 10 digit.");
    } else {
      setSellQty(val);
      setErrorQty(false);
      setErrorMsgQty("");
    }
  };
  const handleRedeem = async () => {
    handleSellPrice(sellQty);
    setHideConfirmBtn(true);
    try {
      const data = {
        mine_id: selectedMine.mine_id,
        recover_address: userDetails?.wallet_address,
        token_qty: sellQty,
      };
      const res: any = await recoverMineToken(data);
      if (res.status) {
        notify.success("Recover Token Successfully");
        setShowResellModal(false);
        // navigate("/resell-request");
      }
    } catch (error) {
      console.log("eerr", error);
      setShowResellModal(false);
      notify.error("Error reselling mine");
    }
  };

  return (
    <div className="col-12">
      <div className={Styles.container}>
        <div className={Styles.headerContainer}>
          <div className={Styles.header}>My Collections</div>
          <div className={Styles.toggleHeader}>
            <div className={Styles.togglebtn}>
              <label className={`${Styles.toggle}`}>Custodian Wallet</label>
              <div className={`form-check form-switch`}>
                <input
                  checked={mineTypeWallet}
                  onClick={() => setMineTypeWallet(!mineTypeWallet)}
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                />
                <label
                  className={`${Styles.toggle} form-check-label`}
                  htmlFor="flexSwitchCheckDefault">
                  My Wallet
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className={Styles.collectionPanel}>
          <div className={Styles.collectionPanelBody}>
            {mineList && mineList.length > 0 ? (
              mineList.map((d: MineType) => <Panel data={d} key={d.mine_id} />)
            ) : (
              <div className="text-white text-center mt-4">
                <h5>{dataLoading ? "Loading !!!" : "No Mine Present "} </h5>
              </div>
            )}
          </div>
          <div className={Styles.collectionPanelFooter}></div>
        </div>
      </div>
      {/* Resell Modal */}
      <Modal
        show={showResellModal}
        onHide={() => {
          setShowResellModal(false);
        }}
        title=""
        confirmBtnText="Transfer Resell Escrow"
        confirmBtnClassName={` justify-content-center mx-auto  ${Styles.btn1}`}
        visibleFooter
        visibleHeaderCloseIcon
        onConfirm={() => {
          handleResell();
        }}
        visibleConfirmBtn={!hideConfirmBtn && !errorPrice && !errorQty}
        visibleFooterCloseButton={false}>
        <div className="px-3 mt-1">
          <h4 className="text-left">Resell</h4>
          <div className="row">
            <div className="mt-3">
              <label htmlFor="availableQty" className="form-label">
                Available Quantity
              </label>
              <input
                type="number"
                className="form-control"
                id="availableQty"
                disabled
                value={
                  mineTypeWallet
                    ? selectedMine?.own_qty
                    : selectedMine?.treasury_qty || 0
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="mt-3">
              <label htmlFor="resellpriceQty" className="form-label">
                Enter Token Value
              </label>
              <input
                type="number"
                className="form-control"
                id="resellpriceQty"
                placeholder="Token Value"
                defaultValue={0}
                min={0}
                maxLength={10}
                onChange={(e) => {
                  handleSellPrice(Number(e.target.value));
                }}
              />
            </div>
            <div>
              {errorPrice && (
                <p className="text-danger text-center mt-1 ">{errorMsgPrice}</p>
              )}
            </div>
          </div>
          <div className="row">
            <div className="mt-3">
              <label htmlFor="resellavailableQty" className="form-label">
                Enter Resell Quantity
              </label>
              <input
                type="number"
                className="form-control"
                id="resellavailableQty"
                placeholder="Resell Quantity"
                min={1}
                defaultValue={0}
                max={
                  mineTypeWallet
                    ? selectedMine?.own_qty
                    : selectedMine?.treasury_qty
                }
                onChange={(e) => {
                  handleSellQuantiy(Number(e.target.value));
                }}
              />
            </div>
          </div>
          <div>
            {errorQty && (
              <p className="text-danger text-center mt-1 ">{errorMsgQty}</p>
            )}
          </div>
        </div>
      </Modal>
      {/* Reedem Modal */}
      <Modal
        show={showRedeemModal}
        onHide={() => {
          setShowRedeemModal(false);
        }}
        title=""
        confirmBtnText="Transfer Redeem Escrow"
        confirmBtnClassName={` justify-content-center mx-auto  ${Styles.btn1}`}
        visibleFooter
        visibleHeaderCloseIcon
        onConfirm={() => {
          handleRedeem();
        }}
        visibleConfirmBtn={selectedMine.treasury_qty >= sellQty}
        visibleFooterCloseButton={false}>
        <div className="px-3 mt-1">
          <h4 className="text-left">Recover</h4>
          <div className="row">
            <div className="mt-3">
              <label htmlFor="availableQty" className="form-label">
                Available Quantity
              </label>
              <input
                type="number"
                className="form-control"
                id="availableQty"
                disabled
                value={
                  mineTypeWallet
                    ? selectedMine?.own_qty
                    : selectedMine?.treasury_qty || 0
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="mt-3">
              <label htmlFor="resellavailableQty" className="form-label">
                Enter Recover Quantity
              </label>
              <input
                type="number"
                className="form-control"
                id="resellavailableQty"
                placeholder="resell quantity"
                defaultValue={0}
                min={1}
                max={
                  mineTypeWallet
                    ? selectedMine?.own_qty
                    : selectedMine?.treasury_qty
                }
                onChange={(e) => {
                  setSellQty(Number(e.target.value));
                }}
              />
            </div>
            <div>
              {sellQty >
                (mineTypeWallet
                  ? selectedMine?.own_qty
                  : selectedMine?.treasury_qty) && (
                <p className="text-danger text-center mt-1 ">
                  Quantity can't be more than available.
                </p>
              )}
            </div>
          </div>
          {/* <div className="row ">
            <div className="mt-3">
              <label htmlFor="resellavailableQty" className="form-label">
                Redeem Token in
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios1"
                  value="option1"
                  onClick={() => setRedeemIn(false)}
                  checked
                />
                <label className="form-check-label">Fiat</label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="exampleRadios"
                  id="exampleRadios2"
                  onClick={() => setRedeemIn(true)}
                  value="option2"
                />
                <label className="form-check-label">Crypto</label>
              </div>
            </div>
          </div> */}
        </div>
      </Modal>
    </div>
  );
};
export default MyCollectionBody;
