import React from "react";
import Layout from "../Layouts/Layout";
import ContactUsComponent from "../Components/ContactUsComponent";
const ContactUs = () => {
  return (
    <Layout title="Contact Us">
      <ContactUsComponent />
    </Layout>
  );
};

export default ContactUs;
