import React from "react";
import Layout from "../Layouts/Layout";
import WhyGraphiteComponent from "../Components/WhyGraphite";
const WhyGraphite = () => {
  return (
    <Layout title="Why Graphite">
      <WhyGraphiteComponent />
    </Layout>
  );
};

export default WhyGraphite;
