// @ts-nocheck

import { useNavigate } from "react-router-dom";
import PasswordSuccess from "../../Assets/Images/PasswordSuccess.svg";
import Styles from "./passwordChangedSuccess.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";

const PasswordChangedSuccessComponent = () => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate("/login");
  };

  return (
    <>
      <div className={Styles.MainDiv}>
        <img className={Styles.SuccessIcon} src={PasswordSuccess} alt="Close" />
        <h2 className={Styles.Heading}>Password Changed!</h2>
        <p className={Styles.Paragraph}>
          Your password has been changed successfully.
        </p>

        <button
          className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}
          type="button"
          onClick={() => handleSignIn()}
        >
          <span className={Styles.ButtonText}>Log in</span>
        </button>
      </div>
    </>
  );
};

export default PasswordChangedSuccessComponent;
