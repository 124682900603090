// @ts-nocheck
import { useState, useEffect } from "react";
import Avatar from "react-avatar";
import { Link, useNavigate } from "react-router-dom";
import HeaderStyle from "./UserHeader.module.css";
import FooterStyle from "../Footer.module.css";
import ButtonStyle from "../Button.module.css";
import MobileMenuIconBlack from "../../../Assets/Images/MobileMenuIconBlack.svg";
import MenuWhite from "../../../Assets/Images/menuWhite.svg";
import MenuYellow from "../../../Assets/Images/menuYellow.svg";
import whiteCross from "../../../Assets/Images/whiteCross.svg";
import BlackX from "../../../Assets/Images/BlackX.svg";
import CloseOrange from "../../../Assets/Images/CloseOrange.svg";
import { useDispatch, useSelector } from "react-redux";
import { clearLocalStorage } from "../../../utils/common";
import { setUserLogout } from "../../../redux/actions/user-action";
import HeaderIcon from "../../../Assets/Images/header-white.svg";
import SavalaLogo from "../../../Assets/Images/savala-logo.svg";
import profileIconWhite from "../../../Assets/Images/profileIconWhite.svg";
import profileIconBlack from "../../../Assets/Images/profileIconBlack.svg";
import logoutWhite from "../../../Assets/Images/logoutWhite.svg";
import logoutBlack from "../../../Assets/Images/logoutBlack.svg";
import Dropdown from "react-bootstrap/Dropdown";
import "./dropDown.css";
import HeaderIconComponent from "./icons/HeaderIcon";
import MyOrdersIconComponent from "./icons/MyOrdersIcon";
import PurchaseIconComponent from "./icons/PurchaseIcon";
import PurchaseTokenIconComponent from "./icons/PurchaseTokenIcon";
import SupportIconComponent from "./icons/SupportIcon";
import notify from "../../../utils/notify";
import MyCollectionIconComponent from "./icons/MyCollection";
import dashboardWhiteIcon from "../../../Assets/Images/icon-dashboard-white.svg";
import dashboardBlackIcon from "../../../Assets/Images/icon-dashboard-black.svg";
import myOrdersBlackIcon from "../../../Assets/Images/icon-orders-black.svg";
import myOrdersWhiteIcon from "../../../Assets/Images/icon-orders-white.svg";
import purchaseBlackIcon from "../../../Assets/Images/icon-purchase-black.svg";
import purchaseWhiteIcon from "../../../Assets/Images/icon-purchase-white.svg";
import tokenSaleBlackIcon from "../../../Assets/Images/icon-token-sale-black.svg";
import tokenSaleWhiteIcon from "../../../Assets/Images/icon-token-sale-white.svg";
import supportBlackIcon from "../../../Assets/Images/icon-support-black.svg";
import supportWhiteIcon from "../../../Assets/Images/icon-support-white.svg";

const Header = ({ title }: any) => {
  const [overLayOpen, setOverLayOpen] = useState<boolean>(false);
  const localUserName = localStorage.getItem("user_name");
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [forPage, setForPage] = useState("");
  const [hover, setHover] = useState("");
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  const [devCheck, setDevCheck] = useState(
    process.env.REACT_APP_DEV_BUILD === "true"
  );
  const { isLoggedIn, userDetails, kycStatus } = useSelector(
    (state: any) => state.userReducer
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNav = (nav: string) => {
    navigate(nav);
  };
  const handleLogout = () => {
    clearLocalStorage();
    dispatch(setUserLogout());
    navigate("/login");
  };
  const toggleMobileMenu = () => {
    setOverLayOpen(!overLayOpen);
  };
  const closeOverlay = () => {
    setOverLayOpen(!overLayOpen);
  };
  const handleNavigate = (path: string) => {
    navigate(path);
    // if (kycStatus === "Verified") {
    //   navigate(path);
    // } else {
    //   // alert("Not Allowed");
    //   notify.error("Complete your KYC first");
    //   navigate("/my-profile");
    // }
  };
  useEffect(() => {
    if (screenSize !== 0) {
      screenSize >= 768 ? setIsMobileScreen(false) : setIsMobileScreen(true);
    }
  }, [screenSize]);

  useEffect(() => {
    if (title === "Purchase" || title === "Purchase Token") setForPage("black");
    else if (title === "My Orders" || title === "Dashboard")
      setForPage("green");
    else setForPage("white");
  }, [title]);
  return (
    <>
      <div
        className={
          title === "Purchase" || title === "Purchase Token"
            ? HeaderStyle.PurchaseMobileMenuOverlay
            : title === "My Orders" || title === "Dashboard"
            ? HeaderStyle.MobileMenuOverlay
            : HeaderStyle.OthersMobileMenuOverlay
        }
        // className={HeaderStyle.MobileMenuOverlay}
        style={{ width: `${(overLayOpen && "100%") || "0px"}` }}>
        {/* {isMobileScreen ? (
          // <div className={HeaderStyle.OverlayContent}>
          //   <div className={HeaderStyle.TopMobileCotent}>
          //     <div className={HeaderStyle.MobileMenuHeader}>
          //       <Link
          //         to="#"
          //         className={HeaderStyle.CloseBtn}
          //         onClick={closeOverlay}>
          //         <img
          //           src={
          //             forPage === "green"
          //               ? whiteCross
          //               : forPage === "black"
          //               ? CloseOrange
          //               : BlackX
          //           }
          //           alt="Close"
          //           onClick={toggleMobileMenu}
          //         />
          //       </Link>
          //       <div
          //         style={{ paddingLeft: "78px", alignSelf: "center" }}
          //         className={HeaderStyle.Logo}>
          //         <Link to="/" className={HeaderStyle.FooterLink}>
          //           <img
          //             style={{ width: "100px", height: "28px" }}
          //             src={title === "My Profile" ? SavalaLogo : HeaderIcon}
          //             alt="Logo"
          //           />
          //         </Link>
          //       </div>
          //     </div>
          //     <Link
          //       to="/my-profile"
          //       className={
          //         forPage !== "white"
          //           ? HeaderStyle.MobileMenu
          //           : HeaderStyle.MobileWhiteMenu
          //       }>
          //       <div className={HeaderStyle.mobileMenuList}>
          //         <div>
          //           <img
          //             src={
          //               forPage !== "white"
          //                 ? profileIconWhite
          //                 : profileIconBlack
          //             }
          //             alt="profile"
          //           />
          //         </div>
          //         <div style={{ alignSelf: "center" }}>My Profile</div>
          //       </div>
          //     </Link>
          //     <button
          //       className={
          //         forPage !== "white"
          //           ? `${HeaderStyle.MobileMenu} ${HeaderStyle.MobileMenubtn}`
          //           : `${HeaderStyle.MobileWhiteMenu} ${HeaderStyle.MobileMenubtn}`
          //       }
          //       onClick={handleLogout}>
          //       <div className={HeaderStyle.mobileMenuList}>
          //         <div>
          //           <img
          //             src={forPage !== "white" ? logoutWhite : logoutBlack}
          //             alt="logout"
          //           />
          //         </div>
          //         <div style={{ alignSelf: "center" }}>Log Out</div>
          //       </div>
          //     </button>
          //   </div>
          // </div>
          <div className={HeaderStyle.OverlayContent}>
            <div className={HeaderStyle.TopMobileCotent}>
              <div className={HeaderStyle.MobileMenuHeader}>
                <Link
                  to="#"
                  className={HeaderStyle.CloseBtn}
                  onClick={closeOverlay}>
                  <img
                    src={
                      forPage === "green"
                        ? whiteCross
                        : forPage === "black"
                        ? CloseOrange
                        : BlackX
                    }
                    alt="Close"
                    onClick={toggleMobileMenu}
                  />
                </Link>
                <div
                  style={{ paddingLeft: "78px", alignSelf: "center" }}
                  className={HeaderStyle.Logo}>
                  <Link to="/" className={HeaderStyle.FooterLink}>
                    <img
                      style={{ width: "100px", height: "28px" }}
                      src={title === "My Profile" ? SavalaLogo : HeaderIcon}
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>
              <div style={{ display: "grid", gap: "15px" }}>
                <Link
                  to="/dashboard"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? dashboardWhiteIcon
                            : dashboardBlackIcon
                        }
                        alt="dashboard"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>Dashboard</div>
                  </div>
                </Link>
                <Link
                  to="/my-orders"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? myOrdersWhiteIcon
                            : myOrdersBlackIcon
                        }
                        alt="my-orders"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>My Orders</div>
                  </div>
                </Link>
                <Dropdown className={HeaderStyle.DesktopMenu}>
                  <Dropdown.Toggle className={HeaderStyle.dropMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                        <img
                          src={
                            forPage !== "white"
                              ? profileIconWhite
                              : profileIconBlack
                          }
                          alt="my-collection"
                        />
                      </div>
                      <div
                        className={HeaderStyle.tabView}
                        style={
                          forPage === "white"
                            ? { color: "black" }
                            : { color: "white" }
                        }>
                        My Collection
                      </div>
                    </div>
                    <i
                      className="fa fa-angle-down"
                      style={
                        forPage === "white"
                          ? { color: "black" }
                          : { color: "white" }
                      }
                      aria-hidden="true"></i>
                  </Dropdown.Toggle>
                  {devCheck && (
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/my-collection")}>
                        My Collections
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/redemption-request")}>
                        Redemption Request
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/resell-request")}>
                        Resell Request
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/secondary-sale")}>
                        Secondary Sale
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/withdraw-request")}>
                        Withdraw Request
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                <Link
                  to="/purchase"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? purchaseWhiteIcon
                            : purchaseBlackIcon
                        }
                        alt="purchase"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>Purchase IGO</div>
                  </div>
                </Link>
                <Link
                  to="/purchase-token"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? tokenSaleWhiteIcon
                            : tokenSaleBlackIcon
                        }
                        alt="purchase-token"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>Token Sale</div>
                  </div>
                </Link>
                <Link
                  to="/support"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? supportWhiteIcon
                            : supportBlackIcon
                        }
                        alt="support"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>Support</div>
                  </div>
                </Link>
                <Link
                  to="/my-profile"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? profileIconWhite
                            : profileIconBlack
                        }
                        alt="profile"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>My Profile</div>
                  </div>
                </Link>
              </div>
              <button
                className={
                  forPage !== "white"
                    ? `${HeaderStyle.MobileMenu} ${HeaderStyle.MobileMenubtn}`
                    : `${HeaderStyle.MobileWhiteMenu} ${HeaderStyle.MobileMenubtn}`
                }
                onClick={handleLogout}>
                <div className={HeaderStyle.mobileMenuList}>
                  <div>
                    <img
                      src={forPage !== "white" ? logoutWhite : logoutBlack}
                      alt="logout"
                    />
                  </div>
                  <div style={{ alignSelf: "center" }}>Log Out</div>
                </div>
              </button>
            </div>
          </div>
        ) : (
          <div className={HeaderStyle.OverlayContent}>
            <div className={HeaderStyle.TopMobileCotent}>
              <div className={HeaderStyle.MobileMenuHeader}>
                <Link
                  to="#"
                  className={HeaderStyle.CloseBtn}
                  onClick={closeOverlay}>
                  <img
                    src={
                      forPage === "green"
                        ? whiteCross
                        : forPage === "black"
                        ? CloseOrange
                        : BlackX
                    }
                    alt="Close"
                    onClick={toggleMobileMenu}
                  />
                </Link>
                <div
                  style={{ paddingLeft: "78px", alignSelf: "center" }}
                  className={HeaderStyle.Logo}>
                  <Link to="/" className={HeaderStyle.FooterLink}>
                    <img
                      style={{ width: "100px", height: "28px" }}
                      src={title === "My Profile" ? SavalaLogo : HeaderIcon}
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>
              <div style={{ display: "grid", gap: "15px" }}>
                <Link
                  to="/dashboard"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? dashboardWhiteIcon
                            : dashboardBlackIcon
                        }
                        alt="dashboard"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>Dashboard</div>
                  </div>
                </Link>
                <Link
                  to="/my-orders"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? myOrdersWhiteIcon
                            : myOrdersBlackIcon
                        }
                        alt="my-orders"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>My Orders</div>
                  </div>
                </Link>
                <Dropdown className={HeaderStyle.DesktopMenu}>
                  <Dropdown.Toggle className={HeaderStyle.dropMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                        <img
                          src={
                            forPage !== "white"
                              ? profileIconWhite
                              : profileIconBlack
                          }
                          alt="my-collection"
                        />
                      </div>
                      <div
                        className={HeaderStyle.tabView}
                        style={
                          forPage === "white"
                            ? { color: "black" }
                            : { color: "white" }
                        }>
                        My Collection
                      </div>
                    </div>
                    <i
                      className="fa fa-angle-down"
                      style={
                        forPage === "white"
                          ? { color: "black" }
                          : { color: "white" }
                      }
                      aria-hidden="true"></i>
                  </Dropdown.Toggle>
                  {devCheck && (
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/my-collection")}>
                        My Collections
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/redemption-request")}>
                        Redemption Request
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/resell-request")}>
                        Resell Request
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/secondary-sale")}>
                        Secondary Sale
                      </Dropdown.Item>
                      <Dropdown.Item
                        className={HeaderStyle.dropItems}
                        onClick={() => handleNav("/withdraw-request")}>
                        Withdraw Request
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
                <Link
                  to="/purchase"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? purchaseWhiteIcon
                            : purchaseBlackIcon
                        }
                        alt="purchase"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>Purchase IGO</div>
                  </div>
                </Link>
                <Link
                  to="/purchase-token"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? tokenSaleWhiteIcon
                            : tokenSaleBlackIcon
                        }
                        alt="purchase-token"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>Token Sale</div>
                  </div>
                </Link>
                <Link
                  to="/support"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? supportWhiteIcon
                            : supportBlackIcon
                        }
                        alt="support"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>Support</div>
                  </div>
                </Link>
                <Link
                  to="/my-profile"
                  className={
                    forPage !== "white"
                      ? HeaderStyle.MobileMenu
                      : HeaderStyle.MobileWhiteMenu
                  }>
                  <div className={HeaderStyle.mobileMenuList}>
                    <div>
                      <img
                        src={
                          forPage !== "white"
                            ? profileIconWhite
                            : profileIconBlack
                        }
                        alt="profile"
                      />
                    </div>
                    <div style={{ alignSelf: "center" }}>My Profile</div>
                  </div>
                </Link>
              </div>
              <button
                className={
                  forPage !== "white"
                    ? `${HeaderStyle.MobileMenu} ${HeaderStyle.MobileMenubtn}`
                    : `${HeaderStyle.MobileWhiteMenu} ${HeaderStyle.MobileMenubtn}`
                }
                onClick={handleLogout}>
                <div className={HeaderStyle.mobileMenuList}>
                  <div>
                    <img
                      src={forPage !== "white" ? logoutWhite : logoutBlack}
                      alt="logout"
                    />
                  </div>
                  <div style={{ alignSelf: "center" }}>Log Out</div>
                </div>
              </button>
            </div>
          </div>
        )} */}
        <div className={HeaderStyle.OverlayContent}>
          <div className={HeaderStyle.TopMobileCotent}>
            <div className={HeaderStyle.MobileMenuHeader}>
              <Link
                to="#"
                className={HeaderStyle.CloseBtn}
                onClick={closeOverlay}>
                <img
                  src={
                    forPage === "green"
                      ? whiteCross
                      : forPage === "black"
                      ? CloseOrange
                      : BlackX
                  }
                  alt="Close"
                  onClick={toggleMobileMenu}
                />
              </Link>
              <div
                style={{ paddingLeft: "78px", alignSelf: "center" }}
                className={HeaderStyle.Logo}>
                <Link to="/" className={HeaderStyle.FooterLink}>
                  <img
                    style={{ width: "100px", height: "28px" }}
                    src={title === "My Profile" ? SavalaLogo : HeaderIcon}
                    alt="Logo"
                  />
                </Link>
              </div>
            </div>
            <div style={{ display: "grid", gap: "15px" }}>
              <Link
                to="/dashboard"
                className={
                  forPage !== "white"
                    ? HeaderStyle.MobileMenu
                    : HeaderStyle.MobileWhiteMenu
                }>
                <div className={HeaderStyle.mobileMenuList}>
                  <div>
                    <img
                      src={
                        forPage !== "white"
                          ? dashboardWhiteIcon
                          : dashboardBlackIcon
                      }
                      alt="dashboard"
                    />
                  </div>
                  <div style={{ alignSelf: "center" }}>Dashboard</div>
                </div>
              </Link>
              <Link
                to="/my-orders"
                className={
                  forPage !== "white"
                    ? HeaderStyle.MobileMenu
                    : HeaderStyle.MobileWhiteMenu
                }>
                <div className={HeaderStyle.mobileMenuList}>
                  <div>
                    <img
                      src={
                        forPage !== "white"
                          ? myOrdersWhiteIcon
                          : myOrdersBlackIcon
                      }
                      alt="my-orders"
                    />
                  </div>
                  <div style={{ alignSelf: "center" }}>My Orders</div>
                </div>
              </Link>
              {devCheck && (
                <Dropdown className={HeaderStyle.DesktopMenu}>
                  <Dropdown.Toggle className={HeaderStyle.dropMenu}>
                    <div className={HeaderStyle.mobileMenuList}>
                      <div>
                        <img
                          src={
                            forPage !== "white"
                              ? profileIconWhite
                              : profileIconBlack
                          }
                          alt="my-collection"
                        />
                      </div>
                      <div
                        className={HeaderStyle.tabView}
                        style={
                          forPage === "white"
                            ? { color: "black" }
                            : { color: "white" }
                        }>
                        My Collection
                      </div>
                    </div>
                    <i
                      className="fa fa-angle-down"
                      style={
                        forPage === "white"
                          ? { color: "black" }
                          : { color: "white" }
                      }
                      aria-hidden="true"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      className={HeaderStyle.dropItems}
                      onClick={() => handleNav("/my-collection")}>
                      My Collections
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={HeaderStyle.dropItems}
                      onClick={() => handleNav("/redemption-request")}>
                      Redemption Request
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={HeaderStyle.dropItems}
                      onClick={() => handleNav("/resell-request")}>
                      Resell Request
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={HeaderStyle.dropItems}
                      onClick={() => handleNav("/secondary-sale")}>
                      Secondary Sale
                    </Dropdown.Item>
                    <Dropdown.Item
                      className={HeaderStyle.dropItems}
                      onClick={() => handleNav("/withdraw-request")}>
                      Withdraw Request
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Link
                to="/purchase"
                className={
                  forPage !== "white"
                    ? HeaderStyle.MobileMenu
                    : HeaderStyle.MobileWhiteMenu
                }>
                <div className={HeaderStyle.mobileMenuList}>
                  <div>
                    <img
                      src={
                        forPage !== "white"
                          ? purchaseWhiteIcon
                          : purchaseBlackIcon
                      }
                      alt="purchase"
                    />
                  </div>
                  <div style={{ alignSelf: "center" }}>Purchase IGO</div>
                </div>
              </Link>
              <Link
                to="/purchase-token"
                className={
                  forPage !== "white"
                    ? HeaderStyle.MobileMenu
                    : HeaderStyle.MobileWhiteMenu
                }>
                <div className={HeaderStyle.mobileMenuList}>
                  <div>
                    <img
                      src={
                        forPage !== "white"
                          ? tokenSaleWhiteIcon
                          : tokenSaleBlackIcon
                      }
                      alt="purchase-token"
                    />
                  </div>
                  <div style={{ alignSelf: "center" }}>Token Sale</div>
                </div>
              </Link>
              <Link
                to="/support"
                className={
                  forPage !== "white"
                    ? HeaderStyle.MobileMenu
                    : HeaderStyle.MobileWhiteMenu
                }>
                <div className={HeaderStyle.mobileMenuList}>
                  <div>
                    <img
                      src={
                        forPage !== "white"
                          ? supportWhiteIcon
                          : supportBlackIcon
                      }
                      alt="support"
                    />
                  </div>
                  <div style={{ alignSelf: "center" }}>Support</div>
                </div>
              </Link>
              <Link
                to="/my-profile"
                className={
                  forPage !== "white"
                    ? HeaderStyle.MobileMenu
                    : HeaderStyle.MobileWhiteMenu
                }>
                <div className={HeaderStyle.mobileMenuList}>
                  <div>
                    <img
                      src={
                        forPage !== "white"
                          ? profileIconWhite
                          : profileIconBlack
                      }
                      alt="profile"
                    />
                  </div>
                  <div style={{ alignSelf: "center" }}>My Profile</div>
                </div>
              </Link>
            </div>
            <button
              className={
                forPage !== "white"
                  ? `${HeaderStyle.MobileMenu} ${HeaderStyle.MobileMenubtn}`
                  : `${HeaderStyle.MobileWhiteMenu} ${HeaderStyle.MobileMenubtn}`
              }
              onClick={handleLogout}>
              <div className={HeaderStyle.mobileMenuList}>
                <div>
                  <img
                    src={forPage !== "white" ? logoutWhite : logoutBlack}
                    alt="logout"
                  />
                </div>
                <div style={{ alignSelf: "center" }}>Log Out</div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <header
        className={
          title === "Purchase" || title === "Purchase Token"
            ? HeaderStyle.PurchaseHeaderContainer
            : forPage === "white" && isMobileScreen
            ? HeaderStyle.OthersHeaderContainer
            : HeaderStyle.HeaderContainer
        }
        // className={
        //   title === "Purchase" || title == "Purchase Token"
        //     ? HeaderStyle.PurchaseHeaderContainer
        //     : HeaderStyle.HeaderContainer
        // }
      >
        <div className={HeaderStyle.MobileMenuIcon}>
          <img
            src={
              forPage === "green" ||
              ((title === "My Profile" || title === "support") &&
                !isMobileScreen)
                ? MenuWhite
                : forPage === "black"
                ? MenuYellow
                : MobileMenuIconBlack
            }
            alt="Logo"
            onClick={toggleMobileMenu}
          />
        </div>
        <div className={HeaderStyle.Logo}>
          <Link to="/" className={HeaderStyle.FooterLink}>
            <img
              src={
                forPage === "white" && isMobileScreen ? SavalaLogo : HeaderIcon
              }
              alt="Logo"
            />
          </Link>
        </div>
        <Link to="/login" className={HeaderStyle.MobileLoginMenu}>
          Log in
        </Link>
        <div className={HeaderStyle.DesktopMenuSection}>
          <Link
            to="/dashboard"
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => setHover("/dashboard")}
            onMouseLeave={() => setHover("")}>
            <HeaderIconComponent
              path={window.location.pathname}
              name="Dashboard"
              hover={hover}
            />
          </Link>
          <Link
            to="/my-orders"
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => setHover("/my-orders")}
            onMouseLeave={() => setHover("")}>
            <MyOrdersIconComponent
              path={window.location.pathname}
              name="My Orders"
              hover={hover}
            />
          </Link>
          {devCheck && (
            <Dropdown className={HeaderStyle.DesktopMenu}>
              <Dropdown.Toggle className={HeaderStyle.dropMenu}>
                <MyCollectionIconComponent
                  path={window.location.pathname}
                  name="Collections"
                />
                <i
                  className="fa fa-angle-down"
                  style={
                    window.location.pathname === "/my-collection"
                      ? { color: "white" }
                      : {}
                  }
                  aria-hidden="true"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  className={HeaderStyle.dropItems}
                  onClick={() => handleNav("/my-collection")}>
                  My Collections
                </Dropdown.Item>
                <Dropdown.Item
                  className={HeaderStyle.dropItems}
                  onClick={() => handleNav("/redemption-request")}>
                  Redemption Request
                </Dropdown.Item>
                <Dropdown.Item
                  className={HeaderStyle.dropItems}
                  onClick={() => handleNav("/resell-request")}>
                  Resell Request
                </Dropdown.Item>
                <Dropdown.Item
                  className={HeaderStyle.dropItems}
                  onClick={() => handleNav("/secondary-sale")}>
                  Secondary Sale
                </Dropdown.Item>
                <Dropdown.Item
                  className={HeaderStyle.dropItems}
                  onClick={() => handleNav("/withdraw-request")}>
                  Withdraw Request
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}

          <div
            onClick={() => handleNavigate("/purchase")}
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => setHover("/purchase")}
            onMouseLeave={() => setHover("")}>
            <PurchaseIconComponent
              path={window.location.pathname}
              name="Purchase IGO"
              hover={hover}
            />
          </div>
          <div
            onClick={() => handleNavigate("/purchase-token")}
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => setHover("/purchase-token")}
            onMouseLeave={() => setHover("")}>
            <PurchaseTokenIconComponent
              path={window.location.pathname}
              name="Token Sale"
              hover={hover}
            />
          </div>
          <Link
            to="/support"
            className={HeaderStyle.DesktopMenu}
            onMouseEnter={() => setHover("/support")}
            onMouseLeave={() => setHover("")}>
            <SupportIconComponent
              path={window.location.pathname}
              name="Supports"
              hover={hover}
            />
          </Link>
        </div>
        <div className={HeaderStyle.DesktopMenuSection}>
          {!isLoggedIn ? (
            <>
              <button
                className={ButtonStyle.PlainBtn}
                onClick={() => handleNav("/login")}>
                Log in
              </button>
              <button
                onClick={() => handleNav("/signup")}
                className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeBlackBackground}`}>
                Sign up
              </button>
            </>
          ) : (
            // <button
            //   className={ButtonStyle.PlainBtn}
            //   onClick={() => handleLogout()}>
            //   Logout
            // </button>
            <Dropdown>
              <Dropdown.Toggle className={HeaderStyle.Logout}>
                {userDetails?.user_image_url ? (
                  <Avatar
                    size="32"
                    round="20px"
                    src={userDetails?.user_image_url}
                  />
                ) : (
                  <Avatar
                    name={userDetails.name ? userDetails.name : ""}
                    size="34"
                    round={true}
                  />
                )}

                {/* <div className={HeaderStyle.dropdownIcon} /> */}
                {userDetails?.first_name?.length > 10
                  ? userDetails.first_name.slice(0, 10) + "..."
                  : userDetails.first_name}
                <i className="fa fa-angle-down" aria-hidden="true"></i>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleNav("/my-profile")}>
                  My Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </header>
    </>
  );
};
export default Header;
