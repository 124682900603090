import { useEffect } from "react";
import Styles from "./order.module.css";
import { orderType } from "../../MyOrder/OrderComponent";
import moment from "moment";
import CurrencyFormat from "react-currency-format";
interface props {
  data: orderType[];
}
interface OrderDataType {
  orderData: orderType;
}
const Order = ({ orderData }: OrderDataType) => {
  return (
    <div className={Styles.orderBox} key={"order-" + orderData.order_id}>
      <div className="row gy-3 py-1">
        <div className="d-flex justify-content-between flex-wrap">
          <div className={Styles.orderItem}>
            <div className={Styles.orderBoxTitle}> Order Date</div>
            <div className={Styles.oderBoxValue}>
              <span className="text-left">
                {moment(orderData.date_order).format("lll")}
              </span>
            </div>
          </div>
          <div className={Styles.orderItem}>
            <div className={Styles.orderBoxTitle}>Reference #</div>
            <div className={Styles.oderBoxValue}>{orderData.order_name}</div>
          </div>
          <div className={Styles.orderItem}>
            <div className={Styles.orderBoxTitle}>Qty</div>
            <div className={Styles.oderBoxValue}>{orderData.qty}</div>
          </div>
        </div>
        <div className=" d-flex justify-content-between flex-wrap">
          <div className={Styles.orderItem}>
            <div className={Styles.orderBoxTitle}>Amount Paid</div>
            <div className={Styles.oderBoxValue}>
              <CurrencyFormat
                value={orderData.amount}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </div>
          </div>
          <div className={Styles.orderItem}>
            <div className={Styles.orderBoxTitle}>Payment Type</div>
            <div className={Styles.oderBoxValue}>
              {orderData.payment_mode ? orderData.payment_mode : "UNSURE"}
            </div>
          </div>
          <div className={Styles.orderItem}>
            <div className={Styles.orderBoxTitle}>Order Type</div>
            <div className={Styles.oderBoxValue}>
              {orderData.order_type ? orderData.order_type : "-"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderComponent = ({ data }: props) => {
  useEffect(() => {}, []);

  return (
    <div className={Styles.orderContainer}>
      <div className={Styles.orderHeadingContainer}>
        <div className={Styles.orderEllipse}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none">
            <circle cx="8.5" cy="9" r="8.5" fill="#F3A537" />
          </svg>
        </div>
        <div className={Styles.orderHeading}>Recent Orders</div>
      </div>
      <div className="col-12 mb-3">
        {data && data.length > 0 ? (
          data.map((d: orderType, index: number) => {
            if (index < 5) {
              return <Order orderData={d} key={d.order_id + " - order"} />;
            }
          })
        ) : (
          <div className={Styles.orderBox}>
            <div className="row gy-3 py-1">
              <div className="d-flex align-items-center justify-content-center">
                No Current Orders
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default OrderComponent;
