import React from "react";
import Layout from "../Layouts/Layout";
import SignupComponent from "../Components/SignupComponent";

const Signup = () => {
  return (
    <Layout title="Create Account">
      <SignupComponent />
    </Layout>
  );
};

export default Signup;
