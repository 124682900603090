import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./ImageLeftContentRight.module.css";
const ImageLeftContentRight = ({
  data,
  image,
  number = false,
  width = "70%",
  padding = false,
  imageWidth = "auto",
}: any) => {
  return (
    <Row className={Styles.header2} style={{ width }}>
      <Col xs={12} sm={12} md={6} lg={6} className={Styles.imgCenter}>
        <img
          src={image}
          alt="about-mountain"
          className={Styles.bannerImage}
          style={{ width: imageWidth }}
        />
      </Col>
      <Col
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={padding ? Styles.minPadding : Styles.rightContent}>
        <Row className="my-3">
          {data &&
            data.length > 0 &&
            data.map((info: any, index: number) => (
              <div key={index}>
                <Row>
                  <div className={Styles.mapHeader}>
                    {number && (
                      <div className={Styles.numberContainer}>{index + 1}</div>
                    )}
                    <div className={Styles.margin}>{info.header}</div>
                  </div>
                </Row>
                <Row>
                  <div className={`${Styles.mapBody} my-4`}>{info.body}</div>
                </Row>
              </div>
            ))}
        </Row>
      </Col>
    </Row>
  );
};
export default ImageLeftContentRight;
