import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import MyProfileComponent from "../Components/MyProfileComponent";
const MyProfile = () => {
  return (
    <UserLayout title="My Profile">
      <MyProfileComponent />
    </UserLayout>
  );
};
export default MyProfile;
