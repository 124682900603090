import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import "./App.css";
import Router from "./Routes";
import { Toaster } from "react-hot-toast";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-ZNJ6DRGQXJ");
    if (process.env.REACT_APP_ENVIRONMENT === "SAVALA") {
      ReactGA.send(window.location.pathname + window.location.search);
    }
  }, []);

  return (
    <>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          duration: 7000,
        }}
      />
      <Router />
    </>
  );
}

export default App;
