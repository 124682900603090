import React from "react";

const HeaderIconComponent = ({
  path,
  name,
  hover,
}: {
  path: string;
  name: string;
  hover: string;
}) => {
  return (
    <>
      {path == "/dashboard" || hover == "/dashboard" ? (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.499512 22.3593C0.499512 19.7969 0.499512 17.2346 0.499512 14.6722C0.510155 14.6515 0.52518 14.6315 0.530814 14.6096C0.813164 13.535 1.48304 13.0166 2.59178 13.0159C4.86435 13.0159 7.13692 13.0147 9.4095 13.0172C9.58855 13.0172 9.77135 13.0191 9.9454 13.056C10.8889 13.2551 11.4811 14.0197 11.4842 15.0442C11.4892 16.5984 11.4867 18.1525 11.4855 19.7061C11.4849 20.5339 11.493 21.3617 11.4736 22.1889C11.4598 22.7594 11.2106 23.2428 10.7474 23.5691C10.475 23.7613 10.1363 23.859 9.8277 23.9992H2.1404C2.09094 23.9817 2.04148 23.9617 1.99077 23.9479C1.32215 23.7669 0.857614 23.3587 0.612201 22.7087C0.569004 22.5941 0.536449 22.4757 0.499512 22.3593Z"
            fill="white"
          />
          <path
            d="M22.86 23.9998H15.1727C15.1596 23.991 15.1483 23.9785 15.1339 23.9747C14.0277 23.6829 13.5149 23.0235 13.5143 21.8851C13.5137 19.6127 13.5118 17.3403 13.5149 15.0678C13.5168 13.8186 14.3207 13.0171 15.5653 13.0164C17.861 13.0152 20.1567 13.0152 22.4525 13.0177C22.6234 13.0177 22.7987 13.0221 22.9646 13.0584C23.8936 13.26 24.4978 14.0252 24.499 15.0046C24.5022 17.2927 24.5003 19.5808 24.5003 21.8682C24.5003 23.0104 23.9982 23.6679 22.8951 23.9716C22.8819 23.9753 22.8713 23.9904 22.8594 23.9998H22.86Z"
            fill="white"
          />
          <path
            d="M0.499512 9.3283C0.499512 6.76594 0.499512 4.20359 0.499512 1.64123C0.508903 1.62934 0.523928 1.61869 0.527684 1.60554C0.829442 0.504706 1.4893 0 2.63122 0C4.89565 0 7.16009 0 9.42452 0C10.6691 0 11.4824 0.802771 11.4849 2.04575C11.4892 4.34135 11.4886 6.63695 11.4849 8.93255C11.483 10.1818 10.6785 10.9821 9.43266 10.9833C7.63651 10.9852 5.84099 10.9833 4.04485 10.9833C3.49017 10.9833 2.93548 10.994 2.38205 10.9783C1.48116 10.9533 0.758698 10.3546 0.548344 9.48171C0.535823 9.42911 0.515789 9.37839 0.499512 9.32704L0.499512 9.3283Z"
            fill="white"
          />
          <path
            d="M18.9914 10.9853C17.8507 10.9853 16.7107 10.9865 15.57 10.9853C14.3229 10.9834 13.5159 10.1838 13.514 8.93765C13.5103 6.64143 13.5103 4.3452 13.514 2.04898C13.5166 0.806625 14.3317 0.00197543 15.575 0.000723058C17.8639 9.68713e-05 20.1521 -0.000529315 22.4409 0.000723058C23.6818 0.000723058 24.4988 0.815391 24.4994 2.05211C24.5006 4.34833 24.5006 6.64456 24.4994 8.94079C24.4988 10.1675 23.6862 10.9815 22.4597 10.984C21.304 10.9865 20.1477 10.984 18.992 10.984L18.9914 10.9853Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.499512 22.3593C0.499512 19.7969 0.499512 17.2346 0.499512 14.6722C0.510155 14.6515 0.52518 14.6315 0.530814 14.6096C0.813164 13.535 1.48304 13.0166 2.59178 13.0159C4.86435 13.0159 7.13692 13.0147 9.4095 13.0172C9.58855 13.0172 9.77135 13.0191 9.9454 13.056C10.8889 13.2551 11.4811 14.0197 11.4842 15.0442C11.4892 16.5984 11.4867 18.1525 11.4855 19.7061C11.4849 20.5339 11.493 21.3617 11.4736 22.1889C11.4598 22.7594 11.2106 23.2428 10.7474 23.5691C10.475 23.7613 10.1363 23.859 9.8277 23.9992H2.1404C2.09094 23.9817 2.04148 23.9617 1.99077 23.9479C1.32215 23.7669 0.857614 23.3587 0.612201 22.7087C0.569004 22.5941 0.536449 22.4757 0.499512 22.3593Z"
            fill="white"
            fillOpacity="0.2"
          />
          <path
            d="M22.86 23.9998H15.1727C15.1596 23.991 15.1483 23.9785 15.1339 23.9747C14.0277 23.6829 13.5149 23.0235 13.5143 21.8851C13.5137 19.6127 13.5118 17.3403 13.5149 15.0678C13.5168 13.8186 14.3207 13.0171 15.5653 13.0164C17.861 13.0152 20.1567 13.0152 22.4525 13.0177C22.6234 13.0177 22.7987 13.0221 22.9646 13.0584C23.8936 13.26 24.4978 14.0252 24.499 15.0046C24.5022 17.2927 24.5003 19.5808 24.5003 21.8682C24.5003 23.0104 23.9982 23.6679 22.8951 23.9716C22.8819 23.9753 22.8713 23.9904 22.8594 23.9998H22.86Z"
            fill="white"
            fillOpacity="0.2"
          />
          <path
            d="M0.499512 9.3283C0.499512 6.76594 0.499512 4.20359 0.499512 1.64123C0.508903 1.62934 0.523928 1.61869 0.527684 1.60554C0.829442 0.504706 1.4893 0 2.63122 0C4.89565 0 7.16009 0 9.42452 0C10.6691 0 11.4824 0.802771 11.4849 2.04575C11.4892 4.34135 11.4886 6.63695 11.4849 8.93255C11.483 10.1818 10.6785 10.9821 9.43266 10.9833C7.63651 10.9852 5.84099 10.9833 4.04485 10.9833C3.49017 10.9833 2.93548 10.994 2.38205 10.9783C1.48116 10.9533 0.758698 10.3546 0.548344 9.48171C0.535823 9.42911 0.515789 9.37839 0.499512 9.32704L0.499512 9.3283Z"
            fill="white"
            fillOpacity="0.2"
          />
          <path
            d="M18.9914 10.9853C17.8507 10.9853 16.7107 10.9865 15.57 10.9853C14.3229 10.9834 13.5159 10.1838 13.514 8.93765C13.5103 6.64143 13.5103 4.3452 13.514 2.04898C13.5166 0.806625 14.3317 0.00197543 15.575 0.000723058C17.8639 9.68713e-05 20.1521 -0.000529315 22.4409 0.000723058C23.6818 0.000723058 24.4988 0.815391 24.4994 2.05211C24.5006 4.34833 24.5006 6.64456 24.4994 8.94079C24.4988 10.1675 23.6862 10.9815 22.4597 10.984C21.304 10.9865 20.1477 10.984 18.992 10.984L18.9914 10.9853Z"
            fill="white"
            fillOpacity="0.2"
          />
        </svg>
      )}
      {path == "/dashboard" || hover == "/dashboard" ? (
        <span style={{ color: "white", marginLeft: "10px" }}>{name}</span>
      ) : (
        <span style={{ marginLeft: "10px" }}>{name}</span>
      )}
    </>
  );
};

export default HeaderIconComponent;
