import React, { useState } from "react";
import Styles from "./walletConnect.module.css";
import { loginAPI } from "../../service/api";

const WalletConnectComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleClick = async () => {
    const data = {
      login: email,
      password: password,
      user_id: null,
      access_token: null,
    };
    const res = await loginAPI(data);
  };
  return (
    <>
      <div className={Styles.PageContainer}>
        <input type="text" placeholder="name" />
        <input
          type="text"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="text"
          placeholder="Password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <input type="text" placeholder="Address Line 1" />
        <input type="text" placeholder="City" />
        <input type="text" placeholder="State" />
        <input type="text" placeholder="Country" />
        <input type="text" placeholder="Zip" />
        <input type="text" placeholder="Name on Bank Account" />
        <input type="text" placeholder="Bank Account Number" />
        <input type="text" placeholder="IFSC code" />
        <button onClick={handleClick}>Update</button>
      </div>
    </>
  );
};
export default WalletConnectComponent;
