import React from "react";
import Layout from "../Layouts/Layout";
import WalletConnectComponent from "../Components/WalletConnectComponent";
const WalletConnect = () => {
  return (
    <Layout>
      <WalletConnectComponent />
    </Layout>
  );
};

export default WalletConnect;
