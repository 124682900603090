import React from 'react';
import { useSelector } from 'react-redux';
import Styles from'./greeting.module.css'

const GreetingComponent =() =>{
    const { userDetails } = useSelector((state: any) => state.userReducer);
    return (
        <div className={Styles.greetingContainer}>
            <div className='d-flex'>
                <div className={Styles.welcomeText}>Welcome</div>
                <div className={Styles.nameText}>{userDetails.name}</div>
            </div>
        </div>
    )
}
export default GreetingComponent;