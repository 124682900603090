import Styles from "./slider.module.css";
// import laptopImg from "../../../Assets/Images/sliderLaptop.svg";
import vehicleImg from "../../../Assets/Images/sliderCar.svg";
import React from "react";
import {  useSelector } from "react-redux";
import URLS from "../../../utils/api-end-points";
interface props {
  imageCounter: number;
}
const SliderComponent = ({  imageCounter }: props) => {
  const { banner_data } = useSelector((state: any) => state.dashboardReducer);


  const getImage = () => {
    if(!banner_data || banner_data.length === 0){
      return vehicleImg ;
    }
    const url =  URLS.REACT_APP_BASE_IMG_URL + banner_data[imageCounter]?.image_url;
    return url;
  };

  return (
    <div className={Styles.sliderContainer + " row "}>
      <div className="col-12 px-5">
        <div>
          <img className={Styles.imgContainer} src={getImage()} alt="" />
        </div>
      </div>
    </div>
  );
};
export default SliderComponent;
