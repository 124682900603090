// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAPI, userDetailAPI, userGetKYCAPI } from "../../service/api";
import loginSubmit from "../../utils/login-submit";
import { setUserDetails, setUserName } from "../../redux/actions";
import Layout from "../../Layouts/Layout";
import InputField from "../Common/InputField";
import Styles from "./login.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";
import { getKYCStatus } from "../../utils/common";
import { setKycStatus } from "../../redux/actions/user-action";

interface UserInterface {
  email: string;
  password: string;
}

const LoginComponent = () => {
  // for page navigation purpose
  const navigate = useNavigate();

  // for storing the data in the redux
  const dispatch = useDispatch();

  const [user, setUser] = useState<UserInterface>({
    email: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);

  const [apiErrorMsg, setApiErrorMsg] = useState<String>("");

  // for handling the input box changes
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // validating the form errors
  const validateForm = (values: UserInterface) => {
    const errors = {};

    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/;
    if (!values.email.trim()) {
      errors.email = "Email is required.";
    } else if (!regexEmail.test(values.email.trim())) {
      errors.email = "Invalid email format.";
    }

    const lengthRegex = /.{8,}/;

    if (!values.password.trim()) {
      errors.password = "Password is required.";
    } else if (!lengthRegex.test(values.password.trim())) {
      errors.password = "Password must be at least 8 characters.";
    }
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormErrors(validateForm(user));

    setIsSubmit(true);
  };

  const loginHandler = async () => {
    const useData = {
      login: user.email.trim(),
      password: user.password.trim(),
      user_id: null,
      access_token: null,
    };

    try {
      // api calling
      const data: any = await loginAPI(useData);
      if (data.status) {
        const res = loginSubmit(data, dispatch);
        if (res !== "/verify-otp") {
          let resp = await userDetailAPI();
          resp.token_expire = data.token_expire;
          const response: any = await userGetKYCAPI();
          dispatch(setKycStatus(getKYCStatus(response.isUserKYCDone)));
          dispatch(setUserDetails(resp));
          setUser({
            email: "",
            password: "",
          });
        }
        navigate(res);
      } else {
        setApiErrorMsg(data.message);
        setUser({
          email: "",
          password: "",
        });
        return;
      }
    } catch (error) {
      console.error("An error occurred during login:", error);
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      loginHandler();
    }
  }, [formErrors]);

  return (
    <div className={Styles.MainDiv}>
      <h2 className={Styles.Heading}>Log in to Savala</h2>
      <form className={Styles.Form} onSubmit={handleSubmit}>
        <div>
          <InputField
            label="Email"
            value={user.email}
            type="email"
            placeholder="Email"
            onChange={changeHandler}
            height="50px"
            name="email"
            maxLength={50}
          />
        </div>
        {formErrors.email && (
          <p className="text-danger">
            <small>{formErrors.email}</small>
          </p>
        )}

        <div id={Styles.InputFieldPassword}>
          <InputField
            label="Password"
            value={user.password}
            type="password"
            placeholder="Password"
            showPassword={showPassword}
            onChange={changeHandler}
            handleTogglePassword={handleClickShowPassword}
            height="50px"
            name="password"
            maxLength={30}
          />
        </div>
        {formErrors.password && (
          <p className="text-danger">
            <small>{formErrors.password}</small>
          </p>
        )}

        <div className={Styles.FormForgotPassword}>
          <Link to="/forgot-password" className={Styles.FormForgotPassword}>
            Forgot your Password?
          </Link>
        </div>

        <div>
          <button
            type="submit"
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}>
            <span className={Styles.FormButtonSpan}>Log in</span>
          </button>
        </div>
        {apiErrorMsg && (
          <p className="text-danger">
            <small>{apiErrorMsg}</small>
          </p>
        )}

        <div className={Styles.FormJoinNow}>
          New to Savala?{" "}
          <Link to="/signup" className={Styles.FormJoinNowLink}>
            Join now
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LoginComponent;
