import React, { useEffect, useState } from "react";
import HomeBanner from "./HomeBanner";
import HomeMultiImage from "./HomeMultiImage";
import HomeOpportunity from "./HomeOpportunity";
import HomeHowTo from "./HomeHowTo";
import HomeBlockchain from "./HomeBlockchain";
import CommonFuture from "../Common/CommonFuture";
import FaqComponent from "../FAQComponent";
import { getFAQData } from "../../service/api";
const HomeComponent = () => {
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const res: any = await getFAQData();
    if (res.status) {
      setData(res.faqs);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <HomeBanner />
      <HomeMultiImage />
      <HomeOpportunity />
      <HomeBlockchain />
      <HomeHowTo />
      <CommonFuture />
      <FaqComponent data={data} short={true} />
    </>
  );
};
export default HomeComponent;
