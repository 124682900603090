import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./HomeOpportunity.module.css";
import ImageLeftContentRight from "../../Common/ImageLeftContentRight";
import WhyGraphite from "../../../Assets/Images/WhyGraphiteRock-4.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
const HomeOpportunity = () => {
  const rightContent = [
    {
      header: "Sustainable battery technology",
      body: "High grade graphite is critical for lithium-ion batteries that capture and store energy from wind, solar and other renewable energy sources.",
    },
    {
      header: "Enable graphite mining",
      body: "Purchasing through Savala allows graphite to be mined 10x faster, accelerating battery production and fuelling sustainable energy development.",
    },
    {
      header: "Unique opportunity",
      body: "Sophisticated blockchain tokenisation reduces time and cost for buyers, making it possible to purchase graphite as a tangible commodity while it’s still in the ground.",
    },
  ];
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );

  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1}>
          <div className={Styles.header1}>
            <div>The opportunity in </div>
            <div className={Styles.diffColor}>graphite</div>
          </div>
          <Row className={Styles.header2}>
            The premium graphite market is poised for strong growth as the world
            transitions to renewable energy technologies.
            <br></br>
            <div className="mt-3">
              <Link to="/why-graphite" className={Styles.links}>
                Learn More
              </Link>
            </div>
          </Row>
        </Col>
      </Row>
      <ImageLeftContentRight
        data={rightContent}
        image={WhyGraphite}
        width={screenSize > 768 ? "70%" : "90%"}
      />
    </div>
  );
};

export default HomeOpportunity;
