import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./CommonFuture.module.css";
import AboutFutute from "../../../Assets/Images/about-future.png";
import ButtonStyle from "../Button.module.css";
import ContactUsModal from "../../HomeComponent/ContactusModal";
import { useNavigate } from "react-router-dom";
const CommonFuture = () => {
  const [showModal, setShowModal] = useState(false);
  const navigation = useNavigate();
  const handleNavigation = () => {
    navigation("/pre-register");
  };
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1 + " p-0"} sm={6} md={6}>
          <Row className={Styles.header1 + " m-0"}>
            Don’t just invest in your own future. Invest in the world’s future.
          </Row>
          <div className={Styles.header2 + " col-12"}>
            Savala enable the global energy transformation like never before, in
            one of the most critical elements of climate change.
          </div>
          <Row
            style={{ width: "30%" }}
            className={Styles.minPadding + " m-0 mb-3"}>
            <button
              className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground}`}
              // onClick={() => setShowModal(!showModal)}
              onClick={() => handleNavigation()}>
              Get Started
            </button>
          </Row>
        </Col>
        <Col className="p-0" sm={6} md={6}>
          <img
            className={Styles.bannerImage + " px-2"}
            src={AboutFutute}
            alt="banner_image"
          />
        </Col>
      </Row>
      {showModal && (
        <ContactUsModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default CommonFuture;
