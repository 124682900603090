import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import metamaskService from "../metamask";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsMetamaskInstalled,
  setIsWalletConnected,
  setUserLoginStatus,
  setUserWalletAddress,
} from "../../redux/actions";
import { clearLocalStorage, getAccessToken, getTokenExpire } from "../common";
import { Spinner } from "react-bootstrap";
import { setLoader } from "../../redux/actions/layout-action";
const PrivateRoute = () => {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const { isMetamaskInstalled, userDetails } = useSelector(
    (state: any) => state.userReducer
  );
  const { loader } = useSelector((state: any) => state.layoutReducer);
  const token_expire = new Date(
    userDetails.token_expire ? userDetails.token_expire : getTokenExpire()
  );
  useEffect(() => {
    const fetchData = () => {
      try {
        const accessToken = getAccessToken();
        if (accessToken) {
          dispatch(setUserLoginStatus(true));
          // if (token_expire.getTime() > currentDate.getTime()) {
          // } else {
          //   clearLocalStorage();
          //   dispatch(setUserLoginStatus(false));
          // }
        } else {
          dispatch(setUserLoginStatus(false));
        }
        dispatch(setLoader(false));
      } catch (error) {
        console.log("privateRoute : ", error);
      }
    };
    const fetchMetamaskInstallation = async () => {
      try {
        const result = await metamaskService.checkMetamaskInstallation();
        if (result) {
          dispatch(setIsMetamaskInstalled(result));
          //   fetchUserAddress();
        }
      } catch (error) {
        console.log("Metamask Installation :", error);
      }
    };

    const fetchUserAddress = async () => {
      const result = await metamaskService.getUserAddress();
      if (result && !result.error) {
        dispatch(setUserWalletAddress(result.account));
        dispatch(setIsWalletConnected(true));
      } else {
        dispatch(setUserWalletAddress(null));
        dispatch(setIsWalletConnected(false));
      }
      dispatch(setLoader(false));
    };
    fetchData();

    !isMetamaskInstalled && fetchMetamaskInstallation();
    // isMetamaskInstalled && fetchUserAddress();
  }, []);

  return (
    <>
      {loader && (
        <div className="Loader">
          <Spinner animation="border" />
        </div>
      )}
      <div className={loader ? "bg-grey unclickable" : ""}>
        <Outlet />
      </div>
    </>
  );
};
export default PrivateRoute;
