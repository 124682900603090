import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./GraphiteHeader.module.css";
import GraphiteHeaderImg from "../../../Assets/Images/why-graphite-banner-1.png";
const GraphiteHeader = () => {
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1} md={6} sm={6}>
          <Row className={Styles.header1}>
            The unique opportunity presented by{" "}
            <span className={Styles.subHeader1}>graphite.</span>
          </Row>
          <Row className={Styles.header2}>
            Graphite is a critical element for the world’s future energy needs.
            It is the best suited anode in lithium-ion batteries that are needed
            for sustainable energy.
            <br></br>
            <br></br>Savala buyers enable leading graphite mines to rapidly
            scale production to meet global graphite demand.
          </Row>
        </Col>
        {/* <Col className={Styles.col1} md={2}></Col> */}
        <Col className={Styles.col2} md={6} sm={6}>
          <img
            className={Styles.bannerImage}
            src={GraphiteHeaderImg}
            alt="banner_image"
          />
        </Col>
      </Row>
    </div>
  );
};

export default GraphiteHeader;
