import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./AboutInvestmentInfomation.module.css";
import AboutMountain from "../../../Assets/Images/about-mountain.svg";

const AboutInvestmentInfomation = () => {
  const buyersInfo = [
    `The Launch of the Savala Commodities Platform gives all
    buyers the opportunity to purchase graphite, in an early stage
    offering usually only reserved for larger scale buyers.`,

    `Buyers will be given the opportunity to purchase a minimum
    of one tonne of graphite with the first stage being the
    release of 2,000 tonnes offered at USD$850 per tonne. Buyers
    will acquire these via the Savala Commodities Platform. On Completion
    of the offering, buyers will be issued a Savala Graphite Token, each of
    which will represent the ownership of 1 tonne of graphite.`,

    `On completion of the sale of the first 2,000 tonnes there
    will be a stage 2 release of up to 9,000 tonnes at
    USD$1,000/ tonne.`,

    `On completion or closing of the sale of Stages 1 and 2, the
    Savala Commodities Platform will provide a secondary
    marketplace whereby Token holders will be able sell their
    tokens. This secondary market will provide the opportunity
    for parties to buy and sell their ownership of the graphite.`,

    `The mine will retain custody for the Buyer and will mine,
    sell and settle the graphite on behalf of the Buyers.`,

    `Once all of the Graphite in stages 1 and 2 is mined, sold
    and settled, whomever holds the token at this point in time
    will have their token redeemed and paid out in cash. Buyers
    will be entitled to the first $USD$1,200 of any sale and 40%
    of any amount above $1,200 for each tonne of graphite they
    own.`,
  ];

  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1}>
          <div className={Styles.header1}>
            <div>Buyer Information</div>
          </div>
          <Row className={Styles.header2}>
            <Col>
              <ul>
                {buyersInfo.map((text, index) => (
                  <li className={Styles.textInfo} key={index}>
                    {text}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AboutInvestmentInfomation;
