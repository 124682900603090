import React from "react";

const PurchaseIconComponent = ({
  path,
  name,
  hover,
}: {
  path: string;
  name: string;
  hover: string;
}) => {
  return (
    <>
      {path == "/purchase" || hover == "/purchase" ? (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="Layer 1">
            <g id="Group">
              <path
                id="Vector"
                d="M2.87617 2.48363C2.516 2.48363 2.16413 2.48501 1.81156 2.48363C1.06012 2.47948 0.50224 1.95107 0.500857 1.24514C0.499474 0.537141 1.05666 0.00458527 1.80603 0.00182233C2.50425 -0.0009406 3.20315 -0.000249867 3.90136 0.00182233C4.62723 0.00458527 5.11529 0.448036 5.21138 1.16571C5.41739 2.70052 5.63169 4.23395 5.84599 5.79432H6.1868C11.9737 5.79432 17.7599 5.79432 23.5467 5.79432C24.0569 5.79432 24.3722 5.99118 24.4752 6.40354C24.5111 6.54722 24.5083 6.71437 24.4731 6.85874C23.7099 9.94079 22.9432 13.0222 22.1683 16.1014C21.9671 16.8999 21.3304 17.3786 20.5009 17.3793C15.7579 17.3807 11.0148 17.3814 6.27114 17.3793C5.33789 17.3793 4.677 16.8005 4.55188 15.8576C4.38251 14.577 4.23112 13.2943 4.07142 12.0123C3.83707 10.1321 3.60134 8.25264 3.36699 6.37246C3.22112 5.20167 3.07664 4.03087 2.93009 2.86008C2.91557 2.74127 2.89552 2.62316 2.87478 2.48363H2.87617Z"
                fill="white"
              />
              <path
                id="Vector_2"
                d="M5.46962 21.5119C5.47376 20.1484 6.59574 19.0349 7.96175 19.0391C9.32707 19.0432 10.4401 20.1629 10.4366 21.5292C10.4325 22.8934 9.31117 24.0068 7.94447 24.0027C6.58054 23.9985 5.46547 22.8768 5.46962 21.5119Z"
                fill="white"
              />
              <path
                id="Vector_3"
                d="M17.8875 19.0352C19.2542 19.0338 20.3727 20.1486 20.3741 21.5135C20.3755 22.8791 19.2597 23.9974 17.8944 23.9995C16.5298 24.0015 15.4085 22.8839 15.4071 21.5204C15.4057 20.1555 16.5229 19.0365 17.8875 19.0352Z"
                fill="white"
              />
            </g>
          </g>
        </svg>
      ) : (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g id="Layer 1">
            <g id="Group">
              <path
                id="Vector"
                d="M2.87617 2.48363C2.516 2.48363 2.16413 2.48501 1.81156 2.48363C1.06012 2.47948 0.50224 1.95107 0.500857 1.24514C0.499474 0.537141 1.05666 0.00458527 1.80603 0.00182233C2.50425 -0.0009406 3.20315 -0.000249867 3.90136 0.00182233C4.62723 0.00458527 5.11529 0.448036 5.21138 1.16571C5.41739 2.70052 5.63169 4.23395 5.84599 5.79432H6.1868C11.9737 5.79432 17.7599 5.79432 23.5467 5.79432C24.0569 5.79432 24.3722 5.99118 24.4752 6.40354C24.5111 6.54722 24.5083 6.71437 24.4731 6.85874C23.7099 9.94079 22.9432 13.0222 22.1683 16.1014C21.9671 16.8999 21.3304 17.3786 20.5009 17.3793C15.7579 17.3807 11.0148 17.3814 6.27114 17.3793C5.33789 17.3793 4.677 16.8005 4.55188 15.8576C4.38251 14.577 4.23112 13.2943 4.07142 12.0123C3.83707 10.1321 3.60134 8.25264 3.36699 6.37246C3.22112 5.20167 3.07664 4.03087 2.93009 2.86008C2.91557 2.74127 2.89552 2.62316 2.87478 2.48363H2.87617Z"
                fill="white"
                fillOpacity="0.2"
              />
              <path
                id="Vector_3"
                d="M17.8875 19.0352C19.2542 19.0338 20.3727 20.1486 20.3741 21.5135C20.3755 22.8791 19.2597 23.9974 17.8944 23.9995C16.5298 24.0015 15.4085 22.8839 15.4071 21.5204C15.4057 20.1555 16.5229 19.0365 17.8875 19.0352Z"
                fill="white"
                fillOpacity="0.2"
              />
              <path
                id="Vector_2"
                d="M5.46962 21.5119C5.47376 20.1484 6.59574 19.0349 7.96175 19.0391C9.32707 19.0432 10.4401 20.1629 10.4366 21.5292C10.4325 22.8934 9.31117 24.0068 7.94447 24.0027C6.58054 23.9985 5.46547 22.8768 5.46962 21.5119Z"
                fill="white"
                fillOpacity="0.2"
              />
            </g>
          </g>
        </svg>
      )}
      {path == "/purchase" || hover == "/purchase" ? (
        <span style={{ color: "white", marginLeft: "10px" }}>{name}</span>
      ) : (
        <span style={{ marginLeft: "10px" }}>{name}</span>
      )}
    </>
  );
};

export default PurchaseIconComponent;
