import React, { useEffect, useState } from "react";
import { contactUsAPI } from "../../service/api";
import InputField from "../Common/InputField";
import validator from "validator";
import Styles from "./GetInTouch.module.css";
import ButtonStyle from "./Button.module.css";

type latestValuesType = {
  name: string;
  email: string;
  message: string;
  validateField: string;
};

const GetInTouch = (props: { type?: string }) => {
  const [name, setName] = useState("");
  const [namaeError, setNameError] = useState<string>("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<string>("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState<string>("");

  const [apiErrorMsg, setApiErrorMsg] = useState("");
  const [activeSubmitBtn, setActiveSubmitBtn] = useState<boolean>(false);

  useEffect(() => {
    try {
      // can set default name and email if user is logged in
    } catch {}
  }, []);

  const toggleSubmitByCryptoBtn = (latestValues: latestValuesType) => {
    if (
      latestValues.name !== "" &&
      latestValues.email !== "" &&
      latestValues.message !== ""
    ) {
      setActiveSubmitBtn(true);
    } else {
      setActiveSubmitBtn(false);
    }
  };

  const validateContactForm = (latestValues: latestValuesType) => {
    setApiErrorMsg("");
    toggleSubmitByCryptoBtn(latestValues);
    switch (latestValues.validateField) {
      case "name":
        if (latestValues.name === "") {
          setNameError("Please, provide the name.");
        } // TODO rest validation
        return true;
      case "email":
        if (!latestValues.email.length) {
          setEmailError("Please, provide the email.");
        } else if (!validator.isEmail(latestValues.email)) {
          setEmailError("Please, enter a valid email!");
          setActiveSubmitBtn(false);
        }
        return true;
      case "message":
        if (!latestValues.message.length) {
          setMessageError("Message is mandatory.");
        } // TODO Rest Validation
        return true;
      default:
        return false;
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setNameError("");
    setName(val);
    validateContactForm({
      name: val,
      email,
      message,
      validateField: "name",
    });
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.trim();
    setEmailError("");
    setEmail(val);
    validateContactForm({
      name,
      email: val,
      message,
      validateField: "email",
    });
  };

  const handleMsgChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = e.target.value;
    setMessageError("");
    setMessage(val);
    validateContactForm({
      name,
      email,
      message: val,
      validateField: "message",
    });
  };

  const submitGetInTouch = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!activeSubmitBtn) {
      console.error("Form value(s) are not valid.");
      return;
    }
    const data = {
      user_id: null,
      access_token: "",
      name,
      email,
      message,
      type: props.type || "get_in_touch",
    };

    try {
      const response: any = await contactUsAPI(data);
      if (response.status) {
        setApiErrorMsg("Information is saved!");
        setName("");
        setEmail("");
        setMessage("");
      } else {
        // handle here failure case
      }
    } catch (error) {
      console.error("An error occurred during getin touch:", error);
    }
  };
  return (
    <div
      className={Styles.GetInTouchContainer}
      style={(props.type === "contact_us" && { width: "100%" }) || {}}>
      <p className={Styles.GetInTouchHeading}>Get in touch</p>
      <p className={Styles.GetInTouchDescription}>
        {(props.type === "contact_us" && (
          <>
            Content on this page is dependent on whether we’ll be providing
            direct contact details for Sasha, Gareth, a general email / phone
            number, or simply the form submission below.
          </>
        )) || (
          <>
            Background documentation is available now. Enquire now for more
            information about how Savala is changing sustainable investment.
          </>
        )}
      </p>
      <form onSubmit={submitGetInTouch}>
        <div className={Styles.GetInTouchFormContainer}>
          <div className={Styles.FormFields}>
            <div className={Styles.ContainerItemsLeft}>
              <InputField
                label=""
                value={name}
                type="text"
                placeholder="Name"
                onChange={handleNameChange}
                height="50px"
              />
              {/* <input
                type="text"
                placeholder="Name"
                value={name}
                className={Styles.FormInput}
                onChange={handleNameChange}
                required={true}
              />{' '} */}
              <InputField
                label=""
                value={email}
                type="email"
                placeholder="Email"
                onChange={handleEmailChange}
                height="50px"
              />
            </div>
            <div className={Styles.ContainerItemsRight}>
              <textarea
                placeholder="Message"
                value={message}
                className={Styles.FormTextArea}
                onChange={handleMsgChange}
                required={true}
              />{" "}
            </div>
          </div>
        </div>
        <br />
        <div
          className={Styles.ContainerItemsLeft}
          style={{ flex: "18% 0", width: "fit-content" }}>
          <button
            className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeWhiteBorder}`}
            style={
              (props.type === "contact_us" && { background: "#1c2a4d" }) || {}
            }
            type="submit"
            disabled={!activeSubmitBtn}>
            Submit
          </button>
        </div>
        {namaeError.length > 0 && (
          <p className={Styles.ErrorMessage}>{namaeError}</p>
        )}
        {emailError.length > 0 && (
          <p className={Styles.ErrorMessage}>{emailError}</p>
        )}
        {messageError.length > 0 && (
          <p className={Styles.ErrorMessage}>{messageError}</p>
        )}
        {apiErrorMsg.length > 0 && (
          <p className={Styles.ErrorMessage}>{apiErrorMsg}</p>
        )}
      </form>
    </div>
  );
};
export default GetInTouch;
