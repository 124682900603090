import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getBlog} from "../../service/api";
import { Card } from "react-bootstrap";
import { FaRegCalendarAlt } from "react-icons/fa";
import Styles from "./news.module.css"

const FullBlogPage = () => {
  const { blogId } = useParams();
  const [fullBlog, setFullBlog] = useState<{ name: string, description: string,date:string }>({ name: "", description: "",date:"" });
  const [dataLoaded, setDataLoaded] = useState(false);
  const getFullBlogs = async () => {
    try {
      const data = {blog_id:blogId}
      const res: any = await getBlog(data);
      setDataLoaded(true)
      if (res.status) {
        console.log("full blog ===",res)
        setFullBlog(res.data[0])
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(()=>{
    getFullBlogs();

  },[])

  return (
    <div className={Styles.fullBlog}>
      {Object.keys(fullBlog).length > 0 && (
    <Card >
    <Card.Body>
      <h4 className={Styles.title}>{fullBlog.name}</h4>
      <div className={Styles.dateContainer}>
        <FaRegCalendarAlt className={`${Styles.calendarIcon} ${Styles.smallIcon}`} />
          <p className={` ${Styles.smallText}`}>{fullBlog.date}</p>
        </div>
      <div className={Styles.textStyle} dangerouslySetInnerHTML={{ __html: fullBlog.description }} />
    </Card.Body>
  </Card>
)}
    </div>
  );
};

export default FullBlogPage;
