import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import Styles from "./SupportPublicDoc.module.css";
import GreyDot from "../../../Assets/Images/grey-dot.svg";
import { getSupportDocsData } from "../../../service/api";
import URLS from "../../../utils/api-end-points";
import { FaFilePdf } from "react-icons/fa6";

const SupportPublicDoc = () => {
  const [docs, setDocs] = useState<any>([]);

  const showPDF = (url: string) => {
    window.open(URLS.REACT_APP_BASE_IMG_URL + url);
  };
  const fetchData = async () => {
    const res: any = await getSupportDocsData();
    if (res.status) {
      setDocs(res.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Row>
        <div className={Styles.card}>
          <div className={Styles.header}>
            <img src={GreyDot} alt="grey dot" className="me-2" />
            Public Documentation
          </div>
          {docs &&
            docs.map((doc: any) => (
              <div
                className={Styles.body}
                onClick={() => showPDF(doc.show_url)}>
                {" "}
                <FaFilePdf className="me-2" />
                {doc.file_name}
              </div>
            ))}
        </div>
      </Row>
    </>
  );
};
export default SupportPublicDoc;
