/* global BigInt */
const { Web3 } = require("web3");
const { ethers } = require("ethers");
const CryptoJS = require("crypto-js");
const { SmartContractABI } = require("../service/abi/eth-abi");
const { default: notify } = require("../utils/notify");
const web3 = new Web3(window.ethereum);
// const eth_provider = new ethers.BrowserProvider(window.ethereum);
const _isMetamaskInstalled = () => {
  return new Promise((resolve) => {
    if (Web3.givenProvider !== null && typeof window.ethereum !== "undefined") {
      resolve(true);
    }
    resolve(false);
  });
};
const _connectToMetamask = async () => {
  const isMetamaskInstalled = await _isMetamaskInstalled();
  if (!isMetamaskInstalled) {
    return { error: true, message: "Metamask is not installed!" };
  }
  if (window.ethereum) {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    return { error: false, accountAddress: accounts[0] };
  }
};
const _disconnectToMetamask = async () => {
  const isMetamaskInstalled = await _isMetamaskInstalled();
  if (!isMetamaskInstalled) {
    return { error: true, message: "Metamask is not installed!" };
  }
  if (window.ethereum) {
    // window.ethereum.removeAllListeners(); // Remove all listeners added by MetaMask
    // window.web3.eth.currentProvider.disconnect();
    // window.ethereum.disconnect();
    return { error: false, message: "Wallet Disconnected" };
  }
};
const _getActiveAccount = async () => {
  const accounts = await web3.eth.requestAccounts();

  if (accounts.length > 0) {
    return { error: false, account: accounts[0] };
  }

  return { error: true, message: "No active account is found!" };
};
// const _tranferToken = async (data) => {
//   await _connectToMetamask();
//   const accountAddress = await _getActiveAccount();
//   if (accountAddress.error) {
//     return false;
//   }
//   const stFactory = new web3.eth.Contract(
//     SmartContractABI,
//     "0x534bD102153EF199abAe8296a2FaE4599fC44Cdc"
//   );
//   console.log("accountAddress", accountAddress);
//   const tx = await stFactory.methods
//     .transfer("0x24bdcfF4C7943512d1f351116ECE0304903203C4", data * 10 ** 6)
//     .send({ from: accountAddress.account });
//   return tx;
// };
const checkMetaMask = async () => {
  if (window.ethereum) {
    const provider = new ethers.BrowserProvider(window.ethereum);
    try {
      const accounts = await provider.send("eth_requestAccounts", []);
      return { connected: true };
    } catch (error) {
      notify.error("Error connecting to MetaMask:");
      return { connected: false };
    }
  } else {
    notify.error("MetaMask not detected.");
    return { connected: false };
  }
};
const _getCurrentNetwork = async () => {
  try {
    if (typeof window.ethereum !== undefined) {
      // now we will get the current network
      const currentNetwork = await window.ethereum.request({
        method: "net_version",
      });
      if (currentNetwork != process.env.REACT_APP_CHAIN_ID) {
        notify.error("Ethereum  not exist");
        await _changeNetwork();
      }
    } else {
      notify.error("Metamask not installed!");
    }
  } catch (err) {
    console.log("getCurrentNetwork", err);
  }
};
const _changeNetwork = async () => {
  const chainId = parseFloat(process.env.REACT_APP_CHAIN_ID);
  const formattedChainID = `0x${chainId.toString(16)}`;
  const currentNetwork = await window.ethereum.request({
    method: "net_version",
  });
  if (currentNetwork != chainId) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: formattedChainID }],
      });
    } catch (err) {
      // This error code indicates that the chain has not been added to MetaMask
      if (err.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainName: process.env.REACT_APP_NETWORK_NAME,
                chainId: formattedChainID,
                nativeCurrency: {
                  name: process.env.REACT_APP_CURRENCY_SYMBOL,
                  decimals: 18,
                  symbol: process.env.REACT_APP_CURRENCY_SYMBOL,
                },
                rpcUrls: [process.env.REACT_APP_RPC_URL],
              },
            ],
          });
        } catch (error) {
          console.log("ERROR", error);
          handleTransactionError(error);
        }
      }
    }
  }
};

const _tranferToken = async (data, contractAddress, adminWallet) => {
  try {
    const check = await checkMetaMask();
    if (!check.connected) return;
    await _getCurrentNetwork();
    // if (!networkChange.error) return;

    const eth_provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await eth_provider.getSigner();

    const MyContract = new ethers.Contract(
      contractAddress,
      SmartContractABI,
      signer
    );
    const decimals = Number(await MyContract.decimals());
    const amount = BigInt(data * 10 ** decimals);
    const tx = await MyContract.transfer(adminWallet, amount);
    return tx;
  } catch (err) {
    return handleTransactionError(err);
  }
};
const handleTransactionError = (error) => {
  if (error.code === "ACTION_REJECTED" || error.code == 4001) {
    notify.error("User rejected the transaction.");
  } else if (error.code === "CALL_EXCEPTION") {
    notify.error("User does not have enough tokens.");
    return error.code;
  }
};
const _generateWalletAddress = (username) => {
  const encodedData = username + Math.random().toString().substring(2);
  const hashedData = CryptoJS.SHA256(encodedData).toString(CryptoJS.enc.Hex);
  const addressBytes = "0x" + hashedData.slice(0, 40);
  return addressBytes;
};

module.exports = {
  _isMetamaskInstalled,
  _connectToMetamask,
  _disconnectToMetamask,
  _getActiveAccount,
  _tranferToken,
  _generateWalletAddress,
};
