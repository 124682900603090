import React from "react";
import Layout from "../Layouts/Layout";
import TermsAndConditionsComponent from "../Components/TermsAndConditionsComponent";
const TermsAndCondtions = () => {
  return (
    <Layout title="Terms and Condtions">
      <TermsAndConditionsComponent text="Terms and Conditions." />
    </Layout>
  );
};

export default TermsAndCondtions;
