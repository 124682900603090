const getAccessToken = () => {
  const accessToken = localStorage.getItem("access_token");
  return accessToken;
};
const getUserId = () => {
  const userId = localStorage.getItem("user_id");
  return userId;
};
const getUserEmail = () => {
  const userEmail = localStorage.getItem("user_email");
  return userEmail;
};
const getTokenExpire = () => {
  const userTokenExpire = localStorage.getItem("token_expire");
  return userTokenExpire;
};

const clearLocalStorage = () => {
  localStorage.clear();
};

const getKYCStatus = (val: any) => {
  switch (val) {
    case "approved":
      return "Verified";
    case "rejected":
      return "Verified";
    case "inreview":
      return "Pending";
    case "blocked":
      return "Pending";
    default:
      return "Pending";
  }
};

function maskEmail(email: string) {
  // Split the email into local part and domain part
  const [localPart, domainPart] = email.split("@");

  // Mask the local part with asterisks, keeping the first and last characters
  const maskedLocalPart =
    localPart.length > 2
      ? localPart[0] + "*".repeat(localPart.length - 2) + localPart.slice(-1)
      : localPart;

  // Mask the domain part with asterisks, keeping the first and last characters
  const maskedDomainPart =
    domainPart.length > 2
      ? domainPart[0] + "*".repeat(domainPart.length - 2) + domainPart.slice(-1)
      : domainPart;

  // Combine the masked local and domain parts to form the masked email
  const maskedEmail = maskedLocalPart + "@" + maskedDomainPart;

  return maskedEmail;
}

const getMonthByNumberMap = () => {
  const data: { [key: string]: string } = {
    "1": "Jan",
    "2": "Feb",
    "3": "Mar",
    "4": "Apr",
    "5": "May",
    "6": " Jun",
    "7": "Jul",
    "8": "Aug",
    "9": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  };
  return data;
};

const copyToClipboard = (src: string): void => {
  if (navigator) navigator.clipboard.writeText(src);
};

const copyText = (val: string) => {
  copyToClipboard(val);
};

export {
  getAccessToken,
  clearLocalStorage,
  getUserId,
  getUserEmail,
  getKYCStatus,
  maskEmail,
  getMonthByNumberMap,
  copyText,
  getTokenExpire,
};
