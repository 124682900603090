import React from "react";
import Styles from "./buyMore.module.css";
import commonStyles from "../../Common/Button.module.css";
import tokenBackground from "../../../Assets/Images/token-background.png";
import { useSelector } from "react-redux";

const BuyMoreComponent = () => {
  const { total_tokens_purchased } = useSelector(
    (state: any) => state.dashboardReducer
  );
  return (
    <div
      className={Styles.tokenContainer}
      style={{ backgroundImage: `url(${tokenBackground})` }}>
      <div className={Styles.investmentHeadingContainer}>
        <div className={Styles.orderEllipse}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none">
            <circle opacity="0.6" cx="8.5" cy="9" r="8.5" fill="white" />
          </svg>
        </div>
        <div className={Styles.investmentHeading}>
          Total number of commodities purchased
        </div>
      </div>
      <div className={Styles.tokenBody + " gap-1"}>
        <div className={Styles.countText + " col-7 text-break text-left"}>
          {total_tokens_purchased || 0}
        </div>
        <div className={Styles.buyBtn + " col-4 "}>
          <button
            className={`${Styles.buymoreBtn} ${commonStyles.PlainBtn} ${commonStyles.ThemeOrangeBackground}`}>
            <span className={Styles.btnText}>Buy More</span>
          </button>
        </div>
      </div>
    </div>
  );
};
export default BuyMoreComponent;
