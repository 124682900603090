// @ts-nocheck

import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { contactUsAPI, getAllCountry } from "../../../service/api";
import { useNavigate } from "react-router-dom";
import notify from "../../../utils/notify";
import Styles from "./contactUs.module.css";
import InputField from "../../Common/InputField";
import ButtonStyles from "../../Common/Button.module.css";

interface Props {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}
const ContactUsModal = ({ showModal, setShowModal }: Props) => {
  const navigate = useNavigate();

  const [user, setUserDetails] = useState({
    name: "",
    email: "",
    comment: "",
    country: "",
  });

  // state variable for form erros
  const [formErrors, setFormErrors] = useState({});

  const [isSubmit, setIsSubmit] = useState(false);

  const [apiErrorMsg, setApiErrorMsg] = useState("");

  const [countryData, setCountryData] = useState();

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setApiErrorMsg("");

    setUserDetails({
      ...user,
      [name]: value,
    });
  };

  // for valiadting the form inputs
  const validateForm = (values: {
    name: string;
    email: string;
    country: string;
    comment: string;
  }) => {
    const errors = {};
    const regexName = /^[^*|\":<>[\]{}`\\()'!#%^_+,./~?;@&$]+$/;
    if (!values.name.trim()) {
      errors.name = "Name is required.";
    } else if (!regexName.test(values.name.trim())) {
      errors.name = "Invalid characters in the name.";
    }
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!values.email.trim()) {
      errors.email = "Email is required.";
    } else if (!regexEmail.test(values.email.trim())) {
      errors.email = "Invalid email format.";
    }
    if (!values.comment.trim()) {
      errors.comment = "Comment is required.";
    }
    if (!values.country.trim()) {
      errors.country = "Country is required.";
    }
    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormErrors(validateForm(user));
    setIsSubmit(true);
  };

  const signupHandler = async () => {
    const data = {
      email: user.email.trim(),
      name: user.name.trim(),
      country_id: user.country,
      message: user.comment,
      type: "contact_us",
    };

    try {
      const response = await contactUsAPI(data);

      if (response.status) {
        setUserDetails({
          name: "",
          email: "",
          comment: "",
          country: "",
        });
        notify.success(response.message);
        setShowModal(false);
      } else {
        setUserDetails({
          name: "",
          email: "",
          comment: "",
          country: "",
        });

        setApiErrorMsg(response.message);
        return;
      }
    } catch (error) {
      console.error("An error occurred:", error);
      return;
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      signupHandler();
    }
  }, [formErrors]);

  const fetchCountry = async () => {
    const response: any = await getAllCountry();
    if (response.status) {
      setCountryData(response.countries_details);
    }
  };
  useEffect(() => {
    fetchCountry();
  }, []);

  return (
    <>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Body className="p-5">
          <p className={Styles.Paragraph}>Tell us about yourself</p>
          <form onSubmit={handleSubmit}>
            <InputField
              label="Name"
              value={user.name}
              type="text"
              placeholder="Name"
              onChange={changeHandler}
              height="50px"
              name="name"
              maxLength={40}
            />

            {formErrors.name && (
              <p className="text-danger">
                <small>{formErrors.name}</small>
              </p>
            )}
            <div className={Styles.InputField}>
              <InputField
                label="Email"
                value={user.email}
                type="email"
                placeholder="Email"
                onChange={changeHandler}
                height="50px"
                name="email"
                maxLength={40}
              />
            </div>
            {formErrors.email && (
              <p className="text-danger">
                <small>{formErrors.email}</small>
              </p>
            )}
            <div className={Styles.InputField}>
              <div className={Styles.label}>Select Country</div>

              <Form.Select
                aria-label="Default select example"
                onChange={changeHandler}
                name="country"
                value={user.country === "" ? "" : user.country}>
                <option>Select one country</option>
                {countryData &&
                  countryData.map((country) => (
                    <option value={country.country_id}>
                      {country.country_name}
                    </option>
                  ))}
              </Form.Select>
            </div>
            {formErrors.country && (
              <p className="text-danger">
                <small>{formErrors.country}</small>
              </p>
            )}
            <div className={Styles.InputField}>
              <InputField
                label="Add Comment"
                value={user.comment}
                type="textArea"
                placeholder="Comment"
                onChange={changeHandler}
                height="50px"
                name="comment"
                maxLength={100}
              />
            </div>
            {formErrors.comment && (
              <p className="text-danger">
                <small>{formErrors.comment}</small>
              </p>
            )}

            <button
              type="submit"
              style={{ marginTop: 50 }}
              className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}>
              <span className={Styles.RegisterButtonText}>Get in Touch</span>
            </button>
            {apiErrorMsg && (
              <p className="text-danger">
                <small>{apiErrorMsg}</small>
              </p>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ContactUsModal;
