import React, { useState,useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import { getBlogsList } from "../../service/api";
import { Link,useNavigate } from "react-router-dom";
import Styles from "./news.module.css"

// import HeaderStyle from "./Header.module.css";
import ButtonStyle from "../Common/Button.module.css"
import HeaderStyle from "../Common/Header.module.css";
import { FaRegCalendarAlt } from "react-icons/fa";

interface Blog {
  name: string;
  short_description: string;
  date:string;
  url:string;
}

interface Props {
  blogs: Blog[];
}

const News = ({ blogs }: Props) => {
  return (
    <div className={Styles.blogContainer}>
      {blogs.map((blog, index) => (
        <BlogCard key={index} name={blog.name} short_description={blog.short_description} date={blog.date} url={blog.url}/>
      ))}
    </div>
  );
};

const BlogCard = ({ name, short_description,date,url}: Blog) => {
  let navigate = useNavigate(); 
  const routeChange = () =>{ 
    let path = url; 
    navigate(path);
  }
  return (
    <Card className={Styles.blogCard}>
      <Card.Body>
        <h4 className={Styles.title}>{name}</h4>
        <div className={Styles.dateContainer}>
        <FaRegCalendarAlt className={`${Styles.calendarIcon} ${Styles.smallIcon}`} />
          <p className={`${Styles.smallText}`}>{date}</p>
        </div>
        <Card.Text className={Styles.textStyle}>{short_description}</Card.Text>
        <div className = {Styles.readButton}>
            <button
                  className={`${ButtonStyle.PlainBtn} ${Styles.ThemeOrange} ${HeaderStyle.MobileBtns}`}
                  onClick={routeChange}>
                  CONTINUE READING
                </button>
        </div>
        
      </Card.Body>
    </Card>
  );
};

const BlogReadingPage = () => {
  const [blogs,setBlogs] = useState([])
  const [dataLoaded, setDataLoaded] = useState(false);
  const getAllBlogs = async () => {
    try {
      const res: any = await getBlogsList();
      setDataLoaded(true)
      if (res.status) {
        setBlogs(res.cms_pages)
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(()=>{
    getAllBlogs();

  },[])
  
    return (
      <div>
        <h1>News</h1>
        {dataLoaded && <News blogs={blogs} />}
        
      </div>
    );
  };
  
  export default BlogReadingPage;

