import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./HomeMultiImage.module.css";
import AboutHeaderImg from "../../../Assets/Images/about-header.png";
import AboutBanner1 from "../../../Assets/Images/about-banner-1.png";
import HomeCar from "../../../Assets/Images/HomeCar.svg";
const HomeMultiImage = () => {
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col md={6}>
          <div className={Styles.banner1}>
            <img
              src={AboutBanner1}
              alt="banner-1"
              className={Styles.bannerImage1}
            />
          </div>
        </Col>
        <Col md={3}>
          <div className={Styles.banner2}>
            With Savala, buyers can tap into the rapid growth in the global
            battery market like never before. Buyers not only benefit from
            the graphite market’s growth, but enable it.
          </div>
        </Col>
        <Col md={3} className={Styles.bgPink}>
          <div className={Styles.banner3}>
            US$400 billion by 2030
            <div className={Styles.subBanner3}>
              McKinsey & Co analysis projects more than 30% annual growth in the
              lithium-ion battery chain from 2022 to 2030.
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div className={Styles.banner4}>
            Premium quality graphite is a core component of lithium-ion
            batteries used in electric vehicles and other energy technology
            critical in solving the world’s energy crisis.
          </div>
        </Col>
        <Col md={3} className={Styles.bgBlue}>
          <div className={Styles.banner3}>
            99.9% Grade Graphite
            <div className={Styles.subBanner3}>
              Independently verified as the world’s leading quality graphite.
            </div>
          </div>
        </Col>
        <Col md={6}>
          <div className={Styles.banner6}>
            <img src={HomeCar} className={Styles.carImg} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HomeMultiImage;
