/* eslint-disable react-hooks/exhaustive-deps */
import Styles from "./myOrder.module.css";
import rock from "../../Assets/Images/rock.svg";
import OrderComponent, { orderType } from "./OrderComponent";
import { useEffect, useState } from "react";
import { getOrderList } from "../../service/api";
import { useDispatch, useSelector } from "react-redux";
import { setOrderList } from "../../redux/actions";
import Pagination from "./Pagination";

const MyOrder = () => {
  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { orderList } = useSelector((state: any) => state.orderReducer);
  const getMyOrderList = async () => {
    try {
      const res: any = await getOrderList(pageNo);
      if (res.status) {
        dispatch(setOrderList(res.order_list));
        setDataLoaded(true);
        setTotalPages(res.total_pages);
      }
    } catch (error) {
      console.log("eerr", error);
    }
  };

  useEffect(() => {
    if (!dataLoaded) {
      getMyOrderList();
    }
  }, []);
  return (
    <>
      <div className={Styles.orderContainer} id="order-body">
        <div className={Styles.body}>
          <div className="row">
            <div className="col-6">
              <div className={Styles.headerContainer}>
                <div className={Styles.header}>My Orders</div>
              </div>
              <div className={Styles.subtitleContainer}>
                <div className={Styles.subtitle}>
                  {/* <a href='/#' className={Styles.subtitle}>View Public Documents</a> */}
                </div>
              </div>
            </div>
            <div className="col-6 d-lg-none d-md-block ">
              <div className="pt-2">
                <div className="pe-5">
                  <img src={rock} className={Styles.imgContianer} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row gap-4">
            <div className="col-lg-6 col-md-12 ">
              <div className="row mb-2">
                {orderList && orderList.length > 0 ? (
                  orderList?.map(
                    (data: orderType, idx: { toString: () => string }) => (
                      <OrderComponent key={data.order_id} data={data} />
                    )
                  )
                ) : (
                  <div className={Styles.orderTabContainer}>
                    <div className="text-center text-white mx-5 px-3 w-100">
                      No Current Orders
                    </div>
                  </div>
                )}
                { orderList?.length && <Pagination totalPages={totalPages}/>}
              </div>
            </div>
            <div className="col-lg-4  col-md-6">
              <div className="mt-2 pt-3 ">
                <div className={Styles.imgLeft + " mt-2 pt-1"}>
                  <img src={rock} alt="" />
                </div>
              </div>
            </div>
            <div className="col-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MyOrder;
