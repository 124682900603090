import React from "react";
import { Chart } from "react-google-charts";
import Styles from "./graph.module.css";
import { useSelector } from "react-redux";

const GraphComponent = () => {
  const { orders_transactions, current_token_value } = useSelector(
    (state: any) => state.dashboardReducer
  );

  const data = orders_transactions.map((d: any) => [
    d.order_date,
    d.total_price,
    d.total_quantity * current_token_value,
  ]);

  const options = {
    title: "Stock Price",
    titleTextStyle: { fontName: '"Arial"' },
    curveType: "function",
    legend: { position: "bottom", fontSize: 5 },
    colors: ["#1C2A4D", "#F4A537"],
    vAxis: {
      viewWindow: {
        min: 0,
      },
    },
    animation: {
      duration: 1000,
      easing: "linear",
      startup: true,
    },
  };

  return (
    <div className={Styles.investmentContainer}>
      <div className="d-flex justify-content-end col-12">
        <div className={Styles.investmentHeadingContainer}>
          <div className={Styles.orderEllipse}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none">
              <circle cx="8.5" cy="9" r="8.5" fill="#F3A537" />
            </svg>
          </div>
          <div className={Styles.investmentHeading}>
            Current value of commodities
          </div>
        </div>
        <div className={data && data.length > 0 ? "d-flex" : "d-flex d-none"}>
          <div className={Styles.headerbtn}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none">
              <circle cx="16" cy="16" r="16" fill="#F4A537" />
              <path
                d="M16.0034 23.1206C14.0103 23.1206 12.0183 23.1218 10.0251 23.1206C9.10055 23.1195 8.91541 22.9378 8.91426 22.0315C8.91426 18.0121 8.91426 13.9926 8.91426 9.97206C8.91426 9.50692 8.88569 9.00406 9.51083 8.91949C9.92683 8.86349 10.1291 9.24178 10.1291 9.99835C10.1291 13.7321 10.1394 17.4658 10.1177 21.1995C10.1143 21.7366 10.2457 21.9184 10.8091 21.9138C14.5474 21.8898 18.2857 21.9024 22.0251 21.9001C22.4948 21.9001 22.9885 21.8772 23.0697 22.5012C23.1223 22.9058 22.744 23.1184 22.0388 23.1195C20.0274 23.1218 18.016 23.1195 16.0045 23.1195L16.0034 23.1206Z"
                fill="black"
              />
              <path
                d="M17.0346 17.3929C18.6026 15.8135 20.1009 14.3026 21.6026 12.7941C21.7763 12.6192 21.9374 12.3872 22.1512 12.3095C22.3626 12.2329 22.7454 12.2249 22.8483 12.3529C22.9809 12.5186 22.9454 12.8535 22.9123 13.1061C22.8963 13.2295 22.726 13.3358 22.6186 13.4443C20.9832 15.0901 19.3477 16.7358 17.71 18.3792C17.1157 18.9758 16.8872 18.9769 16.3066 18.3575C15.71 17.7209 15.1294 17.0683 14.4826 16.3575C13.8883 16.8935 13.3157 17.4066 12.7454 17.9232C12.4917 18.1529 12.2552 18.4032 11.9889 18.6169C11.6917 18.8558 11.3512 18.9175 11.0803 18.5929C10.8266 18.2901 10.9386 17.9861 11.2083 17.7381C12.1443 16.8741 13.0826 16.0135 14.0209 15.1506C14.3934 14.8078 14.7283 14.8718 15.0529 15.2295C15.6894 15.9312 16.3317 16.6261 17.0346 17.3918V17.3929Z"
                fill="black"
              />
            </svg>
          </div>
          <div style={{ display: "none" }} className={Styles.headerbtn}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none">
              <circle cx="16" cy="16" r="15.5" stroke="#F4A537" />
              <path
                d="M13 11H23"
                stroke="#212121"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M13 21H23"
                stroke="#212121"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <path
                d="M13 16H23"
                stroke="#212121"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
              <circle cx="9" cy="11" r="1" fill="#212121" />
              <circle cx="9" cy="21" r="1" fill="#212121" />
              <circle cx="9" cy="16" r="1" fill="#212121" />
            </svg>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="d-flex flex-row-reverse">
          <div className="">
            <select className={Styles.investSelect} defaultValue={0}>
              <option className={Styles.dropdownText} value="0">
                Last 6 months
              </option>
              <option className={Styles.dropdownText} value="1">
                Last 12 months
              </option>
              <option className={Styles.dropdownText} value="2">
                Last 18 months
              </option>
              <option className={Styles.dropdownText} value="3">
                Last 24 months
              </option>
            </select>
          </div>
        </div>
      </div>
      <div className="d-flex col-12">
        {data && data.length > 0 ? (
          <Chart
            chartType="LineChart"
            width="100%"
            height="13rem"
            data={[["Month", "Amount invested", "Current valuation"], ...data]}
            options={options}
          />
        ) : (
          <div className="text-center justify-content-center col-12">
            {" "}
            Data Unavailable{" "}
          </div>
        )}
      </div>
    </div>
  );
};
export default GraphComponent;
