import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./GraphiteMiddleText.module.css";
import ImageLeftContentRight from "../../Common/ImageLeftContentRight";
import GraphiteRock from "../../../Assets/Images/whyGraphiteRock-2.svg";
import { useSelector } from "react-redux";
const GraphiteInvest = () => {
  const rightContent = [
    {
      header: "Global demand increasing",
      body: "In 2023, global graphite demand is up to 800,000 metric tonnes. In 2030, demand is forecast to top 4 million metric tonnes.",
    },
    {
      header: "Critical in renewable energy",
      body: "75% of the forecast graphite demand in 2030 is expected to come from the lithium-ion battery market. 60% will come from electric vehicle companies alone.",
    },
    {
      header: "Innovation is a necessity",
      body: "Without innovative mining and financing methods, the market will be faced with a 777,000 tonne deficit by 2030.",
    },
  ];
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1}>
          <div className={Styles.header1}>Graphite as a Commodity</div>
          <Row className={Styles.header2}>
            Savala is unlocking premium graphite for buyers and for the
            world. It is the first time public buyers have been able to
            access graphite as a commodity, and the only publicly available
            graphite marketplace.
          </Row>
        </Col>
      </Row>
      <ImageLeftContentRight
        data={rightContent}
        image={GraphiteRock}
        width={screenSize > 768 ? "70%" : "90%"}
      />
    </div>
  );
};

export default GraphiteInvest;
