import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./HomeBlockchain.module.css";
import { Card } from "react-bootstrap";
import PC from "../../../Assets/Images/HomeLaptop.svg";
import Hammer from "../../../Assets/Images/HomeHammer.svg";
import SearchGlass from "../../../Assets/Images/HomeSearchGlass.svg";
import ProfessionalImg from "../../../Assets/Images/professional-person.png";
import { useSelector } from "react-redux";
const HomeBlockchain = () => {
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1}>
          <div className={Styles.header1}>The blockchain advantage</div>
        </Col>
        <Row className={Styles.cardContainer + " h-100 "}>
          <Col md={5} lg={5} className=" ">
            <Card className={Styles.card + " h-100"}>
              <img className={Styles.cardImage} src={PC} alt="" />
              <Card.Body className={Styles.cardBody}>
                <Card.Title className={Styles.cardTitle}>Tangible</Card.Title>
                <Card.Text className={Styles.cardText}>
                  Savala tokens are quantifiably linked to a quantity of
                  graphite, creating cast iron ownership of a physical
                  commodity.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={{ span: 7 }} lg={{ span: 7 }} className=" h-100">
            <Row md={12} sm={12} className="  gap-3">
              <Col md={12} sm={12} className=" h-100">
                <Row
                  className={
                    Styles.card + " d-flex flex-column-reverse flex-md-row "
                  }>
                  <Col md={7} className="m-auto">
                    <Card.Body className={Styles.cardBody}>
                      <Card.Title className={Styles.cardTitle}>
                        Unique legal foundation{" "}
                      </Card.Title>
                      <Card.Text className={`${Styles.cardText} mt-3`}>
                        Savala was created with compliance and regulation at the
                        forefront of its purpose.
                      </Card.Text>
                    </Card.Body>
                  </Col>
                  <Col md={5}>
                    <Card.Body className={Styles.cardBody}>
                      <img src={Hammer} className={Styles.imageMargin} alt="" />
                    </Card.Body>
                  </Col>
                </Row>
              </Col>
              <Col md={12} sm={12}>
                <Row
                  className={
                    Styles.card + " d-flex flex-column-reverse flex-md-row "
                  }>
                  <Col md={7} className="m-auto">
                    <Card.Body className={Styles.cardBody}>
                      <Card.Title className={Styles.cardTitle}>
                        Streamlined due diligence{" "}
                      </Card.Title>
                      <Card.Text className={`${Styles.cardText} mt-3`}>
                        Blockchain security replaces traditional frameworks.
                        Blockchain is significantly faster, cost efficient and
                        effective.
                      </Card.Text>
                    </Card.Body>
                  </Col>
                  <Col md={5}>
                    <Card.Body className={Styles.cardBody}>
                      <img
                        src={SearchGlass}
                        className={Styles.imageMargin}
                        alt=""
                      />
                    </Card.Body>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          {/* <Col xs={{ span: 12 }} md={{ span: 7 }} lg={{ span: 7 }}>
            <Row className={Styles.card}>
              {screenSize == 425 && (
                <Col md={5}>
                  <Card.Body className={Styles.cardBody}>
                    <img src={Hammer} className={Styles.imageMargin} />
                  </Card.Body>
                </Col>
              )}
              <Col md={7} className="m-auto">
                <Card.Body className={Styles.cardBody}>
                  <Card.Title>Unique legal foundation </Card.Title>
                  <Card.Text className="mt-3">
                    Savala is one of the world’s first fully legally backed
                    tokens, born from a legal structure with trust at its core.
                  </Card.Text>
                </Card.Body>
              </Col>
              {screenSize > 425 && (
                <Col md={5}>
                  <Card.Body className={Styles.cardBody}>
                    <img src={Hammer} className={Styles.imageMargin} />
                  </Card.Body>
                </Col>
              )}
            </Row>
            <Row className={Styles.card}>
              {screenSize == 425 && (
                <Col md={5}>
                  <Card.Body className={Styles.cardBody}>
                    <img src={SearchGlass} className={Styles.imageMargin} />
                  </Card.Body>
                </Col>
              )}
              <Col md={7} className="m-auto">
                <Card.Body className={Styles.cardBody}>
                  <Card.Title>Streamlined due diligence </Card.Title>
                  <Card.Text className="mt-3">
                    Blockchain security replaces traditional investment
                    frameworks that add time, cost and complexity.
                  </Card.Text>
                </Card.Body>
              </Col>
              {screenSize > 425 && (
                <Col md={5}>
                  <Card.Body className={Styles.cardBody}>
                    <img src={SearchGlass} className={Styles.imageMargin} />
                  </Card.Body>
                </Col>
              )}
            </Row>
          </Col> */}
        </Row>
      </Row>
    </div>
  );
};

export default HomeBlockchain;
