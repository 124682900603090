import React from "react";

const SupportIconComponent = ({
  path,
  name,
  hover,
}: {
  path: string;
  name: string;
  hover: string;
}) => {
  return (
    <>
      {path == "/support" || hover == "/support" ? (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            id="Vector"
            d="M12.5009 0C5.87369 0 0.500854 5.37284 0.500854 12C0.500854 18.6272 5.87369 24 12.5009 24C19.1281 24 24.5009 18.6272 24.5009 12C24.5009 5.37284 19.1281 0 12.5009 0ZM12.4971 18.0373C11.8283 18.0392 11.2801 17.4733 11.2876 16.7886C11.2946 16.116 11.8441 15.576 12.5123 15.5842C13.178 15.5924 13.7186 16.1457 13.7148 16.8158C13.711 17.4808 13.1584 18.0354 12.4971 18.0373ZM15.7434 10.2354C15.4125 10.8505 14.935 11.3362 14.3982 11.7707C14.2573 11.8844 14.1177 12 13.9851 12.1232C13.6264 12.456 13.4451 12.8716 13.4268 13.3585C13.4211 13.5158 13.4243 13.6737 13.418 13.8309C13.406 14.136 13.2752 14.3091 12.986 14.4095C12.9342 14.4272 12.8805 14.4423 12.8268 14.4518C11.9438 14.6046 11.6249 14.3432 11.6066 13.452C11.5851 12.3998 11.9552 11.532 12.8041 10.8834C13.0725 10.6781 13.3377 10.4646 13.5841 10.2341C13.8727 9.96442 14.0735 9.63853 14.0836 9.22484C14.0937 8.80547 13.9112 8.49853 13.5424 8.30337C12.7251 7.87011 11.7266 8.11074 11.2005 8.86547C10.8638 9.348 10.5481 9.45411 10.0819 9.24C9.64927 9.04168 9.46043 8.59768 9.61453 8.08421C9.83811 7.34021 10.3832 6.89811 11.0672 6.60063C11.5883 6.37389 12.1396 6.29621 12.5142 6.29621C13.3561 6.30189 13.9876 6.40611 14.5826 6.68968C16.0112 7.37053 16.4937 8.84151 15.7434 10.2354Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            id="Vector"
            d="M12.5009 0C5.87369 0 0.500854 5.37284 0.500854 12C0.500854 18.6272 5.87369 24 12.5009 24C19.1281 24 24.5009 18.6272 24.5009 12C24.5009 5.37284 19.1281 0 12.5009 0ZM12.4971 18.0373C11.8283 18.0392 11.2801 17.4733 11.2876 16.7886C11.2946 16.116 11.8441 15.576 12.5123 15.5842C13.178 15.5924 13.7186 16.1457 13.7148 16.8158C13.711 17.4808 13.1584 18.0354 12.4971 18.0373ZM15.7434 10.2354C15.4125 10.8505 14.935 11.3362 14.3982 11.7707C14.2573 11.8844 14.1177 12 13.9851 12.1232C13.6264 12.456 13.4451 12.8716 13.4268 13.3585C13.4211 13.5158 13.4243 13.6737 13.418 13.8309C13.406 14.136 13.2752 14.3091 12.986 14.4095C12.9342 14.4272 12.8805 14.4423 12.8268 14.4518C11.9438 14.6046 11.6249 14.3432 11.6066 13.452C11.5851 12.3998 11.9552 11.532 12.8041 10.8834C13.0725 10.6781 13.3377 10.4646 13.5841 10.2341C13.8727 9.96442 14.0735 9.63853 14.0836 9.22484C14.0937 8.80547 13.9112 8.49853 13.5424 8.30337C12.7251 7.87011 11.7266 8.11074 11.2005 8.86547C10.8638 9.348 10.5481 9.45411 10.0819 9.24C9.64927 9.04168 9.46043 8.59768 9.61453 8.08421C9.83811 7.34021 10.3832 6.89811 11.0672 6.60063C11.5883 6.37389 12.1396 6.29621 12.5142 6.29621C13.3561 6.30189 13.9876 6.40611 14.5826 6.68968C16.0112 7.37053 16.4937 8.84151 15.7434 10.2354Z"
            fill="white"
            fillOpacity="0.2"
          />
        </svg>
      )}
      {path == "/support" || hover == "/support" ? (
        <span style={{ color: "white", marginLeft: "10px" }}>{name}</span>
      ) : (
        <span style={{ marginLeft: "10px" }}>{name}</span>
      )}
    </>
  );
};

export default SupportIconComponent;
