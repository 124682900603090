// @ts-nocheck

import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setOTP } from "../../redux/actions";
import { verifyOtpAPI } from "../../service/api";
import { resendOtpAPI } from "../../service/api";
import Styles from "./verifyOtp.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";
import { maskEmail } from "../../utils/common";
import notify from "../../utils/notify";

const VerifyOtpComponent = () => {
  // for screen navigation purpose
  const navigate = useNavigate();

  const [otpError, setOtpError] = useState(false);
  const [otp, setOtp] = useState("");
  const [verifyOtpStatus, setVerifyOtpStatus] = useState("");
  const [resendCodeApiMessage, setResendCodeApiMessage] = useState("");

  const { username } = useSelector((state) => state.userReducer);

  const handleResendCodeSubmit = async () => {
    if (username) {
      const data = {
        username,
      };

      try {
        const response = await resendOtpAPI(data);

        if (response.status) {
          setResendCodeApiMessage(response.message);
          notify.success(`OTP sent to your email ${maskEmail(username)}`);
        } else {
          setResendCodeApiMessage(response.message);
          setOtp("");
        }
      } catch (error) {
        console.error("An error occurred during resend otp:", error);
      }
    } else {
      setResendCodeApiMessage("Username not found!");
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (otp.length !== 6) {
      setOtpError(!otpError);
      return;
    } else {
      const data = {
        username,
        confirmation_code: otp,
      };

      try {
        const response = await verifyOtpAPI(data);

        if (response.status) {
          notify.success(response.message);
          navigate("/login");
        } else {
          setOtp("");
          setVerifyOtpStatus(response.message);
        }
      } catch (error) {
        console.error("An error occurred during verify otp:", error);
      }
    }
  };
  return (
    <>
      <div className={Styles.MainDiv}>
        <h2 className={Styles.HeadingComponent}>
          Please Enter the OTP to Verify Your Account
        </h2>
        <p className={Styles.ParagraphComponent}>
          A OTP (One Time Password) has been sent to{" "}
          {username ? maskEmail(username) : ""}
        </p>

        <form className={Styles.FormComponent} onSubmit={handleSubmit}>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span style={{ width: "8px" }}></span>}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus={true}
            inputStyle={Styles.InputComponent}
            skipDefaultStyles={true}
            inputType="number"
          />
          {otpError && (
            <p className="text-danger">
              <small>Not a valid Otp</small>
            </p>
          )}

          <button
            type="submit"
            style={{ marginTop: 50, marginBottom: 30 }}
            className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} ${Styles.Mobilebtn} w-100`}>
            <span className={Styles.ValidateOtpButtonText}>Validate OTP</span>
          </button>

          <div>
            {verifyOtpStatus && (
              <p className="text-danger">
                <small>{verifyOtpStatus}</small>
              </p>
            )}
            <div style={{ textAlign: "center" }}>
              <a
                href="#"
                id={Styles.ResendOtp}
                className={Styles.ResendOtpComponent}
                onClick={() => handleResendCodeSubmit()}>
                Resend OTP
              </a>
            </div>
            {resendCodeApiMessage && (
              <p className="text-danger">
                <small>{resendCodeApiMessage}</small>
              </p>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default VerifyOtpComponent;
