/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import CarouselComponent from "./CarouselComponent";
import GreetingComponent from "./GreetingComponent";
import OrderComponent from "./OrderComponent";
import Styles from "./dashboard.module.css";
import InvestmentComponent from "./InvestmentCompoent";
import GraphComponent from "./GraphComponent";
import TokenComponent from "./TokenComponent";
import MoneyComponent from "./MoneyComponent";
import BuyMoreComponent from "./BuyMoreComponent";
import SliderComponent from "./SliderComponent";
import { useDispatch, useSelector } from "react-redux";
import { setOrderList, setDashboardData } from "../../redux/actions";
import { getDashboardData, getOrderList } from "../../service/api";

const DashBoardBody = () => {
  const [imageCounter, setImageCounter] = useState(0);

  const dispatch = useDispatch();
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dashDataLoaded, setDashDataLoaded] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const { orderList } = useSelector((state: any) => state.orderReducer);

  const getMyOrderList = async () => {
    const res: any = await getOrderList(pageNo);
    if (res.status) {
      if (res?.order_list) {
        const list = res.order_list;
        list.sort(function (a: any, b: any) {
          var c: any = new Date(a.date);
          var d: any = new Date(b.date);
          return d - c;
        });

        dispatch(setOrderList(list));
        setDataLoaded(true);
      }
    }
  };
  const getDashboardStats = async () => {
    try {
      const res: any = await getDashboardData();
      if (res.status) {
        const data = {
          orders_transactions: res?.orders_transactions,
          total_money_invested: res?.total_money_invested,
          current_value_of_tokens_purchased:
            res?.current_value_of_tokens_purchased,
          banner_data: res?.banner_images,
          total_tokens_purchased: res?.total_tokens_purchased,
          current_token_value: res?.current_token_value
        };
        dispatch(setDashboardData(data));
        setDashDataLoaded(true);
      }
    } catch (error) {
      console.log("eerr", error);
    }
  };
  useEffect(() => {
    if (!dataLoaded) {
      getMyOrderList();
    }
    if (!dashDataLoaded) {
      getDashboardStats();
    }
  }, []);

  return (
    <div className={Styles.dashboardBody + " col-12"}>
      <div className={Styles.mainBody}>
        <div className={Styles.deskView}>
          <div className="row">
            <div className="col-12">
              <GreetingComponent />
            </div>
          </div>
          <div className={Styles.gap + " row  mt-5"}>
            <div className="col-lg-4 col-sm-12">
              <OrderComponent data={orderList} />
            </div>
            <div className="col-lg-4 col-sm-4 d-lg-block d-sm-none">
              <CarouselComponent
                imageCounter={imageCounter}
                setImageCounter={setImageCounter}
              />
              <div className="mt-3">
                <InvestmentComponent />
              </div>
            </div>
            <div className="col-lg-4 col-sm-4 d-lg-block d-sm-none">
              <SliderComponent imageCounter={imageCounter} />
              <div className="mt-3">
                <GraphComponent />
              </div>
            </div>
          </div>
          <div className={Styles.gap + " row  mt-3"}>
            <div className="col-lg-4 col-sm-12">
              <TokenComponent />
            </div>
            <div className="col-lg-4 col-sm-12">
              <MoneyComponent />
            </div>
            <div className="col-lg-4 col-sm-12">
              <BuyMoreComponent />
            </div>
          </div>
        </div>
        <div className={Styles.mobileView}>
          <div className="row">
            <div className="col-12">
              <GreetingComponent />
            </div>
          </div>
          <div className={Styles.gap + " row  mt-3"}>
            <div className="row">
              <div className="col-6">
                <TokenComponent />
              </div>
              <div className="col-6">
                <MoneyComponent />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-0">
              <BuyMoreComponent />
            </div>
          </div>
          <div className={Styles.gap + " row  mt-5"} id="main-bar">
            <div className="col-lg-4 col-sm-12">
              <InvestmentComponent />
            </div>
            <div className="col-lg-4 col-sm-12">
              <GraphComponent />
            </div>
            <div className="col-lg-4 col-sm-12">
              <OrderComponent data={orderList} />
            </div>
            <div className="col-lg-4 col-sm-12 mt-4 px-4">
              <SliderComponent imageCounter={imageCounter} />
            </div>
            <div className="col-lg-4 col-sm-12 mt-4 mb-5 px-4">
              <CarouselComponent
                imageCounter={imageCounter}
                setImageCounter={setImageCounter}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashBoardBody;
