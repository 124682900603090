import React from "react";
import { Row } from "react-bootstrap";
import MobileDashboardIcon from "../../../Assets/Images/mobile-dashboard-icon.svg";
import MobileOrderIcon from "../../../Assets/Images/mobile-myOrder-icon.svg";
import MobilePurchaseIcon from "../../../Assets/Images/mobile-purchase-icon.svg";
import MobileSupportIcon from "../../../Assets/Images/mobile-support-icon.svg";
import MobileDashboardHoverIcon from "../../../Assets/Images/mobile-dashboardHover-icon.svg";
import MobileOrderHoverIcon from "../../../Assets/Images/mobile-orderHover-icon.svg";
import MobilePurchaseHoverIcon from "../../../Assets/Images/mobile-purchaseHover-icon.svg";
import MobileSupportHoverIcon from "../../../Assets/Images/mobile-supportHover-icon.svg";
import Styles from "./MobileBottomNav.module.css";
import CommonIcon from "./CommonIcon";
const MobileBottomNav = () => {
  return (
    <Row className={Styles.bottomNav}>
      <CommonIcon
        path="/dashboard"
        hoverIcon={MobileDashboardHoverIcon}
        icon={MobileDashboardIcon}
        name="Dashboard"
      />
      <CommonIcon
        path="/my-orders"
        hoverIcon={MobileOrderHoverIcon}
        icon={MobileOrderIcon}
        name="Order"
      />
      <CommonIcon
        path="/purchase"
        hoverIcon={MobilePurchaseHoverIcon}
        icon={MobilePurchaseIcon}
        name="IGO"
      />
      <CommonIcon
        path="/purchase-token"
        hoverIcon={MobilePurchaseHoverIcon}
        icon={MobilePurchaseIcon}
        name="Token"
      />
      <CommonIcon
        path="/support"
        hoverIcon={MobileSupportHoverIcon}
        icon={MobileSupportIcon}
        name="Support"
      />
    </Row>
  );
};

export default MobileBottomNav;
