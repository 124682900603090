import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./AboutMultipleImage.module.css";
import AboutHeaderImg from "../../../Assets/Images/about-header.png";
import AboutBanner1 from "../../../Assets/Images/about-banner-1.png";
import AboutPersonHeader from "../../../Assets/Images/about-person-header.png";
const AboutMultipleImage = () => {
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col sm={12} md={12} lg={6}>
          <div className={Styles.banner1}>
            <img
              src={AboutBanner1}
              alt="banner-1"
              className={Styles.bannerImage1}
            />
          </div>
        </Col>
        <Col sm={6} md={6} lg={3}>
          <div className={Styles.banner2}>
            Savala has been carefully created with a bespoke legal backing to
            inspire trust in this new platform.
            <br />
            <br />
            <a
              href="https://cassels.com/"
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none text-white">
              www.cassels.com
            </a>
          </div>
        </Col>
        <Col sm={6} md={6} lg={3}>
          <div className={Styles.banner3 + " fs-3 px-2 py-1"}>
            Quantifiable ownership in graphite through legally backed blockchain
            tokens.
          </div>
        </Col>
        <Col sm={6} md={6} lg={3}>
          <div className={Styles.banner4 + " fs-5 p-2"}>
            Savala blockchain tokens are demonstrably linked to graphite as a
            commodity.
            <br></br>
            <br></br>
            Tokens are born from this legal structure, substantiating the
            agreement between buyers, Savala and the graphite mine.
          </div>
        </Col>
        <Col sm={6} md={6} lg={3}>
          <div className={Styles.banner5 + " fs-3 px-2 py-1"}>
            Cassels Brock & Blackwell LLP, Legal Counsel; Responsible Partner -
            Alison Manzer; B.Sc., M.Sc., LLB, LLM, MBA, DBA.
          </div>
        </Col>
        <Col sm={6} md={6} lg={3}>
          <div className={Styles.banner6}>
            <img
              src={AboutPersonHeader}
              className="p-1"
              style={{
                width: "9em",
                objectFit: "contain",
                objectPosition: "center",
              }}
              alt=""
            />
            <div className="mt-4">Dr Alison Manzer</div>
          </div>
        </Col>
        <Col sm={6} md={6} lg={3}>
          <div className={Styles.banner7}></div>
        </Col>
      </Row>
    </div>
  );
};

export default AboutMultipleImage;
