// @ts-nocheck

import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { setUserName } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { signUpAPI } from "../../service/api";
import InputField from "../../Components/Common/InputField";
import Styles from "./signup.module.css";
import ButtonStyles from "../../Components/Common/Button.module.css";

const SignupComponent = () => {
  // for screen navigation purpose
  const navigate = useNavigate();

  // for updating and the redux store values
  const dispatch = useDispatch();

  // initializing the state variables
  const [user, setUserDetails] = useState({
    name: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    number: "",
  });

  // state variable for check box
  const [isChecked, setIsChecked] = useState(false);

  // state variable for form erros
  const [formErrors, setFormErrors] = useState({});

  const [isSubmit, setIsSubmit] = useState(false);

  const [apiErrorMsg, setApiErrorMsg] = useState("");

  const [showValues, setShowValues] = useState({
    showPassword1: false,
    showPassword2: false,
  });

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setApiErrorMsg("");
    const regexNumber = /^\+?\d*$/;

    if (name === "number") {
      if (value === "" || regexNumber.test(value)) {
        setUserDetails({
          ...user,
          [name]: value,
        });
      }
    } else {
      setUserDetails({
        ...user,
        [name]: value,
      });
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  // for valiadting the form inputs
  const validateForm = (values: {
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
    number: string;
  }) => {
    const errors = {};

    const regexName = /^[^*|\":<>[\]{}`\\()'!#%^_+,./~?;@&$]+$/;

    if (!values.firstName.trim()) {
      errors.firstName = "First Name is required.";
    } else if (!regexName.test(values.firstName.trim())) {
      errors.firstName = "Invalid characters in the First Name.";
    }
    if (!values.lastName.trim()) {
      errors.lastName = "Last Name is required.";
    } else if (!regexName.test(values.lastName.trim())) {
      errors.lastName = "Invalid characters in the Last Name.";
    }

    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/;
    if (!values.email.trim()) {
      errors.email = "Email is required.";
    } else if (!regexEmail.test(values.email.trim())) {
      errors.email = "Invalid email format.";
    }

    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!values.password.trim()) {
      errors.password = "Password is required.";
    } else if (!lengthRegex.test(values.password.trim())) {
      errors.password = "Password must be at least 8 characters.";
    } else if (!uppercaseRegex.test(values.password.trim())) {
      errors.password = "Password must contain at least one uppercase letter.";
    } else if (!lowercaseRegex.test(values.password.trim())) {
      errors.password = "Password must contain at least one lowercase letter.";
    } else if (!numberRegex.test(values.password.trim())) {
      errors.password = "Password must contain at least one number.";
    } else if (!specialCharacterRegex.test(values.password.trim())) {
      errors.password = "Password must contain at least one special character.";
    }

    if (!values.confirmPassword.trim()) {
      errors.confirmPassword = "Confirm password is required.";
    } else if (values.confirmPassword.trim() !== values.password.trim()) {
      errors.confirmPassword = "Confirm password and password should be same.";
    }

    // const regexNumber = /^\+?[0-9]{8,14}$/;
    // if (!values.number) {
    //   errors.number = "Phone number is required.";
    // } else if (!regexNumber.test(values.number)) {
    //   errors.number = "Phone number is not valid.";
    // }

    if (!isChecked) {
      errors.checkBox = "Please accept the policies.";
    }

    return errors;
  };

  // for managing password hide and show for both type of password
  const handleClickShowPassword = (num: number) => {
    if (num == 1) {
      setShowValues({
        ...showValues,
        showPassword1: !showValues.showPassword1,
      });
    } else {
      setShowValues({
        ...showValues,
        showPassword2: !showValues.showPassword2,
      });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormErrors(validateForm(user));
    setIsSubmit(true);
  };

  const signupHandler = async () => {
    const data = {
      username: user.email.trim(),
      password: user.password.trim(),
      phone_number: user.number,
      first_name: user.firstName.trim(),
      last_name: user.lastName.trim(),
    };

    try {
      const response = await signUpAPI(data);

      if (response.status) {
        dispatch(setUserName(data.username));

        // reset the form values after the api action
        setUserDetails({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
          number: "",
        });
        setIsChecked(false);

        navigate("/verify-otp");
      } else {
        // reset the form values after the api action
        setUserDetails({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          confirmPassword: "",
          number: "",
        });
        setIsChecked(false);

        setApiErrorMsg(response.message);
        return;
      }
    } catch (error) {
      console.error("An error occurred during signup:", error);
      return;
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      signupHandler();
    }
  }, [formErrors]);

  return (
    <div className={Styles.MainDiv}>
      <h2 className={Styles.Heading}>Welcome to Savala</h2>
      <p className={Styles.Paragraph}>Tell us about yourself</p>
      <form onSubmit={handleSubmit}>
        <div>
          <InputField
            label="First Name"
            value={user.firstName}
            type="text"
            placeholder="First Name"
            onChange={changeHandler}
            height="50px"
            name="firstName"
            maxLength={40}
          />
        </div>
        {formErrors.firstName && (
          <p className="text-danger">
            <small>{formErrors.firstName}</small>
          </p>
        )}
        <div className={Styles.InputField}>
          <InputField
            label="Last Name"
            value={user.lastName}
            type="text"
            placeholder="Last Name"
            onChange={changeHandler}
            height="50px"
            name="lastName"
            maxLength={40}
          />
        </div>
        {formErrors.lastName && (
          <p className="text-danger">
            <small>{formErrors.lastName}</small>
          </p>
        )}
        <div className={Styles.InputField}>
          <InputField
            label="Email"
            value={user.email}
            type="email"
            placeholder="Email"
            onChange={changeHandler}
            height="50px"
            name="email"
            maxLength={50}
          />
        </div>
        {formErrors.email && (
          <p className="text-danger">
            <small>{formErrors.email}</small>
          </p>
        )}

        <div className={Styles.InputField}>
          <InputField
            label="Password"
            value={user.password}
            type="password"
            placeholder="Password"
            showPassword={showValues.showPassword1}
            onChange={changeHandler}
            handleTogglePassword={() => handleClickShowPassword(1)}
            height="50px"
            name="password"
            maxLength={40}
          />
        </div>
        {formErrors.password && (
          <p className="text-danger">
            <small>{formErrors.password}</small>
          </p>
        )}

        <div className={Styles.InputField}>
          <InputField
            label="Confirm Password"
            value={user.confirmPassword}
            type="password"
            placeholder="Confirm Password"
            showPassword={showValues.showPassword2}
            onChange={changeHandler}
            handleTogglePassword={() => handleClickShowPassword(2)}
            height="50px"
            name="confirmPassword"
            maxLength={40}
          />
        </div>
        {formErrors.confirmPassword && (
          <p className="text-danger">
            <small>{formErrors.confirmPassword}</small>
          </p>
        )}

        {/* <div className={Styles.InputField}>
          <InputField
            label="Phone Number"
            value={user.number}
            type="text"
            placeholder="Phone Number"
            onChange={changeHandler}
            height="50px"
            name="number"
            maxLength={15}
          />
        </div>
        {formErrors.number && (
          <p className="text-danger">
            <small>{formErrors.number}</small>
          </p>
        )} */}

        {/* Term and condition box */}
        <div className={`${Styles.InputField} ${Styles.PolicyDiv}`}>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
            className={Styles.CheckBox}
          />
          <span className={Styles.CheckBoxText}>
            I've read and agree to the Savala{" "}
            <Link to="/terms-and-conditions" className={Styles.Route}>
              terms and conditions
            </Link>
            &nbsp; and &nbsp;
            <Link to="/privacy-policy" className={Styles.Route}>
              privacy policy.
            </Link>
          </span>
        </div>

        {formErrors.checkBox && (
          <p className="text-danger">
            <small>{formErrors.checkBox}</small>
          </p>
        )}

        <button
          type="submit"
          style={{ marginTop: 53 }}
          className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}>
          <span className={Styles.RegisterButtonText}>Next</span>
        </button>
        {apiErrorMsg && (
          <p className="text-danger">
            <small>{apiErrorMsg}</small>
          </p>
        )}

        <div className={Styles.RouteDiv}>
          Already have an account?{" "}
          <Link to="/login" className={Styles.Route}>
            Log in
          </Link>
        </div>
      </form>
    </div>
  );
};

export default SignupComponent;
