import React from "react";
import MyOrder from "../Components/MyOrder";
import UserLayout from "../Layouts/UserLayout/UserLayout";

const MyOrders = () => {
  return (
    <body style={{ backgroundColor: "#1d3118" }}>
      <UserLayout title="My Orders" showPaddding={false}>
        <MyOrder />
      </UserLayout>
    </body>
  );
};

export default MyOrders;
