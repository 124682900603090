import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Styles from "./confirmPassword.module.css";
import ButtonStyles from "../../Common/Button.module.css";
import { confirmForgotPasswordAPI } from "../../../service/api";
import InputField from "../../Common/InputField";
import { Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import notify from "../../../utils/notify";

interface Props {
  showConfirmModal: boolean;
  setShowConfirmModal: (value: boolean) => void;
}

const ConfirmPasswordComponent = ({
  showConfirmModal,
  setShowConfirmModal,
}: Props) => {
  // for page navigation purpose
  const navigate = useNavigate();

  // @ts-ignore
  // const { username } = useSelector((state) => state.userReducer);
  // @ts-ignore
  // const { otp } = useSelector((state) => state.forgotPasswordReducer);

  const [formValue, setFormValue] = useState({
    password: "",
    confirmPassword: "",
  });

  const [formErrors, setFormErrors] = useState<any>({});

  const [showValues, setShowValues] = useState({
    showPassword1: false,
    showPassword2: false,
  });

  const [isSubmit, setIsSubmit] = useState<Boolean>(false);
  const [apiErrorMsg, setApiErrorMsg] = useState<String>("");

  const [otp, setOtp] = useState("");

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormValue({
      ...formValue,
      [name]: value,
    });
  };

  // for managing password hide and show for both type of password
  const handleClickShowPassword = (num: number) => {
    if (num == 1) {
      setShowValues({
        ...showValues,
        showPassword1: !showValues.showPassword1,
      });
    } else {
      setShowValues({
        ...showValues,
        showPassword2: !showValues.showPassword2,
      });
    }
  };

  // @ts-ignore
  const validateForm = (formValue) => {
    const errors = {};

    const lengthRegex = /.{8,}/;
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const numberRegex = /\d/;
    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!formValue.password) {
      // @ts-ignore
      errors.password = "Password is required.";
    } else if (!lengthRegex.test(formValue.password)) {
      // @ts-ignore
      errors.password = "Password must be at least 8 characters.";
    } else if (!uppercaseRegex.test(formValue.password)) {
      // @ts-ignore
      errors.password = "Password must contain at least one uppercase letter.";
    } else if (!lowercaseRegex.test(formValue.password)) {
      // @ts-ignore
      errors.password = "Password must contain at least one lowercase letter.";
    } else if (!numberRegex.test(formValue.password)) {
      // @ts-ignore
      errors.password = "Password must contain at least one number.";
    } else if (!specialCharacterRegex.test(formValue.password)) {
      // @ts-ignore
      errors.password = "Password must contain at least one special character.";
    }

    if (!formValue.confirmPassword) {
      // @ts-ignore
      errors.confirmPassword = "Confirm Password is required.";
    } else if (formValue.confirmPassword !== formValue.password) {
      // @ts-ignore
      errors.confirmPassword = "Confirm password and password should be same.";
    }

    if (otp.length !== 6) {
      // @ts-ignore
      errors.otp = "Otp length is not valid.";
    }

    return errors;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormErrors(validateForm(formValue));
    setIsSubmit(true);
  };

  const confirmPasswordHandler = async () => {
    const data = {
      username: localStorage.getItem("user_email"),
      confirmation_code: otp,
      password: formValue.password,
    };
    try {
      const responseData: any = await confirmForgotPasswordAPI(data);

      // @ts-ignore
      if (responseData.status) {
        setFormValue({
          password: "",
          confirmPassword: "",
        });
        setOtp("");
        // @ts-ignore
        notify.success(responseData.message);
        setShowConfirmModal(false);
        navigate("/my-profile");
      } else {
        // @ts-ignore
        setApiErrorMsg(responseData.message);
        // @ts-ignore
        console.log("Confirm Password failed due to: ", responseData.message);
        setFormValue({
          password: "",
          confirmPassword: "",
        });
        setOtp("");
        return;
      }
    } catch (error) {
      console.error("An error occurred during confirm Password:", error);
      return;
    }
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      confirmPasswordHandler();
    }
  }, [formErrors]);

  return (
    <Modal
      centered
      show={showConfirmModal}
      onHide={() => setShowConfirmModal(false)}
      contentClassName={Styles.modalContent}>
      <>
        <div className={Styles.OuterDiv} style={{ width: "580px" }}>
          <h2 className={Styles.Heading}>Reset Password</h2>
          <p className={Styles.Paragraph}>
            Strong passwords include numbers, letters, and special character.
          </p>

          <form className={Styles.Form} onSubmit={handleSubmit}>
            <div>
              <div className={Styles.labelContainer}>
                <p className={Styles.label}>Enter OTP</p>
              </div>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span style={{ width: "8px" }}></span>}
                renderInput={(props) => <input {...props} />}
                shouldAutoFocus={true}
                inputStyle={Styles.InputComponent}
                skipDefaultStyles={true}
                inputType="number"
              />
            </div>
            {/* 
                           // @ts-ignore */}
            {formErrors.otp && (
              <p className="text-danger">
                <small>{formErrors.otp}</small>
              </p>
            )}
            <div>
              <InputField
                label="Enter New Password"
                value={formValue.password}
                type="password"
                placeholder="New Password"
                showPassword={showValues.showPassword1}
                onChange={changeHandler}
                handleTogglePassword={() => handleClickShowPassword(1)}
                height="50px"
                name="password"
              />
            </div>
            {/* 
                            // @ts-ignore */}
            {formErrors.password && (
              <p className="text-danger">
                <small>{formErrors.password}</small>
              </p>
            )}

            <div className={Styles.InputField}>
              <InputField
                label="Confirm New Password"
                value={formValue.confirmPassword}
                type="password"
                placeholder="Confirm New Password"
                showPassword={showValues.showPassword2}
                onChange={changeHandler}
                handleTogglePassword={() => handleClickShowPassword(2)}
                height="50px"
                name="confirmPassword"
              />
            </div>
            {/* 
                            // @ts-ignore */}
            {formErrors.confirmPassword && (
              <p className="text-danger">
                <small>{formErrors.confirmPassword}</small>
              </p>
            )}

            <button
              style={{ marginTop: 40 }}
              className={`${ButtonStyles.PlainBtn} ${ButtonStyles.ThemeOrangeBackground} w-100`}
              type="submit">
              <span className={Styles.ButtonTextReset}>Reset Password</span>
            </button>
            <div className={Styles.CancelDiv}>
              <button
                onClick={() => setShowConfirmModal(false)}
                className={Styles.cancelButton}>
                Cancel
              </button>
            </div>

            {apiErrorMsg && (
              <p className="text-danger">
                <small>{apiErrorMsg}</small>
              </p>
            )}
          </form>
        </div>
      </>
    </Modal>
  );
};

export default ConfirmPasswordComponent;
