import React from "react";
import Styles from "./InputField.module.css";
import { InputInterface } from "../../../utils/interfaces/inputInterface";
import Info from "../../../Assets/Images/info.svg";
import { Tooltip } from "react-tooltip";

const InputField = ({
  label,
  important = false,
  value,
  onChange,
  showPassword,
  handleTogglePassword,
  type,
  placeholder,
  disabled,
  height,
  name,
  maxLength,
  showInfo,
}: InputInterface) => {
  return (
    <div className={Styles.inputContainer}>
      {label && (
        <div className={Styles.labelContainer}>
          <p className={Styles.label}>
            {label} {important && <b style={{ color: "#FF0003" }}>*</b>}
            {showInfo && (
              <>
                <Tooltip id="my-tooltip" style={{ width: "400px" }} />
                <img
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content={showInfo}
                  src={Info}
                  alt="info"
                  className={Styles.editImg}
                  style={{ paddingLeft: "5px", paddingRight: "5px" }}
                />
              </>
            )}
          </p>
        </div>
      )}
      <div className={Styles.inputFieldContainer}>
        {" "}
        <input
          className={Styles.inputBox}
          type={showPassword ? "text" : type}
          placeholder={`${placeholder || label}`}
          autoComplete={type === "password" ? "off" : "on"}
          onChange={(e) => {
            onChange(e);
          }}
          value={value}
          disabled={disabled}
          style={{ height }}
          name={name}
          maxLength={maxLength}
        />
        {type === "password" && (
          <button
            type="button"
            className={Styles.toggleButton}
            onClick={handleTogglePassword}
            disabled={disabled}>
            {showPassword ? "Hide" : "Show"}
          </button>
        )}
      </div>
    </div>
  );
};
export default InputField;
