import React from "react";
import Layout from "../Layouts/Layout";
import BlogReadingPage from "../Components/News"
const News = () => {
  return (
    <Layout>
      <BlogReadingPage/>
    </Layout>
  );
};

export default News;