import React, { useEffect, useState } from 'react';
import { getOrderList } from "../../../service/api";
import { useDispatch } from "react-redux";
import { setOrderList } from "../../../redux/actions";
import Styles from './pagination.module.css';

const Pagination = ({ totalPages }: {totalPages : number}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const getMyOrderList = async () => {
    try {
      const res: any = await getOrderList(currentPage);
      if (res.status) {
        dispatch(setOrderList(res.order_list));
      }
    } catch (error) {
      console.log("eerr", error);
    }
  };

  useEffect(() => {
    getMyOrderList();
  }, [currentPage]);

  const generatePagination = () => {
    const paginationItems = [];

    // Previous button
    paginationItems.push(
      <li key="previous" className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
        <a className="page-link" href="#" onClick={() => setCurrentPage(currentPage - 1)}>
          Previous
        </a>
      </li>
    );

    // Ellipsis before current page
    if (currentPage > 3) {
      paginationItems.push(<li key="ellipsis-before" className="page-item disabled"><span className="page-link">...</span></li>);
    }

    // Pages around the current page
    for (let i = Math.max(1, currentPage - 2); i <= Math.min(currentPage + 2, totalPages); i++) {
      paginationItems.push(
        <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
          <a className="page-link" href="#" onClick={() => setCurrentPage(i)}>
            {i}
          </a>
        </li>
      );
    }

    // Ellipsis after current page
    if (currentPage < totalPages - 2) {
      paginationItems.push(<li key="ellipsis-after" className="page-item disabled"><span className="page-link">...</span></li>);
    }

    // Next button
    paginationItems.push(
      <li key="next" className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
        <a className="page-link" href="#" onClick={() => setCurrentPage(currentPage + 1)}>
          Next
        </a>
      </li>
    );

    return paginationItems;
  };

  return (
    <nav className={Styles.pagination} aria-label="Page navigation example">
      <ul className="pagination">
        {generatePagination()}
      </ul>
    </nav>
  );
};

export default Pagination;
