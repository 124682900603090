import {
  _isMetamaskInstalled,
  _connectToMetamask,
  _disconnectToMetamask,
  _getActiveAccount,
  _tranferToken,
  _generateWalletAddress,
} from "../service/metamask_service";

// check is metamask wallet installed or not
const checkMetamaskInstallation = async () => {
  const result = await _isMetamaskInstalled();
  return result;
};
const connectWithWallet = async () => {
  const result = await _connectToMetamask();
  return result;
};
const disconnectWithWallet = async () => {
  const result = await _disconnectToMetamask();
  return result;
};
const getUserAddress = async () => {
  const address = await _getActiveAccount();
  return address;
};
const transferToken = async (
  data: number,
  contractAddress: string,
  adminWallet: string
) => {
  const hash = await _tranferToken(data, contractAddress, adminWallet);
  return hash;
};

const generateAddress = (username: string) => {
  const hash = _generateWalletAddress(username);
  return hash;
};

export default {
  checkMetamaskInstallation,
  connectWithWallet,
  disconnectWithWallet,
  getUserAddress,
  transferToken,
  generateAddress,
};
