import GetInTouch from "./GetInTouch";
import { Link } from "react-router-dom";
import SavalaLogo from "../../Assets/Images/savala-logo-white.svg";
import Styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={Styles.DesktopFooterContainer}>
      <div className={Styles.FooterContent}>
        <Link to="/why-graphite" className={Styles.FooterLink}>
          Why Graphite?
        </Link>
        <Link to="/about" className={Styles.FooterLink}>
          About
        </Link>
        <Link to="/faq" className={Styles.FooterLink}>
          FAQs
        </Link>
        <Link to="/contact-us" className={Styles.FooterLink}>
          Contact
        </Link>
        <Link to="/privacy-policy" className={Styles.FooterLink}>
          Privacy Policy
        </Link>
        <Link to="/terms-and-conditions" className={Styles.FooterLink}>
          Terms and Conditions
        </Link>
        <div className={Styles.FooterLogoArea}>
          <div className={Styles.Logo}>
            <Link to="/" className={Styles.FooterLink}>
              <img src={SavalaLogo} alt="Logo" />
            </Link>
          </div>
          <p className={Styles.FooterCopyright}>
            Copyright @Savala. All rights reserved.
          </p>
        </div>
      </div>
      <GetInTouch />
      <div className={Styles.FooterContent}>
        <div className={Styles.MoveFooterLogoArea}>
          <div className={Styles.Logo}>
            <Link to="/" className={Styles.FooterLink}>
              <img src={SavalaLogo} alt="Logo" />
            </Link>
          </div>
          <p className={Styles.FooterCopyright}>
            Copyright @Savala. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
