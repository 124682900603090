import React from "react";
import Layout from "../Layouts/Layout";
import FullBlogPage from "../Components/News/FullBlogPage"
const FullBlog = () => {
  return (
    <Layout>
      <FullBlogPage/>
    </Layout>
  );
};

export default FullBlog;