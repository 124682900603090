import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Styles from "./HomeBanner.module.css";
import BannerImage from "../../../Assets/Images/page404-banner.png";
import ButtonStyle from "../../Common/Button.module.css";
import ContactUsModal from "../ContactusModal";
const HomeBanner = () => {
  const [showModal, setShowModal] = useState(false);
  const navigation = useNavigate();
  const handleNavigation = () => {
    navigation("/pre-register");
  };
  return (
    <>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1} md={7}>
          <Row className={Styles.header1}>
            It’s time to invest with your heart{" "}
            <div className={Styles.header2}>and</div> your head.
          </Row>

          <br />
          <Row className={Styles.subHeader1}>
            Savala is a marketplace for premium commodities that power renewable
            energy. It provides simplicity, sophistication and humanity for
            forward-thinking buyers.
          </Row>
          <Row className={Styles.textLeft}>
            <Col md={3} sm={4} xs={3} className="p-0">
              <button
                className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground}`}
                // onClick={() => setShowModal(true)}
                onClick={() => handleNavigation()}>
                Join Savala
              </button>
            </Col>
            <Col className="p-0 m-auto">
              <Link to="/why-graphite" className={Styles.tag}>
                A new chapter for graphite
              </Link>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <img
              className={Styles.bannerImage}
              src={BannerImage}
              alt="banner_image"
            />
          </Row>
          <Row>
            <div className={Styles.miniLine1}>
              Savala.
              <div className={Styles.miniLine2}>
                {" "}
                Where trust defines trade.
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      {showModal && (
        <ContactUsModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </>
  );
};

export default HomeBanner;
