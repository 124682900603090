import React, { useState } from "react";
import Styles from "./contactUs.module.css";
import { Row, Col } from "react-bootstrap";
import BannerImage from "../../Assets/Images/ContactUs.svg";
import GetInTouch from "../Common/GetInTouch";
import ContactUsPhone from "../../Assets/Images/contact-us-phone.svg";
import ProfileImage1 from "../../Assets/Images/profile-image-1.png";
import ProfileImage2 from "../../Assets/Images/profile-image-2.png";
import ProfileImage3 from "../../Assets/Images/profile-image-3.png";
const ContactUsComponent = () => {
  return (
    <>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1} md={5}>
          <Row className={Styles.header1}>
            Contact us.
            <div className={Styles.header2}> We’d love to hear from you.</div>
          </Row>

          <br />
          <Row className={Styles.subHeader1}>
            Background documentation is available now. Enquire now for more
            information about how Savala is changing sustainable investment.
          </Row>
        </Col>
        <Col>
          <img
            className={Styles.bannerImage}
            src={BannerImage}
            alt="banner_image"
          />
        </Col>
      </Row>
      <Row className={Styles.minPadding}>
        <Col md={4} className={Styles.blackBackGround}>
          <div className={Styles.imageContainer}>
            <img
              className={Styles.imgBorder}
              src={ContactUsPhone}
              alt="phone-img"
              width={"100%"}
            />
          </div>
        </Col>
        <Col md={8}>
          <Row className={Styles.ContactUsContainer}>
            {/* <GetInTouch type="contact_us" /> */}
            <Row className={Styles.header3}>Get in touch</Row>
            <Row className={Styles.subHeader5}>
              If you have questions for the creators of Savala – Sasha and
              Gareth are more than happy to help. Reach out directly using the
              contact details below.
            </Row>
            <Row>
              <Col md={2} className={Styles.imageCol}>
                <img
                  src={ProfileImage1}
                  alt="profile_image"
                  className={Styles.image}
                />
              </Col>
              <Col md={10} style={{ alignSelf: "center" }}>
                <Row className={Styles.subHeader3}>Sasha Jacob</Row>
                <Row className={Styles.subHeader3}>
                  <a
                    href="mailto:Sasha@savala.global"
                    target="_blank"
                    className={Styles.subHeader4}>
                    Sasha@savala.global
                  </a>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={2} className={Styles.imageCol}>
                <img
                  src={ProfileImage2}
                  alt="profile_image"
                  className={Styles.image}
                />
              </Col>
              <Col md={10} style={{ alignSelf: "center" }}>
                <Row className={Styles.subHeader3}>Gareth Beesley</Row>
                <Row className={Styles.subHeader3}>
                  <a
                    href="mailto:gareth@savala.global"
                    target="_blank"
                    className={Styles.subHeader4}>
                    gareth@savala.global
                  </a>
                </Row>
              </Col>
              {/* <Col md={2}>
                <img src={ProfileImage3} alt="profile_image" />
              </Col>
              <Col>
                <Row className={Styles.subHeader3}>Brett Endersby</Row>
                <Row className={Styles.subHeader3}>brett@savala.global</Row>
              </Col> */}
            </Row>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default ContactUsComponent;
