import URLS from "../utils/api-end-points";
import { getAccessToken, getUserId } from "../utils/common";

const getHeaders = () => {
  const headers = {};
  const apiKey = "auth_key";
  headers[apiKey] = process.env.REACT_APP_API_KEY;
  const contentType = "Content-Type";
  headers[contentType] = "application/text";
  return headers;
};

const post = async (url, data = {}) => {
  let response = {};
  const accessToken = getAccessToken();
  const userId = getUserId();
  if (accessToken !== null);
  data = Object.assign(data, {
    user_id: parseInt(userId, 10),
    access_token: accessToken,
  });
  const URL = `${URLS.BASE_URL}${url}`;
  await fetch(`${URL}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: getHeaders(),
  })
    .then(async (result) => {
      unAuthorizeAccess(result);
      response = await result.json();
    })
    .catch((error) => {
      console.log(error);
    });
  return response;
};

const get = async (url) => {
  let response = {};
  const URL = `${URLS.BASE_URL}${url}`;
  await fetch(`${URL}`, {
    method: "GET",
  })
    .then(async (result) => {
      unAuthorizeAccess(result);
      response = await result.json();
    })
    .catch((error) => {
      console.log(error);
    });
  return response;
};
const unAuthorizeAccess = (res) => {
  if (res.status == 440) {
    localStorage.clear();
    window.location.href = "/login";
  }
};

export default { get, post };
