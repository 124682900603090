import React, { useEffect, useState } from "react";
import Layout from "../Layouts/Layout";
import PreRegisterComponent from "../Components/PreRegisterComponent";

const PreRegister = () => {
  return (
    <Layout title="Pre-Register">
      <PreRegisterComponent />
    </Layout>
  );
};

export default PreRegister;
