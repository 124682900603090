import React from "react";
import Layout from "../Layouts/Layout";
import PrivacyPolicyComponent from "../Components/PrivacyPolicyComponent";
const PrivatePolicy = () => {
  return (
    <Layout title="Privacy Policy">
      <PrivacyPolicyComponent text="Privacy Policy." />
    </Layout>
  );
};

export default PrivatePolicy;
