import React, { useEffect } from "react";
import Styles from "./faq.module.css";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
const FaqComponent = ({ data = [], short = false }) => {
  const hash = window.location.hash;
  useEffect(() => {
    if (hash && data.length > 0) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [data]);
  return (
    <div className={Styles.faqBody}>
      <div className={Styles.headerContainer}>
        <div className={Styles.header}>Frequently Asked Questions</div>
      </div>
      <div className="mt-4 mb-3 pb-3">
        <Accordion className={Styles.accordianMainBody}>
          {data &&
            data.map((info: any, index: number) => {
              if (short && index > 4) {
                return null;
              } else {
                return (
                  <Accordion.Item
                    className={Styles.accordianHeader}
                    eventKey={JSON.stringify(index)}
                    key={index}
                    id={`${"question" + index}`}>
                    <Accordion.Header>
                      <div className={Styles.Number}>{index + 1}</div>
                      <div
                        className={Styles.accName}
                        dangerouslySetInnerHTML={{ __html: info?.question }}
                      />
                    </Accordion.Header>
                    <Accordion.Body>
                      <div dangerouslySetInnerHTML={{ __html: info?.answer }} />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              }
            })}
        </Accordion>
        {short && (
          <Link to="/faq">
            <div className={Styles.moreFAQ}>+ More FAQ's</div>
          </Link>
        )}
      </div>
    </div>
  );
};
export default FaqComponent;
