import React from "react";

import GraphiteHeader from "./GraphiteHeader";
import GraphiteMiddleText from "./GraphiteMiddleText";

import GraphiteMultipleImage from "./GraphiteMultipleImage";
import GraphiteInvest from "./GraphiteInvest";
import CommonFuture from "../Common/CommonFuture";
const AboutComponent = () => {
  return (
    <>
      <GraphiteHeader />
      <GraphiteMiddleText />
      <GraphiteMultipleImage />
      <GraphiteInvest />
      <CommonFuture />
    </>
  );
};
export default AboutComponent;
