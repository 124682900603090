import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./AboutWhoAreWe.module.css";
import { Card } from "react-bootstrap";
import ProfessionalImg from "../../../Assets/Images/professional-person.png";
import ProfessionalImg2 from "../../../Assets/Images/professional-person-2.png";
import ProfessionalImg3 from "../../../Assets/Images/professional-person-4.png";
import ProfessionalImg5 from "../../../Assets/Images/professional-person-5.png";
import ProfessionalImgPlaceholder from "../../../Assets/Images/place-holder-1.png";
const AboutWhoAreWe = () => {
  const CardData = [
    {
      image: ProfessionalImg,
      header: "Sasha Jacob",
      body: "Sasha Jacob is the Founder of Savala as well as the chairman and CEO of Jacob Capital Management Inc. He is a prominent global figure in renewable energy and clean technology and was recognised by Institutional Investor as one of the 5 Most Influential Emerging Players in Renewable Energy.",
      message:
        "He has managed more than 100 transactions in the renewable sector, and has participated in financings valued at more than $10 billion.",
    },
    {
      image: ProfessionalImg2,
      header: "Gareth Beesley",
      body: "Gareth Beesley is CEO and Co-Founder of DComm, a global leader in blockchain and real world asset tokenisation.",
      message:
        "He has more than 20 years of experience in tech startups, international business models, and transactional database systems, and has been a key influence on a variety of global software companies.",
    },
  ];
  const CardData2 = [
    {
      image: ProfessionalImg3,
      header: "Benjamin Sarkozy",
      body: "Benjamin Sarkozy, a prominent entrepreneur and investor in the graphite industry, is lauded for his expertise featured in top publications like Fastmarkets S&P Global and Industrial Heating.",
      message:
        "As CEO of GES Europe, he leads the company's advancement in the graphite sector, emphasizing its pivotal role in lithium-ion batteries. With diverse experience in construction chemicals and healthcare, Sarkozy's focus on optimizing graphite for energy technologies is evident. His academic background includes a Master of Science from Emlyon Business School and a B.Sc. from Justus-Liebig-Universität Giessen.",
    },
    {
      image: ProfessionalImgPlaceholder,
      header: "Coming Soon !",
      // body: "Benjamin Sarkozy, a prominent entrepreneur and investor in the graphite industry, is lauded for his expertise featured in top publications like Fastmarkets S&P Global and Industrial Heating. As CEO of GES Europe, he leads the company's advancement in the graphite sector, emphasizing its pivotal role in lithium-ion batteries. With diverse experience in construction chemicals and healthcare, Sarkozy's focus on optimizing graphite for energy technologies is evident.",
      // message:
      //   "His academic background includes a Master of Science from Emlyon Business School and a B.Sc. from Justus-Liebig-Universität Giessen, enhancing his leadership in driving sustainable supply chains and setting industry standards.",
    },
    {
      image: ProfessionalImg5,
      header: "Ungad Chadda",
      body: "Ungad has 30 years of experience in the financial sector in Canada and is currently CEO of Urban Infrastructure Group, a publicly listed company operating in the Canadian residential housing sector.",
      message:
        "Ungad is a CA, CPA by training and has held a variety of senior executive roles during his 21 years at TMX Group Limited, including President of the Toronto Stock Exchange.",
    },
  ];
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1}>
          <div className={Styles.header1}>Who are we?</div>
        </Col>
        <Row className={Styles.cardContainer}>
          {CardData.map((info) => (
            <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Card className={Styles.card + " h-100"}>
                <Card.Img variant="top" src={info.image} />
                <Card.Body className={Styles.cardBody}>
                  <Card.Title>{info.header}</Card.Title>
                  <Card.Text>{info.body}</Card.Text>
                  <Card.Text>{info.message}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Col className={Styles.col1}>
          <div className={Styles.header2}>Advisory Board</div>
        </Col>
        <Row className={Styles.cardContainer}>
          {CardData2.map((info) => (
            <Col xs={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }}>
              <Card className={Styles.card + " h-100"}>
                <Card.Img
                  variant="top"
                  src={info.image}
                  className={Styles.advisoryImage}
                />
                <Card.Body className={Styles.cardBody}>
                  <Card.Title
                    className={
                      info.header === "Coming Soon !"
                        ? Styles.announcementSoonHeader
                        : ""
                    }>
                    {info.header}
                  </Card.Title>
                  <Card.Text>{info.body}</Card.Text>
                  <Card.Text>{info.message}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Row>
    </div>
  );
};

export default AboutWhoAreWe;
