import React from "react";
import Layout from "../Layouts/Layout";
import AboutComponent from "../Components/AboutComponent";
const About = () => {
  return (
    <Layout title="About Page">
      <AboutComponent />
    </Layout>
  );
};

export default About;
