import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./AboutInvestmentInfomation.module.css";
import AboutMountain from "../../../Assets/Images/about-mountain.svg";
const AboutInvestmentInfomationMobile = () => {
  return (
    <div className={Styles.mobileView}>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1}>
          <Row>
            <Col xs={6} className={Styles.middleText}>
              <div className={Styles.header1}>
                <div>Buyer Information</div>
              </div>
            </Col>
            <Col xs={6}>
              <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                <img
                  src={AboutMountain}
                  alt="about-mountain"
                  width="100%"
                  height="123px"
                />
              </Col>
            </Col>
          </Row>
          <Row className={Styles.header2}>
            <Col xs={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
              <Row className="my-3 gap-3">
                <Row>
                  The Launch of the Savala Commodities Platform gives all buyers
                  the opportunity to purchase graphite, in an early stage
                  offering usually only reserved for larger scale buyers.
                </Row>
                <Row>
                  Buyers will be given the opportunity to purchase a minimum of
                  one tonne of graphite with the first stage being the release
                  of 2,000 tonnes offered at USD$850 per tonne. Buyers will
                  acquire these via the Savala Commodities Platform and on
                  successful purchase, will be issued a Token, each of which
                  will represent the ownership of 1 tonne of graphite.
                </Row>
              </Row>
              <Row className="my-5 gap-3">
                <Row>
                  On completion of the sale of the first 2,000 tonnes there will
                  be a stage 2 release of up to 9,000 tonnes at USD$1,000/
                  tonne.
                </Row>
                <Row>
                  On completion or closing of the sale of Stages 1 and 2, the
                  Savala Commodities Platform will provide a secondary
                  marketplace whereby Token holders will be able sell their
                  tokens. This secondary market will provide the opportunity for
                  parties to buy and sell their ownership of the graphite.
                </Row>
              </Row>
              <Row className="my-5 gap-3">
                <Row>
                  The mine will retain custody for the Buyer and will mine, sell
                  and settle the graphite on behalf of the Buyers.
                </Row>
                <Row>
                  Once all of the Graphite in stages 1 and 2 is mined, sold and
                  settled, whomever holds the token at this point in time will
                  have their token redeemed and paid out in cash. Buyers will be
                  entitled to the first $USD$1,200 of any sale and 40% of any
                  amount above $1,200 for each tonne of graphite they own.
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AboutInvestmentInfomationMobile;
