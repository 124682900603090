import React from "react";

import AboutHeader from "./AboutHeader";
import AboutMiddleText from "./AboutMiddleText";
import AboutWhoAreWe from "./AboutWhoAreWe";
import AboutWhoAreWeMobile from "./AboutWhoAreWeMobile";
import AboutMultipleImage from "./AboutMultipleImage";
import CommonFuture from "../Common/CommonFuture";
import { useSelector } from "react-redux";
import AboutInvestmentInfomation from "./AboutInvestmentInfomation";
import AboutInvestmentInfomationMobile from "./AboutInvestmentInfomationMobile";

const AboutComponent = () => {
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  return (
    <>
      <AboutHeader />
      <AboutMiddleText />

      {screenSize > 425 ? <AboutWhoAreWe /> : <AboutWhoAreWeMobile />}
      <AboutMultipleImage />

      {screenSize > 425 ? (
        <AboutInvestmentInfomation />
      ) : (
        <AboutInvestmentInfomationMobile />
      )}
      <CommonFuture />
    </>
  );
};
export default AboutComponent;
