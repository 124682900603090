import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./GraphiteMultipleImage.module.css";
import GraphiteCamera from "../../../Assets/Images/graphiteCamera.svg";
import GraphiteGraph from "../../../Assets/Images/graphiteGraph-1.png";
const GraphiteMultipleImage = () => {
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col md={6} sm={12}>
          <div className={Styles.banner1}>
            <img
              src={GraphiteCamera}
              alt="banner-1"
              width="70%"
              className={Styles.img1}
              // height="100%"
            />
          </div>
        </Col>
        <Col md={3} sm={6}>
          <div className={Styles.banner2}>
            Graphite is used for the anode in lithium-ion batteries that are a
            vital part of the world’s renewable energy solutions. It is low
            cost, has high energy and power density, and is more abundant than
            any other alternatives.
          </div>
        </Col>
        <Col md={3} sm={6} className={Styles.backgroundColor}>
          <div className={Styles.banner3}>
            Graphite represents nearly
            <div className={Styles.bigFont}>50%</div>
            of the materials needed in every Li-ion battery.
          </div>
        </Col>
        <Col md={3} sm={6}>
          <div className={Styles.banner4}>
            The growth in the electric vehicle industry is a major factor
            underpinning the expected rise in demand for graphite. There is up
            to 70kgs of graphite in every electric vehicle.
          </div>
        </Col>
        <Col md={3} sm={6}>
          <div className={Styles.banner5}>
            Global demand for graphite is forecast to be up to 8 times current
            levels by 2035.
          </div>
        </Col>
        <Col md={6} sm={12}>
          <div className={Styles.banner6}>
            <img src={GraphiteGraph} height={"100%"} width={"100%"} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default GraphiteMultipleImage;
