import React from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./AboutMiddleText.module.css";
const AboutMiddleText = () => {
  return (
    <div>
      <Row className={Styles.bannerContainer}>
        <Col className={Styles.col1}>
          <div className={Styles.header1}>
            <div>What is</div>
            <div className={Styles.diffColor}>Savala?</div>
          </div>
          <Row className={Styles.header2}>
            Savala is uniquely positioned at the intersection of technology,
            finance and humanity.  Created by Resource, Finance, Battery Metal
            experts. Savala buyers enable graphite mines to scale operations at
            pace, keeping up with global demand and fuelling production of
            renewable energy technology.
            <br></br>
            <br></br>
            Savala uses sophisticated blockchain technology to digitalise
            physical assets and make them available like never before.
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default AboutMiddleText;
