// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Styles from "./MyProfileTabs.module.css";
import EditPen from "../../../Assets/Images/edit-pen.svg";
import LightPen from "../../../Assets/Images/light-edit-pen.svg";
import Copy from "../../../Assets/Images/copy.svg";
import { ProfileTab } from "../../../utils/interfaces/commonInterface";
import ButtonStyle from "../../Common/Button.module.css";
import { useSelector } from "react-redux";
import { copyText } from "../../../utils/common/index";
import notify from "../../../utils/notify";
import Info from "../../../Assets/Images/info.svg";
import { Tooltip } from "react-tooltip";
import { RxQuestionMarkCircled } from "react-icons/rx";
interface Country {
  country_id: number;
  country_name: string;
}

const MyProfileTabs = ({
  label,
  value,
  handleClick,
  color,
  id = "",
  editField,
  handleUpdate,
  disable,
  fieldFor,
  important = false,
  tempWalletAddress = false,
  options = [],
  tooltipText,
  link,
}: any) => {
  const { screenSize }: { screenSize: number } = useSelector(
    (state: any) => state.layoutReducer
  );
  const [show, setShow] = useState(true);
  const [tempValue, setTempValue] = useState("");
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (label === "Phone number" && !/^\+?\d*$/.test(e.target.value)) {
      notify.error("Enter only numbers");
      return;
    }
    setTempValue(e.target.value);
  };
  const handleSubmit = () => {
    if (tempValue == "" || value === tempValue) {
      setShow(false);
      return;
    }
    if (label === "Phone number" && tempValue.length < 10) {
      notify.error("Enter correct mobile number");
      return;
    }
    handleUpdate(label, tempValue);
  };

  const handleSelectCountry = (event: any) => {
    setTempValue(event.target.value);
    if (event.target.value == "" || value === event.target.value) {
      setShow(false);
      return;
    }
    handleUpdate(label, event.target.value);
  };

  useEffect(() => {
    if (screenSize !== 0) {
      screenSize >= 768 ? setIsMobileScreen(false) : setIsMobileScreen(true);
    }
  }, [screenSize]);
  return (
    <>
      {isMobileScreen ? (
        <div className={Styles.inputContainer}>
          {label && (
            <div className={Styles.labelContainer}>
              <p className={Styles.label}>
                {label} {important && <b style={{ color: "#FF0003" }}>*</b>}
                {tooltipText && (
                  <a href={link} className={Styles.tooltipText}>
                    <u>{tooltipText}</u>
                  </a>
                )}
              </p>
            </div>
          )}
          <div className={Styles.inputFieldContainer}>
            {" "}
            <div className={Styles.value}>
              {editField == label && show ? (
                fieldFor !== "country" ? (
                  <input
                    type="text"
                    value={tempValue}
                    className={Styles.inputBox}
                    autoFocus
                    onChange={handleChange}
                    maxLength={label == "Phone number" ? 15 : 30}
                  />
                ) : (
                  <select
                    className={Styles.mobilecountrySelect}
                    onChange={handleSelectCountry}>
                    <option
                      className={Styles.dropdownText}
                      value=""
                      // disabled
                      // hidden
                    >
                      - Select Country -
                    </option>
                    {options.map((option: any) => (
                      <option
                        key={option.country_id}
                        className={Styles.dropdownText}
                        value={option.country_name}>
                        {option.country_name}
                      </option>
                    ))}
                  </select>
                )
              ) : (
                <input
                  type="text"
                  value={!tempWalletAddress && !value ? "N/A" : value}
                  className={
                    fieldFor === "walletConnect"
                      ? `${Styles.inputBox} ${Styles.mobileInputBox}`
                      : `${Styles.inputBox}`
                  }
                  autoFocus
                  disabled={true}
                />
              )}{" "}
            </div>
            {!disable && (
              <>
                {editField == label && show ? (
                  <img
                    src={EditPen}
                    alt="edit-pen"
                    onClick={() => handleSubmit()}
                    className={`${Styles.editImg} ${Styles.toggleButton}`}
                  />
                ) : (
                  <img
                    src={LightPen}
                    alt="edit-pen"
                    onClick={() => {
                      setShow(true);
                      setTempValue(value);
                      handleClick(label);
                    }}
                    id={id}
                    className={`${Styles.editImg} ${Styles.toggleButton}`}
                  />
                )}
              </>
            )}
            {fieldFor === "kyc" && value === "Pending" && (
              <button
                className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.mobileBtn}`}
                onClick={handleClick}
                id="blockpass-kyc-connect">
                Start KYC
              </button>
            )}
            {fieldFor === "walletConnect" &&
              (!value ? (
                <button
                  className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground} ${Styles.mobileBtn}`}
                  onClick={handleClick}>
                  Wallet Connect
                </button>
              ) : (
                <img
                  src={Copy}
                  alt="copy"
                  onClick={() => {
                    copyText(value);
                    notify.success("Address Copied");
                  }}
                  className={`${Styles.editImg} ${Styles.mobileCopy}`}
                />
              ))}
          </div>
        </div>
      ) : (
        <Row className={Styles.tab} style={{ backgroundColor: color }}>
          <Col className={Styles.valueCol} md={4} sm={3}>
            <div className={Styles.key}>
              {label}{" "}
              {tooltipText && (
                <>
                  <Tooltip id={tooltipText} clickable>
                    <div>
                      <a href={link} className={Styles.kycToolTip}>
                        <u className={Styles.kycToolTip2}>{tooltipText}</u>
                      </a>
                    </div>
                  </Tooltip>
                  <RxQuestionMarkCircled
                    data-tooltip-id={tooltipText}
                    className="m-2"
                  />
                </>
              )}
            </div>
          </Col>
          <Col style={{ width: "50%" }} className={Styles.valueCol}>
            <div className={Styles.value}>
              {editField == label && show ? (
                fieldFor !== "country" ? (
                  <input
                    type="text"
                    value={tempValue}
                    className={Styles.inputField}
                    autoFocus
                    onChange={handleChange}
                    maxLength={label == "Phone number" ? 15 : 30}
                  />
                ) : (
                  <select
                    className={Styles.countrySelect}
                    onChange={handleSelectCountry}>
                    <option
                      className={Styles.dropdownText}
                      value=""
                      // disabled
                      // hidden
                    >
                      - Select Country -
                    </option>
                    {options.map((option: any) => (
                      <option
                        key={option.country_id}
                        className={Styles.dropdownText}
                        value={option.country_name}>
                        {option.country_name}
                      </option>
                    ))}
                  </select>
                )
              ) : (
                (value && (
                  <div>
                    <div className={Styles.labelText}>
                      {tempWalletAddress && !value ? "N/A" : value}
                    </div>{" "}
                  </div>
                )) ||
                "N/A"
              )}{" "}
            </div>
          </Col>
          <Col md={2} sm={3} className="text-end">
            <div>
              {!disable && (
                <>
                  {editField == label && show ? (
                    <img
                      src={EditPen}
                      alt="edit-pen"
                      onClick={() => handleSubmit()}
                      className={Styles.editImg}
                    />
                  ) : (
                    <img
                      src={LightPen}
                      alt="edit-pen"
                      onClick={() => {
                        setShow(true);
                        setTempValue(value);
                        handleClick(label);
                      }}
                      id={id}
                      className={Styles.editImg}
                    />
                  )}
                </>
              )}
              {fieldFor === "kyc" && value === "Pending" && (
                <button
                  className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground}`}
                  onClick={handleClick}
                  id="blockpass-kyc-connect">
                  Start KYC
                </button>
              )}
              {fieldFor === "walletConnect" &&
                (!value ? (
                  <button
                    className={`${ButtonStyle.PlainBtn} ${ButtonStyle.ThemeOrangeBackground}`}
                    onClick={handleClick}>
                    Wallet Connect
                  </button>
                ) : (
                  <img
                    src={Copy}
                    alt="copy"
                    onClick={() => {
                      copyText(value);
                      notify.success("Address Copied");
                    }}
                    className={Styles.editImg}
                  />
                ))}
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default MyProfileTabs;
