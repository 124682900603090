import React from "react";
import UserLayout from "../Layouts/UserLayout/UserLayout";
import SupportComponent from "../Components/SupportComponent";
const Support = () => {
  return (
    <UserLayout showPaddding title="Support">
      <SupportComponent />
    </UserLayout>
  );
};
export default Support;
