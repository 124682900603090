import React, { useEffect, useState } from "react";
import Layout from "../Layouts/Layout";

export interface Mine {
  token_asset_id: BigInteger;
  mine_id: BigInteger;
  mine_name: string;
  qty: number;
  in_process_qty: number;
  available_qty: number;
  type: string;
  ico_end_date: string;
  sale_price: number;
  currency: string;
  state: string;
}

type latestValuesType = {
  icoQty: number | "";
  mine: string;
  validateFeild?: string;
};

const BuyToken = () => {
  // Master Data
  const [mines, setMines] = useState<Mine[]>([]);
  const [orderID, setOrderID] = useState<number>(0);
  const [apiErrorMsg, setApiErrorMsg] = useState<string>("");

  // state variables
  const [mine, setMine] = useState("");
  const [mineError, setMineError] = useState<string>("");
  const [icoQuantity, setIcoQuantity] = useState<number | "">("");
  const [qtyError, setQtyError] = useState<string>("");
  const [availableQty, setAvailableQty] = useState<number | "">("");
  const [amount, setAmount] = useState<number | "">("");
  const [paymentMode, setPaymentMode] = useState("crypto_currency");
  const [activeBuyBtn, setActiveBuyBtn] = useState<boolean>(false);

  const toggleSubmitByCryptoBtn = (latestValues: latestValuesType) => {
    if (
      latestValues.mine !== "selectMine" &&
      latestValues.icoQty &&
      latestValues.icoQty > 0
    ) {
      setActiveBuyBtn(true);
    } else {
      setActiveBuyBtn(false);
    }
  };

  const validateBuyTokenForm = (latestValues: latestValuesType) => {
    setApiErrorMsg("");
    toggleSubmitByCryptoBtn(latestValues);
    setAmount("");
    const matchedMine = mines.filter(
      (m) => m.token_asset_id.toString() === latestValues.mine
    );
    if (matchedMine.length) {
      if (latestValues.icoQty) {
        setAmount(matchedMine[0].sale_price * latestValues.icoQty);
      }
    }
    switch (latestValues.validateFeild) {
      case "mine":
        if (latestValues.mine === "selectMine") {
          setMineError("Mine is mandatory.");
        }
        return true;
      case "icoQty":
        if (latestValues.icoQty && latestValues.icoQty <= 0) {
          setQtyError("Quantity should be grater than zero.");
        }
        let onlyNumbers = /^[0-9]+$/.test(latestValues.icoQty.toString());
        if (!onlyNumbers) {
          setQtyError("Only number is allowed.");
        }
        return true;
      default:
        return false;
    }
  };

  const handleMineChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMineError("");
    setMine(e.target.value);
    if (e.target.value === "selectMine") {
      setAmount("");
      setAvailableQty("");
    } else {
      const matchedMine = mines.filter(
        (m) => m.token_asset_id.toString() === e.target.value
      );
      if (matchedMine) {
        setAvailableQty(matchedMine[0].available_qty);
      }
    }
    validateBuyTokenForm({
      icoQty: icoQuantity,
      mine: e.target.value,
      validateFeild: "mine",
    });
  };

  const handleICOQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let qty = parseInt(e.target.value, 10);
    setIcoQuantity(qty);
    validateBuyTokenForm({
      icoQty: qty,
      mine: mine,
      validateFeild: "icoQty",
    });
  };
  const handlePaymentModeChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setPaymentMode(e.target.value);

  const buyMineToken = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // TODO checks for validation
    if (!activeBuyBtn) {
      return;
    }
    const data = {
      user_id: 9,
      access_token: "2a78a648bc63a913093a1a3ea3ffb62e40ed7579",
      token_asset_id: mine,
      qty: icoQuantity,
      payment_type: paymentMode,
    };

    try {
      await fetch("https://gmt-backend-stage.zeeve.net/api/v1/buy_mine_token", {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
          auth_key: "1BAQ6QEY0Qb0ITzG4A",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        res.json().then((data) => {
          if (data.status) {
            setOrderID(data.order_id);
          } else {
            // TODO handle failure case here
          }
        });
      });
    } catch (error) {
      console.error("An error occurred during Buy Token:", error);
    }
  };

  const fiatPayment = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    try {
      let logincheck = sessionStorage.getItem("loggedIn");
      if (logincheck == "true") {
        window.location.href = "/";
        return;
      }
      document.title = "Buy Token";
    } catch {}
    const loadMasterData = async () => {
      try {
        await fetch(
          "https://gmt-backend-stage.zeeve.net/api/v1/asset_request/master",
          {
            method: "POST",
            headers: {
              "Content-Type": "text/plain",
              auth_key: "1BAQ6QEY0Qb0ITzG4A",
            },
            body: JSON.stringify({
              user_id: 9,
              access_token: "2a78a648bc63a913093a1a3ea3ffb62e40ed7579",
            }),
          }
        ).then((res) => {
          res.json().then((data) => {
            if (data.status) {
              setMines(data.crypto_asset);
            }
          });
        });
      } catch (error) {
        console.log("Error: ", error);
        setApiErrorMsg("Crypto asset could not load.");
      }
    };
    loadMasterData();
  }, []);

  return (
    <Layout>
      <div className="form-container">
        <h2>{orderID !== 0 && <p>Order is created successfully!</p>}</h2>
        <h2>
          {((orderID === 0 || paymentMode === "crypto_currency") &&
            "Buy IGO Tokens") ||
            "Payment mode - Fiat"}
        </h2>
        {(orderID === 0 && (
          <form onSubmit={buyMineToken}>
            <label>
              Mine{" "}
              <select
                placeholder="Select Mine"
                value={mine}
                onChange={handleMineChange}>
                <option value="selectMine">Select Mine</option>
                {mines.map((mine: Mine) => (
                  <option
                    key={mine.token_asset_id.toString()}
                    value={mine.token_asset_id.toString()}>
                    {mine.mine_name}
                  </option>
                ))}
              </select>{" "}
              {mineError.length > 0 && <p>{mineError}</p>}
            </label>
            <br />
            <label>
              IGO Quantity{" "}
              <input
                type="number"
                placeholder="IGO Quantity"
                value={icoQuantity}
                onChange={handleICOQuantityChange}
              />{" "}
              {qtyError.length > 0 && <p>{qtyError}</p>}
            </label>
            <p>Available Quantity to Purchase {availableQty}</p>
            <br />
            <label>
              Amount{" "}
              <input
                type="text"
                placeholder="Amount"
                value={amount.toString()}
                readOnly
              />{" "}
            </label>
            <p>Minimum Qty to purchase 1</p>
            <br />
            <label>
              Payment Mode{" "}
              <select value={paymentMode} onChange={handlePaymentModeChange}>
                <option value="crypto_currency">Crypto</option>
                <option value="fiat">Fiat</option>
              </select>{" "}
            </label>
            <br />
            {apiErrorMsg.length > 0 && <p>{apiErrorMsg}</p>}
            <button type="submit" disabled={!activeBuyBtn}>
              Buy Now
            </button>
          </form>
        )) ||
          (paymentMode === "fiat" && (
            <form onSubmit={fiatPayment}>
              <p>Rest development work (Step2) is pending</p>
              <button type="submit">Submit</button>
            </form>
          ))}
      </div>
    </Layout>
  );
};

export default BuyToken;
