import React from "react";
import Layout from "../Layouts/Layout";
import LoginComponent from "../Components/LoginComponent";

const Login = () => {
  return (
    <Layout title="Login">
      <LoginComponent />
    </Layout>
  );
};

export default Login;
