import React from "react";
import Layout from "../Layouts/Layout";
import VerifyOtpComponent from "../Components/VerifyOtpComponent";

const VerifyOtp = () => {
  return (
    <>
      <Layout title="Verify Otp">
        <VerifyOtpComponent />
      </Layout>
    </>
  );
};

export default VerifyOtp;
