import CurrencyFormat from 'react-currency-format';
import Styles from './order.module.css';
import moment from 'moment';
import { FaLink } from "react-icons/fa";
export interface orderType {
    order_id: number,
    order_name: string,
    mine_id: any,
    mine_name: any,
    currency_id: number,
    currency_name: string,
    transaction_referenc: any,
    partner_name: string,
    qty: number,
    amount: number,
    date_order: any,
    state: string,
    payment_mode: string,
    blockchain_url: string;
    order_type: string;
}
interface props {
    data: orderType
}
const OrderComponent = ({ data }: props) => {
    return (
        <div className={Styles.orderTabContainer}>
            <div className={Styles.orderDiv + ''}>
                <div className={Styles.orderDivHeading}>
                    ORDER DATE
                </div>
                <div className={Styles.orderDivText}>
                    {/* {data.date_order} */}
                    {moment(data.date_order).format('lll')}
                </div>
            </div>
            <div className={Styles.orderDiv + ''}>
                <div className={Styles.orderDivHeading}>
                    REFERENCE #
                </div>
                <div className={Styles.orderDivText}>
                    {data.order_name}
                </div>
            </div>
            <div className={Styles.orderDiv + ''}>
                <div className={Styles.orderDivHeading}>
                    QUANTITY
                </div>
                <div className={Styles.orderDivText}>
                    {data.qty}
                </div>
            </div>
            <div className={Styles.orderDivLast + ''}>
                <div className={Styles.orderDivHeading}>
                    BLOCKCHAIN TRANSACTION
                </div>
                <div className={Styles.orderDivText}>
                        {
                            data?.blockchain_url ?
                            (<a href={data.blockchain_url} className={Styles.linkText} target='_blank' rel="noreferrer">
                            <FaLink size={14} className='me-1'/>
                                {data.blockchain_url? data?.blockchain_url.substring(0,18) + '...' : ''}
                            </a>)
                            :'-'
                        }
                </div>
            </div>
            <div className='col-12'></div>
            <div className={Styles.orderDiv + ' '}>
                <div className={Styles.orderDivHeading}>
                    AMOUNT PAID
                </div>
                <div className={Styles.orderDivText}>
                    <CurrencyFormat value={data.amount} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                </div>
            </div>
            <div className={Styles.orderDiv + ' '}>
                <div className={Styles.orderDivHeading}>
                    PAYMENT TYPE
                </div>
                <div className={Styles.orderDivText}>
                    {data.payment_mode ? data.payment_mode : 'UNSURE'}
                </div>
            </div>
            <div className={Styles.orderDiv + ' '}>
                <div className={Styles.orderDivHeading}>
                    ORDER TYPE
                </div>
                <div className={Styles.orderDivText}>
                {data.order_type ? data.order_type : '-'}
                </div>
            </div>
            <div className={Styles.orderDiv + ' '}>
                <div className={Styles.orderDivHeading}>
                    ORDER STATUS
                </div>
                <div className={Styles.orderDivText}>
                    {data.state}
                </div>
            </div>
        </div>
    )
}
export default OrderComponent;